import s from './pageSizeDropdown.module.scss'
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import {useEffect, useRef, useState} from "react";

export const pageSizeOptions = [50, 100, 250, 500, 1000];

export const PageSizeDropdown = ({ size, setSize }) => {
    const [isActive, setIsActive] = useState(false);
    const dropdownRef = useRef(null);

    const onClickHandler = (pageSize) => {
        console.log('Selected pageSize:', pageSize); // Додаємо лог для відслідковування вибраного значення
        setSize(pageSize); // Передати вибране значення в функцію onSetPageSize
        setIsActive(false);
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsActive(false);
        }
    };

    const handleEscClose = (e) => {
        if (e.key === 'Escape') {
            setIsActive(false);
            if (document.activeElement) {
                document.activeElement.blur();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', handleEscClose);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handleEscClose);
        };
    }, []);

    return (
        <div className={s.container} tabIndex={0} ref={dropdownRef}>
            <div className={s.dropdown} onClick={(e) => setIsActive(!isActive)}>
                {size} <GlobalSvgSelector id="white-dropdown-arrow"/>
            </div>
            {isActive && (
                <ul className={s.sizeList}>
                    {pageSizeOptions.map((item, index) => (
                        <li
                            onClick={() => onClickHandler(item)}
                            key={index}
                            style={item === size ? {backgroundColor: 'rgba(85, 120, 218, 0.50)'} : null}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
