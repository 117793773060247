import React from 'react';
import {useSpring, animated} from "react-spring";
import s from './lineChart.module.scss'


const LineChart = ({percentage, color}) => {
    // const value = 76

    const { width } = useSpring({
        from: { width: '0%' },
        to: { width: `${percentage}%` },
    });

    return (
        <div className={s.chart}>
            <animated.div
                style={{
                    height: '100%',
                    backgroundColor: color,
                    borderRadius: "400px",
                    width,
                }}
            />
        </div>
    );
};

export default LineChart;