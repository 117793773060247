import React, { useEffect, useMemo, useRef, useState } from "react";
import RedirectorCard from "./RedirectorCard/RedirectorCard";
import {
  deleteRedirector,
  fetchRedirectorsList,
  fetchRedirectorsWithParams,
} from "../../redux/slices/redirectorSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleContinue } from "../../utils/helpers/scrollTop";
import { Link, useNavigate } from "react-router-dom";
import CustomSkeleton from "../CustomSkeleton/CustomSkeleton";
import { toast } from "react-hot-toast";
import {
  fetchDomainsList,
  globalDataSelector,
} from "../../redux/slices/globalDataSlice";
import MultiSelect from "../MultiSelect/MultiSelect";
import { fetchAppList } from "../../redux/slices/applicationSlice";
import Search from "../Search/Search";
import qs from "qs";
import s from "./allRedirectors.module.scss";
import BlueButton from "../UiKit/Buttons/BlueButton/BlueButton";
import EmptyList from "../EmptyList/EmptyList";
import { fetchRedirectorsFilters } from "../../api/helpers/fetchFilters";

const AllRedirectors = () => {
  const [searchValue, setSearchValue] = useState("");

  const [selectedApps, setSelectedApps] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);

  const [domainSelectInput, setDomainSelectInput] = useState([]);
  const [appSelectInput, setAppSelectInput] = useState(null);

  const isSearch = useRef(false);
  const isMounted = useRef(false);

  const { redirectorsList, redirectorListLoading, totalCount } = useSelector(
    (state) => state.redirector,
  );

  const { domainsList } = useSelector(globalDataSelector);
  const { appList } = useSelector((state) => state.application);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getRedirectors = () => {
    const redirectorsParams = {
      domains: selectedDomains.join(","),
      applications: selectedApps.join(","),
      search: searchValue,
    };
    dispatch(fetchRedirectorsWithParams(redirectorsParams));
  };

  useEffect(() => {
    fetchRedirectorsFilters(dispatch);
  }, [dispatch]);

  // Якщо параметри були змінені та відбувся перший рендер, виконується цей код:
  useEffect(() => {
    if (isMounted.current) {
      const queryString = qs.stringify({
        domains: selectedDomains.join(","),
        applications: selectedApps.join(","),
        search: searchValue,
      });

      navigate(`?${queryString}`);
    }

    isMounted.current = true;
  }, [selectedDomains, selectedApps, searchValue]);

  // Перевіряємо чи був перший рендер і якщо так, то перевіряємо та зберігаємо URL параметри
  useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));

      setSelectedDomains(params.domains.split(","));
      setSelectedApps(params.applications.split(","));

      if (params.domains) {
        const domainSelectInputList = params.domains.split(",").map((item) => ({
          value: item,
          label: item,
        }));

        setDomainSelectInput(domainSelectInputList);
      }

      if (params.applications) {
        const appSelectInputList = params.applications
          .split(",")
          .map((item) => ({
            value: item,
            label: item,
          }));

        setAppSelectInput(appSelectInputList);
      }

      if (params.search) {
        setSearchValue(params.search);
      }

      isSearch.current = true;
    }
  }, []);

  // Якщо вже був перший рендер, то робимо запит
  useEffect(() => {
    if (!isSearch.current) {
      getRedirectors();
    }

    isSearch.current = false;
  }, [selectedDomains, selectedApps, searchValue]);

  const onDeleteHandler = async (id) => {
    await dispatch(deleteRedirector(id));
    toast.success("Запись была успешно удалена");
    handleContinue();
    getRedirectors();
    // dispatch(fetchRedirectorList());
  };

  const skeletons = (styles) => {
    return [...new Array(4)].map((_, index) => (
      <CustomSkeleton styles={styles} key={index} />
    ));
  };
  const redirects = redirectorsList.map((obj, index) => (
    <div key={index}>
      <RedirectorCard {...obj} onDeleteHandler={onDeleteHandler} />
    </div>
  ));

  const domainListOptions = domainsList.map(({ address }) => ({
    value: address,
    label: address,
  }));
  // const appListOptions = appList.map(({ name }) => ({
  //   value: name,
  //   label: name,
  // }));

  const appListOptions = useMemo(() => {
    return appList.map((item) => ({
      label: item.name,
      value: item.name,
      is_alive: item.is_alive,
      name: "app_list",
    }));
  }, [appList]);

  return (
    <div className={s.RedirectorsWrapper}>
      {/*<div className={s.redirectorsHeader}>*/}
      {/*    /!*<h3>Все редиректы</h3>*!/*/}
      {/*    <BlueButton*/}
      {/*        text='Создать'*/}
      {/*        action={() => {*/}
      {/*            navigate("/all-redirectors/create");*/}
      {/*            handleContinue();*/}
      {/*        }}/>*/}
      {/*</div>*/}

      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Все редиректы</h3>
        <Link to="/all-redirectors/create" className={s.blueButton}>
          Создать
        </Link>
        {/*<BlueButton*/}
        {/*  width={120}*/}
        {/*  text="Создать"*/}
        {/*  action={() => {*/}
        {/*    navigate("/all-redirectors/create");*/}
        {/*    handleContinue();*/}
        {/*  }}*/}
        {/*/>*/}
      </div>

      <div className={s.scrollWrapper}>
        <div className={s.filtersWrapper}>
          <h5 className={s.filtersTitle}>Фильтрация</h5>

          <div className={s.filters}>
            <div className={s.selectWrapper}>
              <MultiSelect
                value={domainSelectInput}
                options={domainListOptions}
                setSelectedOptions={(o) => {
                  setSelectedDomains(o.map((i) => i.value));
                  setDomainSelectInput(o);
                }}
                placeholder="Домен"
                isMulti={true}
              />
            </div>
            <div className={s.selectWrapper}>
              <MultiSelect
                value={appSelectInput}
                options={appListOptions}
                setSelectedOptions={(o) => {
                  setSelectedApps(o.map((i) => i.value));
                  setAppSelectInput(o);
                }}
                placeholder="Приложение"
                isMulti={true}
              />
            </div>
          </div>
        </div>

        {/*<div className={s.searchBar}>*/}
        {/*    <div className={s.recordsCount}>*/}
        {/*        Всего:<span>{totalCount}</span>*/}
        {/*    </div>*/}
        {/*    /!*<div className={s.searchWrapper}>*!/*/}
        {/*    <Search searchValue={searchValue} setSearchValue={setSearchValue}/>*/}
        {/*    /!*</div>*!/*/}
        {/*</div>*/}

        <div className={s.searchBar}>
          <div className={s.recordsCount}>
            Всего: <span>{!redirectorListLoading && totalCount}</span>
          </div>
          <Search searchValue={searchValue} setSearchValue={setSearchValue} />
        </div>

        <div className={s.cardsWrapper}>
          {totalCount === 0 && !redirectorListLoading ? (
            <EmptyList />
          ) : redirectorListLoading ? (
            skeletons(s.skeletonCard)
          ) : (
            redirects
          )}
        </div>
      </div>
    </div>
  );
};

export default AllRedirectors;
