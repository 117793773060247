import React, {useEffect, useState} from "react";
import s from "./appSettings.module.scss";
import {useNavigate} from "react-router-dom";
import PrelandingStep from "./Steps/PrelandingStep/PrelandingStep";
import RedirectStep from "./Steps/RedirectStep/RedirectStep";
import SettingsStep from "./Steps/SettingsStep";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import {useDispatch, useSelector} from "react-redux";
import {setAllowedIps, setCurrentFlowObjId, setSelectedRedirectors} from "../../../redux/slices/flowSlice";

const EditFlowSettings = () => {

    const [step, setStep] = useState("settings");

    const dispatch = useDispatch()

    const {fetchObjLoading, flowObj} = useSelector(state => state.flow)

    useEffect(() => {
        return () => {
            dispatch(setSelectedRedirectors([]))
            dispatch(setCurrentFlowObjId(null))
            dispatch(setAllowedIps([]))
        }
    }, [dispatch]);

    return (
        <div className={s.flowSettingsWrapper}>


            <div className={s.pageHeader}>
                <h3 className={s.pageTitle}>{fetchObjLoading ? 'Загрузка...' : flowObj?.domain?.address}</h3>
            </div>


            <div className={s.settingsHeader}>
                <h5 className={s.subTitle}>Редактирование потока</h5>
                <div className={s.stepIndicatorWrapper}>
                    <div
                        style={{cursor: "default"}}
                        className={
                            step === "domain"
                                ? `${s.stepIndicator} ${s.active}`
                                : s.stepIndicator
                        }
                    >
                        <GlobalSvgSelector id="check-circle-icon"/>
                        <span className={s.stepName}>Домен</span>
                        <span className={s.stepNumber}>1</span>
                    </div>

                    <div
                        onClick={() => setStep('settings')}
                        className={
                            step === "settings"
                                ? `${s.stepIndicator} ${s.active}`
                                : s.stepIndicator
                        }
                    >
                        {step === "prelanding" || step === "redirect" ? (
                            <GlobalSvgSelector id="check-circle-icon"/>
                        ) : null}
                        <span className={s.stepName}>Настройка</span>
                        <span className={s.stepNumber}>2</span>
                    </div>
                    <div
                        onClick={() => setStep('prelanding')}
                        className={
                            step === "prelanding"
                                ? `${s.stepIndicator} ${s.active}`
                                : s.stepIndicator
                        }
                    >
                        {step === "redirect" && (
                            <GlobalSvgSelector id="check-circle-icon"/>
                        )}
                        <span className={s.stepName}>Преленд</span>
                        <span className={s.stepNumber}>3</span>
                    </div>
                    <div
                        onClick={() => {
                            if (fetchObjLoading) {
                                return
                            }
                            setStep('redirect')
                        }}
                        className={
                            step === "redirect"
                                ? `${s.stepIndicator} ${s.active}`
                                : s.stepIndicator
                        }
                    >
                        <span className={s.stepName}>Редирект</span>
                        <span className={s.stepNumber}>4</span>
                    </div>

                </div>
            </div>

            {/*flowSettingsWrapper*/}

            <div className={s.settingsWrapper}>

                <div className={s.settingsContent}>

                    {step === "settings" && <SettingsStep setStep={setStep}/>}

                    {step === "prelanding" && (
                        // <>
                        //     <div className={s.prelandingStep}>
                                <PrelandingStep setStep={setStep}/>
                            // </div>
                        // </>
                    )}

                    {step === "redirect" && <RedirectStep/>}


                </div>

                {/*{step === "prelanding" && (*/}
                {/*    <>*/}
                {/*        <div className={s.prelandingStep}>*/}
                {/*            <PrelandingStep setStep={setStep}/>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*)}*/}
                {/*{step === "redirect" && <RedirectStep/>}*/}
            </div>
        </div>
    );
};

export default EditFlowSettings;