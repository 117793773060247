import React, { useEffect, useRef, useState } from "react";
import s from "./header.module.scss";
// import logoImg from "../../../assets/img/logo.svg";
import mainLogo from "../../../assets/img/mainLogo.svg";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import { authSelector } from "../../../redux/slices/authSlice";
import { motion } from "framer-motion";
import { Divide as Hamburger } from "hamburger-react";
import { useDispatch, useSelector } from "react-redux";
import {
  financeSelector,
  setBalance,
  setTopUpBalanceActive,
} from "../../../redux/slices/financeSlice";
import ConfirmPopup from "../../UiKit/Popups/ConfirmPopup/ConfirmPopup";
import AnimatedBalance from "../../UiKit/AnimatedBalance/AnimatedBalance";
import { getUser } from "../../../storage/storage";
import ButtonTip from "../../UiKit/ButtonTip/ButtonTip";
import { useWindowWidth } from "../../../hooks/useWindowWidth";
import { IconButton, Tooltip } from "@mui/material";
import MuiTooltip from "../../UiKit/MuiTooltip/MuiTooltip";
import { Link, useNavigate } from "react-router-dom";
import {
  getFinanceWebSocket,
  wsOptions,
  wsSource,
} from "../../../api/WebSocket";
import useWebSocket from "react-use-websocket";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import NotificationsPopup from "../../UiKit/Popups/NotificationsPopup/NotificationsPopup";
import { logoutHandler } from "../../../api/helpers/handlers";

const headerAnimation = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.1 },
  }),
};

const Header = () => {
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [isActiveNotificationsPopup, setActiveNotificationsPopup] =
    useState(false);
  const [exitConfirmPopup, setExitConfirmPopup] = useState(false);

  // const { lastMessage } = useWebSocket(getFinanceWebSocket, wsOptions);

  const notificationsBellRef = useRef(null);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const { balance } = useSelector(financeSelector);
  const { isPartner } = useSelector(authSelector);

  const navigate = useNavigate();

  const userData = getUser();
  const user = userData ? userData.user : null;

  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [isScrolling, setIsScrolling] = useState(false);

  // useEffect(() => {
  //   if (!lastMessage) return;
  //
  //   const parsedMessage = JSON.parse(lastMessage.data);
  //
  //   if (parsedMessage?.source === wsSource.balance) {
  //     dispatch(setBalance(parsedMessage?.data?.new_balance));
  //   }
  // }, [dispatch, lastMessage]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (
        currentScrollY < lastScrollY &&
        !isScrolling &&
        currentScrollY <= 105
      ) {
        setIsScrolling(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          setIsScrolling(false);
        }, 500);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY, isScrolling]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setOpenMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpenMenu]);

  const pageWidth = useWindowWidth();

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={headerAnimation}
      custom={1}
      className={s.headerWrapper}
    >
      <ConfirmPopup
        active={exitConfirmPopup}
        setActive={setExitConfirmPopup}
        action={() => logoutHandler()}
        confirmBtnText="Выйти"
        confirmName="Выход"
        content="Вы уверены, что хотите выйти из учетной записи?"
      />
      <div className={s.leftSide}>
        <Link to="/my-flows">
          <img className={s.logoImg} src={mainLogo} alt="Logo Image" />
        </Link>
      </div>
      <div className={s.rightSide}>
        <div className={s.authData}>
          {/*<div className={s.notificationsWrapper}>*/}
          {/*  <button*/}
          {/*    ref={notificationsBellRef}*/}
          {/*    className={s.bellWrapper}*/}
          {/*    onClick={() =>*/}
          {/*      setActiveNotificationsPopup(!isActiveNotificationsPopup)*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <GlobalSvgSelector id="bell-icon" />*/}
          {/*    /!*<span className={s.notificationsCount}>+99</span>*!/*/}
          {/*    <span className={s.notificationsCount}>15</span>*/}
          {/*  </button>*/}
          {/*  <NotificationsPopup*/}
          {/*    isActive={isActiveNotificationsPopup}*/}
          {/*    setActive={setActiveNotificationsPopup}*/}
          {/*    buttonRef={notificationsBellRef}*/}
          {/*  />*/}
          {/*</div>*/}

          {!isPartner && typeof isPartner === "boolean" && (
            <div
              className={s.balanceData}
              onClick={() => dispatch(setTopUpBalanceActive(true))}
            >
              <span>
                <AnimatedBalance end={balance} />
              </span>
              <span className={s.balanceBtn}>+</span>
            </div>
          )}

          <span className={s.username} id="user">
            {user?.email}
            {pageWidth <= 1000 && <ButtonTip content={user?.email} id="user" />}
          </span>
        </div>
        <div
          style={{ height: 50 }}
          className={s.burgerMenuWrapper}
          ref={dropdownRef}
        >
          <Hamburger
            color="#5D87FF"
            size={28}
            rounded
            toggled={isOpenMenu}
            toggle={setOpenMenu}
          />

          <div
            className={
              isOpenMenu
                ? `${s.profileMenuWrapper} ${s.active}`
                : s.profileMenuWrapper
            }
          >
            <div className={s.profileMenu}>
              <div className={s.header}>
                <span>Профиль</span>
                <button
                  className={s.closeMenuBtn}
                  onClick={() => setOpenMenu(false)}
                >
                  <GlobalSvgSelector id="close-icon" />
                </button>
              </div>
              <ul className={s.content}>
                {/*<li className={s.contentItem}>*/}
                {/*  <button*/}
                {/*    className={s.balance}*/}
                {/*    onClick={() => {*/}
                {/*      navigate("/account");*/}
                {/*      setOpenMenu(false);*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <GlobalSvgSelector id="account-icon" />*/}
                {/*    Личный кабинет*/}
                {/*  </button>*/}
                {/*</li>*/}

                {!isPartner && typeof isPartner === "boolean" && (
                  <li className={s.contentItem}>
                    <button
                      className={s.balance}
                      onClick={() => {
                        dispatch(setTopUpBalanceActive(true));
                        setOpenMenu(false);
                      }}
                    >
                      <GlobalSvgSelector id="wallet-white-icon" />
                      Пополнить счет
                    </button>
                  </li>
                )}

                <li className={s.contentItem}>
                  <button
                    className={s.exit}
                    onClick={() => {
                      setOpenMenu(false);
                      setExitConfirmPopup(true);
                    }}
                  >
                    <GlobalSvgSelector id="exit-icon" />
                    Выход
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Header;
