import React, { useRef, useState } from "react";
import s from "./prelandingStep.module.scss";
import wheelImg from "../../../../../assets/img/speenWheel.png";
import AppSettingsDropdown from "../../AppSettingsDropdown/AppSettingsDropdown";
import GlobalSvgSelector from "../../../../GlobalSvgSelector/GlobalSvgSelector";
import CustomCheckbox from "../../../../CustomCheckbox/CustomCheckbox";
import SpinWheel from "../../../../Prelandings/SpinWheel/SpinWheel";
import {
  setPrelandTypeLabel,
  setPrelandTypeName,
} from "../../../../../redux/slices/flowSlice";
import { prelandTypeList } from "../../../../../utils/vars/staticVariables";
import { useDispatch, useSelector } from "react-redux";
import CreatePrelanding from "./CreatePrelanding/CreatePrelanding";
import MyPrelandings from "./MyPrelandings/MyPrelandings";
import OwnPreland from "./OwnPreland/OwnPreland";

export const createdPrelandings = [
  { name: "preview 1" },
  { name: "preview 2" },
  { name: "preview 3" },
  { name: "preview 4" },
  { name: "preview 5" },
  { name: "preview 6" },
  { name: "preview 7" },
  { name: "preview 8" },
  { name: "preview 9" },
];

const PrelandingStep = ({ setStep }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(null);
  const [spinCount, setSpinCount] = useState(null);
  const [createdPreland, setCreatedPreland] = useState(
    createdPrelandings[0].name,
  );

  const [wheelNumbers, setWheelNumbers] = useState(() =>
    new Array(12).fill().map((_, index) => ({ id: index, value: "" })),
  );

  const [winSector, setWinSector] = useState(wheelNumbers[0]);

  const [prelandingStepContent, setPrelandingStepContent] =
    useState("chooseCreated");

  const fileInputRef = useRef(null);

  const dispatch = useDispatch();

  const { prelandTypeName } = useSelector((state) => state.flow);

  const updateSectorValue = (id, newValue) => {
    setWheelNumbers((currentSectors) =>
      currentSectors.map((sector) =>
        sector.id === id ? { ...sector, value: newValue } : sector,
      ),
    );
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={s.prelandingStepWrapper}>
      <div className={s.selectPrelandButtons}>
        <button
          className={`${s.selectPreland} ${
            prelandingStepContent === "chooseCreated" ? s.active : ""
          }`}
          onClick={() => setPrelandingStepContent("chooseCreated")}
        >
          Выбрать
        </button>
        <button
          className={`${s.selectPreland} ${
            prelandingStepContent === "createNew" ? s.active : ""
          }`}
          onClick={() => setPrelandingStepContent("createNew")}
        >
          Создать
        </button>
        <button
          className={`${s.selectPreland} ${
            prelandingStepContent === "ownPreland" ? s.active : ""
          }`}
          onClick={() => setPrelandingStepContent("ownPreland")}
        >
          Загрузить
        </button>
      </div>

      {prelandingStepContent === "createNew" && (
        <CreatePrelanding setStep={setStep} />
      )}

      {prelandingStepContent === "chooseCreated" && (
        <MyPrelandings setStep={setStep} />
      )}

      {prelandingStepContent === "ownPreland" && (
        <OwnPreland setStep={setStep} />
      )}
    </div>
  );
};

export default PrelandingStep;
