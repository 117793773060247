import React, { useState } from "react";
import s from "../pixelApi.module.scss";
import { useNavigate } from "react-router-dom";
import {
  checkPixelTokenValid,
  fetchPixelApi,
  postPixelApiItem,
} from "../../../redux/slices/pixelApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleContinue } from "../../../utils/helpers/scrollTop";
import { toast } from "react-hot-toast";
import { getUser } from "../../../storage/storage";
import BlueButton from "../../UiKit/Buttons/BlueButton/BlueButton";
import { setWhitePageUrl } from "../../../redux/slices/flowSlice";
import BaseTextInput from "../../BaseTextInput/BaseTextInput";
import RedButton from "../../UiKit/Buttons/RedButton/RedButton";
import { VALID_TOKEN } from "../../../utils/vars/staticVariables";

const CreateNewPixelApi = () => {
  const [pixelId, setPixelId] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = localStorage.getItem("accessToken");

  const { user_id } = getUser();

  const { createLoading } = useSelector((state) => state.pixelApi);

  const validateNumbers = (input) => {
    return /^\d+$/.test(input.trim());
  };

  function validateAlphaNumeric(input) {
    // Перевіряємо, чи рядок містить лише латинські букви (великі або малі) та цифри
    return /^[a-zA-Z0-9]+$/.test(input.trim());
  }

  const successAction = () => {
    dispatch(fetchPixelApi());
    toast.success("Pixel Api был успешно создан");
    navigate("/pixel-api");
    handleContinue();
  };

  const onErrorAction = (text) => {
    toast.error(text);
  };

  const onCreateHandler = async () => {
    if (!validateNumbers(pixelId)) {
      toast.error("Некоректное значение Pixel");
      return;
    }

    if (!validateAlphaNumeric(token)) {
      toast.error("Некоректное значение Token");
      return;
    }

    const isValidResult = await dispatch(checkPixelTokenValid(token));

    if (isValidResult?.payload?.detail === VALID_TOKEN) {
      dispatch(
        postPixelApiItem({
          successAction,
          data: {
            pixel_id: pixelId,
            token: token,
            owner: user_id,
          },
          onErrorAction,
        }),
      );
    } else {
      toast.error("Токен недействителен");
    }
  };

  return (
    <div className={s.pixelWrapper}>
      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Создание Pixel API</h3>
      </div>
      <div className={s.pixelApiForm}>
        <div className={s.inputsWrapper}>
          <div>
            <h5 className={s.subTitle}>Pixel</h5>
            <BaseTextInput
              action={(e) => setPixelId(e.target.value)}
              value={pixelId}
              placeholder="Введите Pixel"
            />
          </div>

          <div>
            <h5 className={s.subTitle}>Token</h5>
            <BaseTextInput
              action={(e) => setToken(e.target.value)}
              value={token}
              placeholder="Введите Token"
            />
          </div>
        </div>
      </div>
      <div className={s.buttonsWrapper}>
        <RedButton
          width={120}
          text="Отменить"
          action={() => {
            navigate("/pixel-api");
            handleContinue();
          }}
        />
        <BlueButton
          width={120}
          text="Создать"
          pending={createLoading}
          action={onCreateHandler}
        />
      </div>
    </div>
  );
};

export default CreateNewPixelApi;
