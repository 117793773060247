import React, { useState } from "react";
import s from "./prelandings.module.scss";
import GlobalSvgSelector from "../GlobalSvgSelector/GlobalSvgSelector";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import ConfirmPopup from "../UiKit/Popups/ConfirmPopup/ConfirmPopup";
import MuiTooltip from "../UiKit/MuiTooltip/MuiTooltip";

const PrelandingItem = ({
  item,
  getFlows,
  onDeleteHandler,
  duplicateFlowPending,
}) => {
  const navigate = useNavigate();
  const [isActiveCreateNote, setActiveCreateNote] = useState(false);
  const [isActiveEditNote, setActiveEditNote] = useState(false);
  const [activeRemovePopup, setActiveRemovePopup] = useState(false);
  const [copyLinksPopupIsActive, setCopyLinksPopup] = useState(false);
  const [activeCreateFlowPopup, setActiveCreateFlowPopup] = useState(false);

  const [noteText, setNoteText] = useState("");

  const dispatch = useDispatch();

  return (
    <>
      <td>
        {/*<ConfirmPopup*/}
        {/*    active={activeCreateFlowPopup}*/}
        {/*    setActive={setActiveCreateFlowPopup}*/}
        {/*    // action={() => onDeleteHandler(item.id)}*/}
        {/*    action={() => {*/}
        {/*        setActiveCreateFlowPopup(false)*/}
        {/*        onDuplicateFlowHandler()*/}
        {/*    }}*/}
        {/*    confirmBtnText='Продолжить'*/}
        {/*    confirmName='Дублирование потока'*/}
        {/*    content="При дублировании потока с вашего баланса спишется $6 за домен"*/}
        {/*/>*/}
        <ConfirmPopup
          active={activeRemovePopup}
          setActive={setActiveRemovePopup}
          action={() => onDeleteHandler(item.id)}
          confirmBtnText="Удалить"
          confirmName="Удаление"
          // content="Удалить прелендинг"
        />
        {item.name}
      </td>
      <td>Колесо</td>
      <td>
        <div className={s.actionsWrapper}>
          <div className={s.actions}>
            <Link to={`/prelandings/edit/${item.id}`} className={s.editBtn}>
              <MuiTooltip title="Редактировать прелендинг">
                <GlobalSvgSelector id="edit-icon" />
              </MuiTooltip>
            </Link>

            {/*<button*/}
            {/*    className={s.editBtn}*/}
            {/*    onClick={() => navigate(`/prelandings/edit/${item.id}`)}*/}
            {/*>*/}
            {/*    <MuiTooltip title='Редактировать прелендинг'>*/}
            {/*        <GlobalSvgSelector id="edit-icon"/>*/}
            {/*    </MuiTooltip>*/}
            {/*</button>*/}

            <button
              className={s.removeBtn}
              onClick={() => setActiveRemovePopup(true)}
            >
              <MuiTooltip title="Удалить прелендинг">
                <GlobalSvgSelector id="remove-icon" />
              </MuiTooltip>
            </button>
          </div>
        </div>
      </td>
    </>
  );
};

export default PrelandingItem;
