export const validateAlphaNumbericStr = (s) => {
    const regex = /^[A-Za-z0-9]+$/;
    return regex.test(s.trim())
}

export const containsNonSpaceCharacter = (str) => {
    return /\S/.test(str);
};

export const hasNullPercentageArr = (arr) => arr.some(
    (item) => item.percentage === null,
);

export const hasNullApplicationArr = (arr) => arr.some(
    (item) => item.application === null,
);

export const validateIPs = (input) => {
    // Замінюємо нові рядки і коми на пробіли
    const normalizedInput = input.replace(/\n|,/g, ' ');
    // Розділяємо введений текст на окремі IP адреси за пробілами
    const ips = normalizedInput.split(' ').filter(Boolean); // видаляємо пусті рядки

    const pattern = /^[0-9.]+$/;

    // Перевіряємо кожен елемент масиву
    for (let ip of ips) {
        if (!pattern.test(ip)) {
            return false; // Якщо елемент не відповідає патерну, повертаємо false
        }
    }

    return true;


    // Регулярний вираз для перевірки IP адреси
    // const ipPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    // Перевіряємо кожну IP адресу
    // for (let ip of ips) {
    //     if (!ipPattern.test(ip)) {
    //         return false; // Якщо хоча б одна IP адреса невалідна, повертаємо false
    //     }
    // }
    //
    // return true; // Всі IP адреси валідні
}