import React from "react";
import { Link, Outlet } from "react-router-dom";
import AuthBackground from "../../components/UiKit/AuthBackground/AuthBackground";
import GlobalSvgSelector from "../../components/GlobalSvgSelector/GlobalSvgSelector";
import s from "./authLayout.module.scss";

const AuthLayout = () => {
  return (
    <div className={s.authWrapper}>
      <div className={s.bgWrapper}>
        <AuthBackground />
      </div>

      <div className={s.logoWrapper}>
        <Link to="/auth/login">
          <GlobalSvgSelector id="logo-icon" />
        </Link>
      </div>

      <Outlet />

      <div className={s.companyRightsWrapper}>
        WWA © 2024. All rights reserved.
      </div>
    </div>
  );
};

export default AuthLayout;
