import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";
import { authSlice } from "./authSlice";
import { toast } from "react-hot-toast";
import { getIsStaff, getUser } from "../../storage/storage";

export const fetchAppList = createAsyncThunk(
  "application/fetchAppList",
  async (_, thunkAPI) => {
    try {
      const response = await $api.get("/applications/");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const startCreateFlow = createAsyncThunk(
  "application/startCreateFlow",
  async ({ onErrorAction, onSuccessAction, setLimitPopup }) => {
    try {
      const response = await $api.get("/configuration/flow/create/");

      onSuccessAction(response.data.flow.id);
      return response.data;
    } catch (error) {
      // const errorMessage = error.response.data.detail;
      const errorMessage = error?.response?.data?.details;

      if (
        errorMessage ===
        "Вы достигли дневного лимита по количеству новых потоков. Чтобы расширить лимит обратитесь в саппорт"
      ) {
        setLimitPopup();
        throw new Error(errorMessage);
      }

      if (errorMessage === "Нет свободных доменов") {
        onErrorAction("Нет доступных доменов для создания потока");
        throw new Error(errorMessage);
      }
      if (
        errorMessage ===
        "Недостаточная сумма на балансе для создания потока. Пополните баланс."
      ) {
        onErrorAction("Недостачно денег на балансе");
        throw new Error(errorMessage);
      } else {
        onErrorAction("Произошла ошибка при создании потока");
        throw error;
      }
    }
  },
);

export const fetchPriorities = createAsyncThunk(
  "application/fetchPriorityAppList",
  async () => {
    try {
      const response = await $api.get("/priorities/");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const updatePriorities = createAsyncThunk(
  "application/updatePriorityAppList",
  async ({ data, toast }) => {
    try {
      const response = await $api.put("/priorities/", data);

      toast.success("Приоритет приложений был обновлен");

      return response.data;
    } catch (error) {
      toast.error("Произошла ошибка");
      throw error;
    }
  },
);

export const createRedirectorStep = createAsyncThunk(
  "application/createRedirectorStep",
  async ({ data, onSuccessAction }) => {
    try {
      const response = await $api.post(
        "/configuration/flow/create/set_redirector/",
        data,
      );

      onSuccessAction();

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const applicationSlice = createSlice({
  name: "application",
  initialState: {
    appList: [],
    appListLoading: false,

    flowId: null,
    startCreateFlowLoading: false,

    singleApp: {},
    singleAppLoading: false,

    error: null,
    settingsLoading: false,

    createRedirectorStepLoading: false,
    createFlowLoading: false,

    flowList: [],
    totalCount: 0,

    popupActive: false,

    priorities: [],
    prioritiesPending: false,
    updatePrioritiesPending: false,
  },
  reducers: {
    setPriorities: (state, action) => {
      state.priorities = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppList.pending, (state) => {
        state.startCreateFlowLoading = true;
        state.appListLoading = true;
        state.appList = [];
      })
      .addCase(fetchAppList.fulfilled, (state, { payload }) => {
        state.appList = payload;
        // state.appList = !getIsStaff ? result : result.filter(item => item?.is_alive === true)
        state.appListLoading = false;
      })
      .addCase(fetchAppList.rejected, (state) => {
        state.appListLoading = false;
        state.appList = [];
      })

      .addCase(fetchPriorities.pending, (state) => {
        state.priorities = [];
        state.prioritiesPending = true;
      })
      .addCase(fetchPriorities.fulfilled, (state, action) => {
        state.priorities = action.payload.priorities;
        state.prioritiesPending = false;
      })
      .addCase(fetchPriorities.rejected, (state) => {
        state.prioritiesPending = false;
      })

      .addCase(updatePriorities.pending, (state) => {
        state.updatePrioritiesPending = true;
      })
      .addCase(updatePriorities.fulfilled, (state, action) => {
        state.updatePrioritiesPending = false;
        state.priorities = action.payload.priorities;
        console.log("action", action);
      })
      .addCase(updatePriorities.rejected, (state) => {
        state.updatePrioritiesPending = false;
      })

      /////////////////////////  startCreateFlow  ///////////////////
      .addCase(startCreateFlow.pending, (state) => {
        state.startCreateFlowLoading = true;
        state.createFlowLoading = true;
      })
      .addCase(startCreateFlow.fulfilled, (state, action) => {
        state.flowId = action.payload.flow.id;
        state.startCreateFlowLoading = false;
        state.createFlowLoading = false;
      })
      .addCase(startCreateFlow.rejected, (state) => {
        state.startCreateFlowLoading = false;
        state.createFlowLoading = false;
      })

      /////////////////////  createRedirectorStep  ///////////////////////
      .addCase(createRedirectorStep.pending, (state) => {
        state.createRedirectorStepLoading = true;
      })
      .addCase(createRedirectorStep.fulfilled, (state, action) => {
        state.createRedirectorStepLoading = false;
        state.popupActive = true;
      })
      .addCase(createRedirectorStep.rejected, (state) => {
        state.createRedirectorStepLoading = false;
      });
  },
});

export const { setPriorities } = applicationSlice.actions;

export default applicationSlice.reducer;

export const applicationSelector = (state) => state.application;
