import React, { useState } from "react";
import s from "./redirectStep.module.scss";
import CreateNewRedirect from "./CreateNewRedirect/CreateNewRedirect";
import { MyRedirects } from "./MyRedirects/MyRedirects";
import GlobalSvgSelector from "../../../../GlobalSvgSelector/GlobalSvgSelector";
import {useNavigate} from "react-router-dom";
import EditRedirect from "./EditRedirect/EditRedirect";
// import {useState} from "@types/react";

const RedirectStep = () => {
  const [stepContent, setStepContent] = useState("myRedirects");

  const onCreateHandler = () => {
    if (stepContent === "createNew") {
    }
    // if (redirectStepContent === "createNew") {
    //     dispatch(createRedirectorStep(sendDataObj));
    //   }
    //   if (redirectStepContent === "myRedirects") {
    //     console.log("myRedirects dispatch");
    //     // dispatch(createRedirectorStep(sendDataObj))
    //   }
  };

  return (
      <div className={s.redirectStepWrapper}>

          <div className={s.selectRedirectButtons}>

              <button
                  className={`${s.selectRedirect} ${
                      stepContent === "myRedirects" ? s.active : ""
                  }`}
                  onClick={() => setStepContent("myRedirects")}
              >
                  Мои редиректы
              </button>

              {
                  stepContent !== "editRedirect" && <button
                      className={`${s.selectRedirect} ${
                          stepContent === "createNew" ? s.active : ""
                      }`}
                      onClick={() => setStepContent("createNew")}
                  >
                      Создать редирект
                  </button>
              }


              {
                  stepContent === 'editRedirect' && <button
                      className={`${s.selectRedirect} ${
                          stepContent === "editRedirect" ? s.active : ""
                      }`}
                      onClick={() => setStepContent("editRedirect")}
                  >
                      Редактирование редиректа
                  </button>
              }
          </div>

          {/*<div className={s.selectPrelandButtons}>*/}
          {/*    <button*/}
          {/*        className={`${s.selectPreland} ${*/}
          {/*            prelandingStepContent === "chooseCreated" ? s.active : ""*/}
          {/*        }`}*/}
          {/*        onClick={() => setPrelandingStepContent("chooseCreated")}*/}
          {/*    >*/}
          {/*        Выбрать преленд*/}
          {/*    </button>*/}
          {/*    <button*/}
          {/*        className={`${s.selectPreland} ${*/}
          {/*            prelandingStepContent === "createNew" ? s.active : ""*/}
          {/*        }`}*/}
          {/*        onClick={() => setPrelandingStepContent("createNew")}*/}
          {/*    >*/}
          {/*        Создать преленд*/}
          {/*    </button>*/}
          {/*</div>*/}

          <div className={s.stepsWrapper}>
              {stepContent === "myRedirects" && <MyRedirects setContent={setStepContent}/>}
              {stepContent === "createNew" && <CreateNewRedirect setContent={setStepContent}/>}
              {stepContent === "editRedirect" && <EditRedirect setContent={setStepContent}/>}
          </div>

          {/*<div className={s.buttonsWrapper}>*/}
          {/*    <button*/}
          {/*        className={s.cancelBtn}*/}
          {/*        // onClick={() => navigate("/app-catalog")}*/}
          {/*    >*/}
          {/*        Отмена*/}
          {/*    </button>*/}
          {/*    <button*/}
          {/*        className={s.nextStepBtn}*/}
          {/*        // onClick={onSendRedirect}*/}
          {/*        // disabled={createRedirectorStepLoading}*/}
          {/*    >*/}
          {/*        Сохранить*/}
          {/*        /!*{createRedirectorStepLoading ? "Загрузка" : "Сохранить"}*!/*/}
          {/*    </button>*/}
          {/*</div>*/}
      </div>
  );
};

export default RedirectStep;
