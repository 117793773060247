import React, { useEffect, useRef, useState } from "react";
import s from "./pixelApi.module.scss";
import GlobalSvgSelector from "../GlobalSvgSelector/GlobalSvgSelector";
import Pagination from "../Pagination/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePixelApiItem,
  fetchPixelApi,
  pixelApiSetCurrentPage,
} from "../../redux/slices/pixelApiSlice";

import { handleContinue } from "../../utils/helpers/scrollTop";
import PixelApiTr from "./PixelApiTr";
import { toast } from "react-hot-toast";
import qs from "qs";
import Search from "../Search/Search";
import { setCurrentPage } from "../../redux/slices/flowSlice";
import CustomSkeleton from "../CustomSkeleton/CustomSkeleton";
import BlueButton from "../UiKit/Buttons/BlueButton/BlueButton";
import EmptyList from "../EmptyList/EmptyList";
import { getIsStaff } from "../../storage/storage";

const PixelApi = () => {
  const [searchValue, setSearchValue] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);

  const isSearch = useRef(false);
  const isMounted = useRef(false);

  const { pixelApiList, pixelApiListLoading, totalCount, currentPage } =
    useSelector((state) => state.pixelApi);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPixelApi = () => {
    const fetchPixelApiParams = { page: currentPage, search: searchValue };
    dispatch(fetchPixelApi(fetchPixelApiParams));
  };

  useEffect(() => {
    if (isMounted.current) {
      const queryString = qs.stringify({
        page: currentPage,
        search: searchValue,
      });

      navigate(`?${queryString}`);
    }

    isMounted.current = true;
  }, [currentPage, searchValue]);

  useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));
      const { page, search } = params;
      dispatch(pixelApiSetCurrentPage(parseInt(page)));
      setSearchValue(search);
      isSearch.current = true;
    }
  }, []);

  useEffect(() => {
    if (!isSearch.current) {
      getPixelApi();
    }

    isSearch.current = false;
  }, [currentPage, searchValue]);

  useEffect(() => {
    // return () => {
    //   dispatch(pixelApiSetCurrentPage(1))
    // }
  }, []);

  const onDeleteHandler = async (id) => {
    await dispatch(deletePixelApiItem(id));
    toast.success("Запись была успешно удалена");
    handleContinue();
    getPixelApi();
    // await dispatch(fetchPixelApi());
  };

  return (
    <div className={s.pixelWrapper}>
      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Pixel API</h3>
        <Link to="/pixel-api/create-new" className={s.blueButton}>
          Создать
        </Link>
        {/*<BlueButton*/}
        {/*    width={120}*/}
        {/*    text='Создать'*/}
        {/*    action={() => navigate("/pixel-api/create-new")}*/}
        {/*/>*/}
      </div>

      <div className={s.searchBar}>
        <div className={s.recordsCount}>
          Всего: {!pixelApiListLoading && <span>{totalCount}</span>}
        </div>
        <Search searchValue={searchValue} setSearchValue={setSearchValue} />
      </div>

      <div className={s.container}>
        {pixelApiListLoading ? (
          <CustomSkeleton styles={s.pixelApiSkeleton} />
        ) : (
          <>
            <div className={s.tableWrapper}>
              <div className={s.table}>
                {totalCount === 0 && !pixelApiListLoading ? (
                  <EmptyList />
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th>Пиксель</th>
                        <th>Токен</th>
                        {getIsStaff() && (
                          <>
                            <th>Оффер</th>
                          </>
                        )}
                        <th>Статус</th>
                        {getIsStaff() && (
                          <>
                            <th>Почта</th>
                            <th>Пометка саппорта</th>
                          </>
                        )}
                        <th>Действия</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pixelApiList.map((item) => (
                        <tr key={item.id}>
                          <PixelApiTr
                            {...item}
                            onDeleteHandler={onDeleteHandler}
                          />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            {totalCount > 8 && (
              <div className={s.paginationWrapper}>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={(page) =>
                    dispatch(pixelApiSetCurrentPage(page))
                  }
                  // setCurrentPage={setCurrentPage}
                  total={totalCount}
                  limit={8}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PixelApi;
