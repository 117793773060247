import React, { useEffect, useRef, useState } from "react";
import s from "../redirectStep.module.scss";
import GlobalSvgSelector from "../../../../../GlobalSvgSelector/GlobalSvgSelector";
import LineChart from "../../../../../UiKit/LineChart/LineChart";
// import { colorList } from "../../../../../../utils/lists";
import DoughnutChart from "../../../../../UiKit/DoughnutChart/DoughnutChart";
import { fetchRedirectorsList } from "../../../../../../redux/slices/redirectorSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { handleContinue } from "../../../../../../utils/helpers/scrollTop";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeFlowRedirectById,
  setCurrentEditRedirectorId,
  setSelectedRedirectors,
} from "../../../../../../redux/slices/flowSlice";
import MultiSelect from "../../../../../MultiSelect/MultiSelect";
import { colorList } from "../../../../../../utils/vars/staticVariables";
import MuiTooltip from "../../../../../UiKit/MuiTooltip/MuiTooltip";
import RedButton from "../../../../../UiKit/Buttons/RedButton/RedButton";
import BlueButton from "../../../../../UiKit/Buttons/BlueButton/BlueButton";

export const scrollToElement = (ref) => {
  // if (ref.current) {
  // Скрол до елемента
  ref.current.scrollIntoView({ behavior: "smooth" });

  // Додаткове зміщення скрола на 10 пікселів вниз
  // window.scrollBy(0, 1000);
  // }
};

export const MyRedirects = ({ setContent }) => {
  const dispatch = useDispatch();

  const { redirectorsList } = useSelector((state) => state.redirector);

  const { changeRedirectLoading, selectedRedirectors, flowObj } = useSelector(
    (state) => state.flow,
  );

  const redirectorState = useSelector((state) => state.redirector);

  const buttonsRef = useRef(null); // Створення ref

  const navigate = useNavigate();

  const { id } = useParams();

  // const scrollToElement = (ref) => {
  //   if (ref.current) {
  //     // Скрол до елемента
  //     ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //
  //     // Додаткове зміщення скрола на 10 пікселів вниз
  //     // window.scrollBy(0, 1000);
  //   }
  // };

  useEffect(() => {
    dispatch(fetchRedirectorsList({}));
  }, []);

  const convertToNumber = (str) => {
    let number = parseFloat(str);
    return Math.round(number);
  };

  const sendDataObj = {
    flow_id: parseInt(id),
    // redirector_id: [redirectorId],
    redirector_id: selectedRedirectors.map((item) => item?.id),
  };

  // console.log()

  const onUpdateRedirect = () => {
    if (flowObj?.settings === null) {
      toast.error("Сперва настройте White Page");
      return;
    }

    if (selectedRedirectors.length < 1) {
      toast.error("Передайте минимум один редирект");
      return;
    }

    // console.log('flowObj', flowObj)
    // console.log('flowObj length', flowObj?.url?.length)

    // if (flowObj?.url?.length < 5) {
    //   toast.error("Сперва настройте White Page");
    //   return
    // }

    const onErrorAction = (text) => {
      toast.error(text);
      // navigate("/my-flows");
      // handleContinue();
    };
    const onSuccessAction = () => {
      toast.success("Редирект потока был успешно настроен");
      navigate("/my-flows");
      handleContinue();
    };

    dispatch(
      changeFlowRedirectById({ sendDataObj, onSuccessAction, onErrorAction }),
    );
  };

  const sub7ListOptions = redirectorsList.map(({ sub7 }) => ({
    value: sub7,
    label: sub7,
  }));

  selectedRedirectors?.map((item) =>
    console.log("app perce", item?.application_percentages, "index: "),
  );

  return (
    <div className={s.myRedirectWrapper}>
      <>
        <div
          className={s.chooseMyRedirect}
          // style={{
          //   marginTop: 0,
          //   borderRadius: "0px 0px 8px" + " 8px",
          // }}
        >
          <div className={s.myRedirectors}>
            {selectedRedirectors.length < 1 && (
              <div>
                <h5 className={s.subTitle}>Выберите редирект:</h5>

                {/*<h3 className={s.chooseNewRedirect}>Выберите редирект: </h3>*/}

                <MultiSelect
                  isClearable={false}
                  options={sub7ListOptions}
                  setSelectedOptions={(o) => {
                    // const filteredSelectedArr = selectedRedirectors.filter(
                    //     (redirector) => redirector.id !== id,
                    // );
                    const currentRedirector = redirectorsList.find(
                      (item) => item.sub7 === o.value,
                    );

                    // const updatedSelectedRedirectors = [
                    //   ...filteredSelectedArr,
                    //   currentRedirector,
                    // ];
                    dispatch(setSelectedRedirectors([currentRedirector]));
                  }}
                  placeholder="Sub7"
                  isMulti={false}
                />
              </div>
            )}

            {selectedRedirectors.map(
              ({ sub7, id, application_percentages }, index) => (
                <div key={index} className={s.selectWrapper}>
                  {/*<h3 className={s.redirectName}>Редирект {index + 1}</h3>*/}
                  <h5 className={s.subTitle}>Редирект {index + 1}</h5>

                  <MultiSelect
                    defaultValue={{
                      value: sub7,
                      label: sub7,
                    }}
                    isClearable={false}
                    options={sub7ListOptions}
                    setSelectedOptions={(o) => {
                      if (
                        selectedRedirectors.find((obj) => obj.sub7 === o.value)
                      ) {
                        toast.error("Вы уже выбрали этот редирект");
                        return;
                      }

                      scrollToElement(buttonsRef);

                      const filteredSelectedArr = selectedRedirectors.filter(
                        (redirector) => redirector.id !== id,
                      );
                      const currentRedirector = redirectorsList.find(
                        (item) => item.sub7 === o.value,
                      );
                      const updatedSelectedRedirectors = [
                        ...filteredSelectedArr,
                        currentRedirector,
                      ];
                      dispatch(
                        setSelectedRedirectors(updatedSelectedRedirectors),
                      );
                    }}
                    placeholder="Sub7"
                    isMulti={false}
                  />
                  <div className={s.redirectorItemWrapper}>
                    <div className={s.redirectorItem}>
                      <div className={s.itemHeader}>
                        <div className={s.manageButtonsWrapper}>
                          <div className={s.manageButton}>
                            <button
                              className={s.editBtn}
                              onClick={() => {
                                dispatch(setCurrentEditRedirectorId(id));
                                setContent("editRedirect");
                              }}
                              // onClick={() =>
                              //   navigate(`/all-redirectors/edit/${id}`)
                              // }
                            >
                              <MuiTooltip title="Редактировать редирект">
                                <GlobalSvgSelector id="edit-icon" />
                              </MuiTooltip>
                              {/*<GlobalSvgSelector id="edit-icon"/>*/}
                            </button>
                          </div>
                          <div className={s.manageButton}>
                            <button
                              className={s.deleteBtn}
                              onClick={() => {
                                const filteredSelectedArr =
                                  selectedRedirectors.filter(
                                    (redirector) => redirector.id !== id,
                                  );
                                dispatch(
                                  setSelectedRedirectors(filteredSelectedArr),
                                );
                              }}
                              // onClick={() =>
                              //   navigate(`/all-redirectors/edit/${id}`)
                              // }
                            >
                              <MuiTooltip title="Убрать редирект">
                                <GlobalSvgSelector id="remove-icon" />
                              </MuiTooltip>
                              {/*<GlobalSvgSelector id="edit-icon"/>*/}
                            </button>
                          </div>
                        </div>
                        <div className={s.names}>
                          <div className={s.firstSub}>
                            <h3>{sub7}</h3>
                            <h6>sub7</h6>
                          </div>
                        </div>
                      </div>
                      <div className={s.itemContent}>
                        <div className={s.additions}>
                          {application_percentages &&
                            application_percentages.map((item, index) => (
                              <div className={s.add1} key={item.id}>
                                <div className={s.header}>
                                  <div className={s.addNumber}>
                                    Приложение
                                    {/*<span>{index + 1}</span>*/}
                                    <span>{item.application.name}</span>
                                  </div>
                                  {/*<div*/}
                                  {/*    className={s.addPercentage}*/}
                                  {/*    style={{*/}
                                  {/*      color: colorList[index],*/}
                                  {/*      backgroundColor: "#252B48",*/}
                                  {/*    }}*/}
                                  {/*>*/}
                                  {/*  {convertToNumber(item.percentage)} %*/}
                                  {/*</div>*/}
                                </div>
                                <div className={s.rowPercentageWrapper}>
                                  <div className={s.lineChartHeader}>
                                    {item?.service_count}

                                    <div
                                      className={s.addPercentage}
                                      style={{
                                        color: colorList[index],
                                        backgroundColor: "#252B48",
                                      }}
                                    >
                                      {convertToNumber(item.percentage)} %
                                    </div>
                                  </div>

                                  <div className={s.rowPercentageWrapper}>
                                    <LineChart
                                      percentage={convertToNumber(
                                        item.percentage,
                                      )}
                                      color={colorList[index]}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className={s.percentageCircle}>
                          <DoughnutChart list={application_percentages} />

                          <div className={s.sub7Name}>
                            <div className={s.firstSub}>
                              <h3>{sub7}</h3>
                              <h6>sub7</h6>
                            </div>
                          </div>

                          {/*<div className={s.firstSub}>*/}
                          {/*  <h3>{sub7}</h3>*/}
                          {/*  <h6>sub7</h6>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                    {/*<div className={s.removeChosenRedirect}>*/}
                    {/*  <div className={s.minusBtnWrapper}>*/}
                    {/*    <button*/}
                    {/*        className={s.minusBtn}*/}
                    {/*        onClick={() => {*/}
                    {/*          const filteredSelectedArr =*/}
                    {/*              selectedRedirectors.filter(*/}
                    {/*                  (redirector) => redirector.id !== id,*/}
                    {/*              );*/}
                    {/*          dispatch(*/}
                    {/*              setSelectedRedirectors(filteredSelectedArr),*/}
                    {/*          );*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*      <GlobalSvgSelector id="circle-minus-icon"/>*/}
                    {/*    </button>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              ),
            )}
          </div>
          <div className={s.addRedirect}>
            {selectedRedirectors.length > 0 && (
              <div>
                {/*<h3 className={s.chooseNewRedirect}>*/}
                {/*  Выберите дополнительный редирект:{" "}*/}
                {/*</h3>*/}
                <h5 className={s.subTitle}>
                  Выберите дополнительный редирект:{" "}
                </h5>
                <MultiSelect
                  isClearable={false}
                  value={null}
                  options={sub7ListOptions}
                  setSelectedOptions={(o) => {
                    if (
                      selectedRedirectors.find((obj) => obj.sub7 === o.value)
                    ) {
                      toast.error("Вы уже выбрали этот редирект");
                      return;
                    }
                    // const filteredSelectedArr = selectedRedirectors.filter(
                    //     (redirector) => redirector.id !== id,
                    // );
                    const currentRedirector = redirectorsList.find(
                      (item) => item.sub7 === o.value,
                    );
                    const updatedSelectedRedirectors = [
                      ...selectedRedirectors,
                      currentRedirector,
                    ];
                    dispatch(
                      setSelectedRedirectors(updatedSelectedRedirectors),
                    );
                    scrollToElement(buttonsRef);
                  }}
                  placeholder="Sub7"
                  isMulti={false}
                />
              </div>
            )}
          </div>
        </div>

        {/*<div className={s.buttonsWrapper}>*/}
        {/*  <button*/}
        {/*      className={s.cancelBtn}*/}
        {/*      onClick={() => {*/}
        {/*        navigate("/my-flows");*/}
        {/*        handleContinue();*/}
        {/*      }}*/}
        {/*  >*/}
        {/*    Отмена*/}
        {/*  </button>*/}
        {/*  <button*/}
        {/*      className={s.nextStepBtn}*/}
        {/*      onClick={onUpdateRedirect}*/}
        {/*      disabled={changeRedirectLoading}*/}
        {/*  >*/}
        {/*    {changeRedirectLoading ? "Загрузка" : "Сохранить"}*/}
        {/*  </button>*/}
        {/*</div>*/}

        <div className={s.buttonsWrapper} ref={buttonsRef}>
          <RedButton
            width={120}
            text="Отменить"
            action={() => {
              navigate("/my-flows");
              handleContinue();
            }}
          />
          <BlueButton
            width={120}
            text="Сохранить"
            pending={changeRedirectLoading}
            action={onUpdateRedirect}
          />
        </div>
      </>
    </div>
  );
};
