import React, {useEffect, useState, memo, useCallback} from "react";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import PercentageBtn from "../PercentageBtn/PercentageBtn";
import MultiSelect from "../../MultiSelect/MultiSelect";
import s from "../allRedirectors.module.scss";
import MuiTooltip from "../../UiKit/MuiTooltip/MuiTooltip";

const removeBlock = (indexToRemove, setApplicationPercentages) => {
    setApplicationPercentages((prevBlocks) =>
        prevBlocks.filter((_, index) => index !== indexToRemove)
    );
};

const updateObjectAtIndex = (index, newObject, setApplicationPercentages) => {
    setApplicationPercentages((prevState) => {
        const newState = [...prevState];
        newState[index] = newObject;
        return newState;
    });
};

const ApplicationItem = memo(({index, setApplicationPercentages, options}) => {
    const [percentageValue, setPercentageValue] = useState(null);
    const [appId, setAppId] = useState(null);

    useEffect(() => {
        const applicationPercentagesObj = {
            application: appId,
            percentage: percentageValue,
        };

        updateObjectAtIndex(index, applicationPercentagesObj, setApplicationPercentages);
    }, [appId, percentageValue, index, setApplicationPercentages]);

    return (
        <div className={s.addition}>
            <div className={s.dropDownContainer}>
                {/*<h6>Приложение</h6>*/}

                {/*<h5 className={s.subTitle}>Приложение</h5>*/}
                <h5 className={s.subTitle}>-</h5>


                <div className={s.selectWrapper}>
                    <MultiSelect
                        isClearable={false}
                        options={options}
                        setSelectedOptions={(o) => setAppId(o.value)}
                        placeholder="Приложение"
                        isMulti={false}
                    />
                </div>

            </div>
            <div className={s.rightContainer}>
                <PercentageBtn
                    percentageValue={percentageValue}
                    setPercentageValue={setPercentageValue}
                />
                {index > 0 ? (
                    <div className={s.removeBtnWrapper}>
                        <button
                            className={s.removeBtn}
                            onClick={() => removeBlock(index, setApplicationPercentages)}
                        >
                            {/*<MuiTooltip title='Убрать из списка выбранных приложений'>*/}
                            <MuiTooltip title='Убрать приложение'>
                                <GlobalSvgSelector id="remove-icon"/>
                            </MuiTooltip>
                        </button>
                    </div>
                ) : <div className={s.emptyWrapper}></div>}
            </div>
        </div>
    );
});

export default ApplicationItem;
