import s from "./assets/styles/app.module.scss";
import { useRoutes } from "./hooks/useRoutes";
import { isUserAuthenticated } from "./redux/slices/authSlice";
import 'react-tooltip/dist/react-tooltip.css'
import ToasterAlert from "./components/UiKit/ToasterAlert/ToasterAlert";
import React from "react";


function App() {
  const routes = useRoutes(isUserAuthenticated());

  return <div className={s.appWrapper}>
    <ToasterAlert/>
    {routes}</div>;
}

export default App;
