export const documentationList = [
    { name: "Обзор сервиса", icon: 'rules-icon', link: "https://stingy-trout-0f1.notion.site/WWA-303d7c630861460a8856e43a3e41e1c6?pvs=4" },
    { name: "Создание потока", icon: 'rules-icon', link: "https://stingy-trout-0f1.notion.site/WWA-fd9f67e383d742efae47ae8ecd1db81e?pvs=4" },
    { name: "Интеграция с трекером", icon: 'rules-icon', link: "https://stingy-trout-0f1.notion.site/WWA-c15ff07456714895bcba8feb088bd709?pvs=4" },
    { name: "FB PIXEL", icon: 'rules-icon', link: "https://stingy-trout-0f1.notion.site/WWA-FB-PIXEL-6b6fd781231b4e909e27728e0e5a6750?pvs=4" },
]


export const lessonsList = [
    { name: "Урок 1. \"Авторизация и баланс\"", icon: 'media-icon', link: "https://www.youtube.com/watch?v=5LF8gC2E8m4&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=1" },
    { name: "Урок 2. \"Потоки и редиректы\"", icon: 'media-icon', link: "https://www.youtube.com/watch?v=RM2YQEz6kH8&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=2" },
    { name: "Урок 3. \"Получение ссылки для запуска\"", icon: 'media-icon', link: "https://www.youtube.com/watch?v=UbvFVgkt9sc&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=3" },
    { name: "Урок 4. \"Пиксель\"", icon: 'media-icon', link: "https://www.youtube.com/watch?v=5n7pjw5oNys&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=4" },
    { name: "Урок 5. \"Создание пикселя БМ\"", icon: 'media-icon', link: "https://www.youtube.com/watch?v=OspbOrWppic&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=5" },
    { name: "Урок 6. \"Дополнение Пиксели\"", icon: 'media-icon', link: "https://www.youtube.com/watch?v=VHLYje9aNqQ&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=6" },
    { name: "Урок 7. \"Проверка валидности пиксель токена\"", icon: 'media-icon', link: "https://www.youtube.com/watch?v=-YwUAKD2fPo&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=7" },
];