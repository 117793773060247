import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/partials/Header/Header";
import s from "./mainLayout.module.scss";
import Sidebar from "../../components/partials/Sidebar/Sidebar";
import ToasterAlert from "../../components/UiKit/ToasterAlert/ToasterAlert";
import TopUpBalancePopup from "../../components/UiKit/Popups/TopUpBalancePopup/TopUpBalancePopup";
import UpPage from "../../components/UpPage/UpPage";
import { authSelector, fetchMyUserInfo } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchInitialData } from "../../api/helpers/fetchGlobalData";

const MainLayout = () => {
  const [showUpPage, setShowUpPage] = useState(false);
  const dispatch = useDispatch();
  const { isPartner } = useSelector(authSelector);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setShowUpPage(position > 90);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchMyUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (isPartner === false) {
      fetchInitialData(dispatch);
    }
  }, [dispatch, isPartner]);

  return (
    <div className={s.layoutContent}>
      <Header />

      {showUpPage && <UpPage />}

      <div className={s.container}>
        <Sidebar />
        <div className={s.pageContentWrapper}>
          <ToasterAlert />
          <TopUpBalancePopup />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
