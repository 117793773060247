import React, { useEffect, useMemo } from "react";
import s from "./transactions.module.scss";
import {
  exportBalances,
  exportTransactions,
  fetchTransactions,
  financeSelector,
  setTopUpBalanceActive,
} from "../../../redux/slices/financeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import WriteOffOption from "./TransactionsOptions/WriteOffOption";
import RefillOption from "./TransactionsOptions/RefillOption";
import BlueButton from "../../UiKit/Buttons/BlueButton/BlueButton";
import MultiSelect from "../../MultiSelect/MultiSelect";
import { toast } from "react-hot-toast";
import { getIsStaff } from "../../../storage/storage";
import {
  fetchUsersList,
  globalDataSelector,
} from "../../../redux/slices/globalDataSlice";
import { formExcelHandler } from "../../../utils/helpers/handlers";
import { MuiDatePicker } from "../../Blocks/MuiDatePicker/MuiDatePicker";
import {
  formatTimestampToServerDate,
  stringToDayJs,
} from "../../../utils/helpers/formatTime";
import {
  created_at_after,
  created_at_before,
  page,
  purpose,
  user,
} from "../../Statistics/ClicksStats/ClicksStats";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import { useSelectOptions } from "../../../utils/helpers/useSelectHelpers";
import { authSelector } from "../../../redux/slices/authSlice";
import { getDefaultParams } from "../../../api/helpers/scripts";
import {
  INVALID_PAGE_ERROR,
  PURPOSE_REFILL,
  PURPOSE_WRITE_OFF,
} from "../../../utils/vars/staticVariables";
import Pagination from "../../Pagination/Pagination";
import { params } from "../../../utils/vars/params";

const Transactions = () => {
  const dispatch = useDispatch();

  const { exportTransactionsPending, exportBalancesPending, totalCount } =
    useSelector(financeSelector);

  const { isPartner } = useSelector(authSelector);
  const { usersList } = useSelector(globalDataSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    page: pageValue,
    created_at_after: createdAtAfterParam,
    created_at_before: createdAtBeforeParam,
    user: userParam,
    purpose: purposeParam,
  } = useGetSearchParams();

  const purposeContent = isPartner
    ? PURPOSE_WRITE_OFF
    : purposeParam || PURPOSE_REFILL;

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const selectedUser = useMemo(() => {
    return userParam ? { value: userParam, label: userParam } : null;
  }, [userParam]);

  useEffect(() => {
    if (getIsStaff()) {
      dispatch(fetchUsersList());
    }
  }, [dispatch]);

  const usersListOptions = useSelectOptions(usersList, "email", "email");

  const onExportTransactions = () => {
    const toastId = toast.loading("Выполняется экспорт транзакций");
    const onSuccessAction = (data) => {
      formExcelHandler(data, "transactions");
      toast.success("Отчет по пополнениям был выгружен", {
        id: toastId,
        style: {
          minWidth: "470px",
        },
      });
    };

    const onErrorAction = (text) => {
      toast.error(text, {
        id: toastId,
        style: {
          minWidth: "470px",
        },
      });
    };

    dispatch(
      exportTransactions({
        params: searchParams,
        onSuccessAction,
        onErrorAction,
      }),
    );
  };

  const onExportBalances = () => {
    const toastId = toast.loading("Выполняется экспорт балансов");
    const onSuccessAction = (data) => {
      formExcelHandler(data, "balances");
      toast.success("Отчет по пополнениям был выгружен", {
        id: toastId,
        style: {
          minWidth: "470px",
        },
      });
    };

    const onErrorAction = (text) => {
      toast.error(text, {
        id: toastId,
        style: {
          minWidth: "470px",
        },
      });
    };

    dispatch(
      exportBalances({
        params: searchParams,
        onSuccessAction,
        onErrorAction,
      }),
    );
  };

  useEffect(() => {
    if (isPartner === false) {
      if (
        purposeContent !== PURPOSE_REFILL &&
        purposeContent !== PURPOSE_WRITE_OFF
      ) {
        updateSearchParams(purpose, PURPOSE_REFILL);
      }
    }
    if (isPartner === true) {
      updateSearchParams(purpose, PURPOSE_WRITE_OFF);
    }
    // if (isPartner === true) {
    // }
  }, [isPartner]);

  useEffect(() => {
    console.log("before");
    // if (typeof isPartner === "boolean") {
    console.log("innn");

    const promise = dispatch(
      fetchTransactions(
        getDefaultParams({
          searchParams,
          defaultPageSize: 8,
          purpose: purposeContent,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return () => {
      promise.abort();
    };
    // }
  }, [dispatch, searchParams]);

  return (
    <div className={s.BalanceWrapper}>
      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Транзакции</h3>
        <div className={s.btnsWrapper}>
          {getIsStaff() && (
            <>
              <BlueButton
                text="Экспорт балансов"
                action={onExportBalances}
                pending={exportBalancesPending}
                width={150}
              />

              <BlueButton
                text="Экспорт транзакций"
                action={onExportTransactions}
                pending={exportTransactionsPending}
                width={160}
              />
            </>
          )}

          {isPartner === false && (
            <BlueButton
              text="Пополнить"
              action={() => dispatch(setTopUpBalanceActive(true))}
              width={105}
            />
          )}
        </div>
      </div>

      <div className={s.filtersWrapper}>
        <h5 className={s.filtersTitle}>Фильтрация</h5>
        <div className={s.filters}>
          <div className={s.switchPages}>
            {isPartner === false && (
              <button
                className={`${s.rechargeBtn} ${
                  purposeContent === PURPOSE_REFILL ? s.active : ""
                }`}
                onClick={() => {
                  updateSearchParams(purpose, PURPOSE_REFILL);
                  updateSearchParams(page, 1);
                }}
              >
                Пополнения
              </button>
            )}

            <button
              className={`${s.writeOffBtn} ${
                purposeContent === PURPOSE_WRITE_OFF ? s.active : ""
              }`}
              onClick={() => {
                updateSearchParams(purpose, PURPOSE_WRITE_OFF);
                updateSearchParams(page, 1);
              }}
            >
              Списания
            </button>
          </div>
          <div className={s.filter}>
            {getIsStaff() && (
              <div className={s.dropdownWrapper}>
                <MultiSelect
                  isClearable={true}
                  options={usersListOptions}
                  value={selectedUser}
                  setSelectedOptions={(option) => {
                    if (option) {
                      const { value } = option;
                      updateSearchParams(user, value);
                    } else {
                      updateSearchParams(user, "", true);
                    }
                  }}
                  placeholder="Выберите пользователя"
                  isMulti={false}
                />
              </div>
            )}
            <div className={s.datePickersWrapper}>
              <div className={s.datePicker}>
                <MuiDatePicker
                  label="Дата от"
                  value={stringToDayJs(createdAtAfterParam)}
                  onChange={(e) =>
                    updateSearchParams(
                      created_at_after,
                      formatTimestampToServerDate(e?.toString()),
                    )
                  }
                />
              </div>
              <div className={s.datePicker}>
                <MuiDatePicker
                  label="Дата до"
                  value={stringToDayJs(createdAtBeforeParam)}
                  onChange={(e) => {
                    updateSearchParams(
                      created_at_before,
                      formatTimestampToServerDate(e?.toString()),
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {purposeContent === PURPOSE_REFILL && (
        <RefillOption updateSearchParams={updateSearchParams} />
      )}

      {purposeContent === PURPOSE_WRITE_OFF && (
        <WriteOffOption updateSearchParams={updateSearchParams} />
      )}

      {totalCount > 8 && (
        <div className={s.paginationWrapper}>
          <Pagination
            currentPage={parseInt(pageValue) || 1}
            setCurrentPage={(page) => {
              updateSearchParams(params.page, page);
            }}
            total={totalCount}
            limit={8}
          />
        </div>
      )}
    </div>
  );
};

export default Transactions;
