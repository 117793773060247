import React from "react";
import DomainStep from "./Steps/DomainStep";
import s from "./createFlow.module.scss";

const CreateFlow = () => {


    // console.log(window.innerWidth); // Виводить ширину вікна в пікселях
    // console.log(window.innerHeight); // Виводить висоту вікна в пікселях


    return (
        <div className={s.createFlowWrapper}>
            <div className={s.pageHeader}>
                <h3 className={s.pageTitle}>Мои потоки</h3>
            </div>
            <div className={s.settingsHeader}>
                <h5 className={s.subTitle}>Создание потока</h5>
                <div className={s.stepIndicatorWrapper}>
                    <div
                        className={`${s.stepIndicator} ${s.active}`}
                    >
                        <span className={s.stepName}>Домен</span>
                    </div>
                    <div className={s.stepIndicator}>
                        <span className={s.stepName}>Настройка</span>
                        <span className={s.stepNumber}>2</span>
                    </div>
                    <div className={s.stepIndicator}>
                        <span className={s.stepName}>Преленд</span>
                        <span className={s.stepNumber}>3</span>
                    </div>
                    <div className={s.stepIndicator}>
                        <span className={s.stepName}>Редирект</span>
                        <span className={s.stepNumber}>4</span>
                    </div>
                </div>
            </div>
            <div className={s.createFlowContent}>
                <DomainStep/>
            </div>
        </div>
    );
};

export default CreateFlow;