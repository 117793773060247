import React from 'react';
import {Toaster} from "react-hot-toast";
import './toasterAlert.scss'


const ToasterAlert = () => {

    const customStyles = {
        success: {
            style: {
                width: "100%",
                background: '#5D87FF',
                paddingLeft: 10,
                color: '#F2F5FA',
            },
            iconTheme: {
                primary: '#F2F5FA',
                secondary: '#5D87FF',
            },
        },
    }


    return (
        <Toaster
            toastOptions={customStyles}
            visibleToasts={2}
        />
    )
}

export default ToasterAlert;