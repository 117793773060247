import React, { useEffect, useState } from "react";
import s from "../allRedirectors.module.scss";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import PercentageBtn from "../PercentageBtn/PercentageBtn";
import MultiSelect from "../../MultiSelect/MultiSelect";
import MuiTooltip from "../../UiKit/MuiTooltip/MuiTooltip";

const EditApplicationItem = ({
  app,
  index,
  setApplicationPercentages,
  setEditedApplicationPercentages,
  options,
  editRedirectorObj,
}) => {
  const [percentageValue, setPercentageValue] = useState(
    Math.floor(app?.percentage) || 0,
  );
  const [appName, setAppName] = useState(app?.appName || null);
  const [appId, setAppId] = useState(app?.appId || null);
  const [inputItem, setInputItem] = useState(null)

  useEffect(() => {
    setPercentageValue(Math.floor(app?.percentage));
    setAppName(app?.appName);
    setAppId(app?.appId);

    if (app.appId && app.appName) {
      setInputItem({value: app?.appId, label: app?.appName})
    }
  }, [app]);

  const removeBlock = (indexToRemove) => {
    setApplicationPercentages((prev) =>
      prev.filter((_, index) => index !== indexToRemove),
    );
    setEditedApplicationPercentages((prev) =>
      prev.filter((_, index) => index !== indexToRemove),
    );
  };

  useEffect(() => {
    if (editRedirectorObj && editRedirectorObj.application_percentages) {
      setApplicationPercentages((prevState) => {
        if (prevState) {
          const newState = [...prevState];

          if (newState[index]) {
            newState[index].application = appId;
            newState[index].percentage = percentageValue;
            return newState;
          }
        }
      });

      setEditedApplicationPercentages((prevState) => {
        if (prevState) {
          const newState = [...prevState];

          if (newState[index]) {
            newState[index].appId = appId;
            newState[index].appName = appName;
            newState[index].percentage = percentageValue;
            return newState;
          }
        }
      });
    }
  }, [appId, percentageValue, editRedirectorObj]);

  return (
    <div className={s.addition}>
      <div className={s.dropDownContainer}>
        {/*<h6>Приложение</h6>*/}
        <h5 className={s.subTitle}>-</h5>
        <MultiSelect
            value={inputItem}
            isClearable={false}
            options={options}
            setSelectedOptions={(o) => {
              setAppId(o.value)
              setAppName(o.label)
              setInputItem(o)
            }}
            placeholder="Приложение"
            isMulti={false}
        />
      </div>
      <div className={s.rightContainer}>
        <PercentageBtn
          percentageValue={percentageValue}
          setPercentageValue={setPercentageValue}
        />

        {index > 0 ? (
            <div className={s.removeBtnWrapper}>
              <button
                  className={s.removeBtn}
                  onClick={() => removeBlock(index, setApplicationPercentages)}
              >
                {/*<MuiTooltip title='Убрать из списка выбранных приложений'>*/}
                <MuiTooltip title='Убрать приложение'>
                  <GlobalSvgSelector id="remove-icon"/>
                </MuiTooltip>
              </button>
            </div>
        ) : <div className={s.emptyWrapper}></div>}

        {/*{index > 0 ? (*/}
        {/*  <div className={s.removeBtnWrapper}>*/}
        {/*    <button className={s.removeBtn} onClick={() => removeBlock(index)}>*/}
        {/*      <GlobalSvgSelector id="remove-icon" />*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <div>*/}
        {/*    <div style={{ width: 48, height: 78.5 }}></div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default EditApplicationItem;
