import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { globalDataSelector } from "../../../redux/slices/globalDataSlice";
import { redirectorSelector } from "../../../redux/slices/redirectorSlice";
import {
  fetchClicksStats,
  resetTotalCount,
  statisticsSelector,
} from "../../../redux/slices/statisticsSlice";
import { applicationSelector } from "../../../redux/slices/applicationSlice";
import {
  PageSizeDropdown,
  pageSizeOptions,
} from "../../UiKit/PageSizeDropdown/PageSizeDropdown";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import MultiSelect from "../../MultiSelect/MultiSelect";
import CustomSkeleton from "../../CustomSkeleton/CustomSkeleton";
import Pagination from "../../Pagination/Pagination";
import {
  formatDateTime,
  formatTimestampToServerDate,
  stringToDayJs,
} from "../../../utils/helpers/formatTime";
import EmptyList from "../../EmptyList/EmptyList";
import {
  clicksStatsOrderingList,
  params,
  sortDateOptions,
  statisticsStatusOptions,
} from "../../../utils/vars/params";
import { getDefaultParams } from "../../../api/helpers/scripts";
import SearchStats from "../../Search/SearchStats/SearchStats";
import { useDebounce } from "../../../hooks/useDebounce";
import { MuiDatePicker } from "../../Blocks/MuiDatePicker/MuiDatePicker";
import ParamsSort from "../../Blocks/ParamsSort/ParamsSort";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import { INVALID_PAGE_ERROR } from "../../../utils/vars/staticVariables";
import s from "./clicksStats.module.scss";
import {
  useSearchParamsOptions,
  useSelectOptions,
} from "../../../utils/helpers/useSelectHelpers";
import { fetchClickStatsFilters } from "../../../api/helpers/fetchFilters";

export const {
  domain,
  sub7,
  status,
  geo,
  application_name,
  sort,
  page_size,
  page,
  created_at_before,
  created_at_after,
  search,
  user,
  ip_address,
  flow,
  country_code,
  os,
  date_time_before,
  date_time_after,
  purpose,
  email,
} = params;

export const onSortDateHandler = (option, updateSearchParams, setSortDate) => {
  const value = option.value;

  if (value !== "custom_date") {
    updateSearchParams(created_at_before, "", true);
    updateSearchParams(created_at_after, "", true);
  }
  setSortDate(option);

  if (value === "custom_date") {
    updateSearchParams(sort, "", true);
  } else {
    updateSearchParams(sort, value);
  }
};

const ClicksStats = () => {
  const [sortDate, setSortDate] = useState(sortDateOptions[0]);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [selectedGeos, setSelectedGeos] = useState([]);

  const dispatch = useDispatch();

  const { statisticsList, loading, totalCount } =
    useSelector(statisticsSelector);
  const { redirectorsList } = useSelector(redirectorSelector);
  const { domainsList, geoList } = useSelector(globalDataSelector);
  const { appList } = useSelector(applicationSelector);

  const filteredAppList = useMemo(() => {
    return appList.filter((item) => item?.is_alive === true);
  }, [appList]);

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    page_size: pageSizeParam,
    search: searchValue,
    sort: sortValue,
    page: pageValue,
    created_at_after: createdAtAfterParam,
    created_at_before: createdAtBeforeParam,
  } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const [selectedDomains, selectedSub7, selectedStatus, selectedApplications] =
    useSearchParamsOptions(searchParams, [
      domain,
      sub7,
      status,
      application_name,
    ]);

  const onSetPageSize = (selectedSize) => {
    let size = selectedSize;
    if (!pageSizeOptions.includes(size)) {
      size = pageSizeOptions[0];
    }
    updateSearchParams(page_size, size);
    setPageSize(size);
  };

  const domainsListOptions = useSelectOptions(
    domainsList,
    "address",
    "address",
  );
  const sub7ListOptions = useSelectOptions(redirectorsList, "sub7", "sub7");
  const geoListOptions = useSelectOptions(geoList, "name", "code");
  // const appListOptions = useSelectOptions(filteredAppList, "name", "name");

  const appListOptions = useMemo(() => {
    return appList.map((item) => ({
      label: item.name,
      value: item.name,
      is_alive: item.is_alive,
      name: "app_list",
    }));
  }, [appList]);

  const [name, setName] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  useEffect(() => {
    fetchClickStatsFilters(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (pageSizeParam) {
      onSetPageSize(parseInt(pageSizeParam, 10));
    } else {
      setPageSize(pageSizeOptions[0]);
    }

    if (!searchValue) {
      setName("");
    } else {
      setName(searchValue);
    }

    if (
      createdAtAfterParam ||
      createdAtBeforeParam ||
      sortDate.value === "custom_date"
    ) {
      setSortDate(sortDateOptions.find((item) => item.value === "custom_date"));
    } else {
      if (!sortValue) {
        setSortDate(sortDateOptions[0]);
      } else {
        setSortDate(sortDateOptions.find(({ value }) => value === sortValue));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    const newGeoListOptions = searchParams
      .get(geo)
      ?.split(",")
      .filter((value) => value !== "")
      .map((value) => {
        return geoListOptions.find((item) => item.value === value);
      });

    setSelectedGeos(newGeoListOptions);
  }, [searchParams, geoListOptions]);

  useEffect(() => {
    const promise = dispatch(
      fetchClicksStats(
        getDefaultParams({
          searchParams,
          sort,
          defaultPageSize: pageSizeOptions[0],
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return () => {
      promise.abort();
      // dispatch(resetTotalCount());
    };
  }, [dispatch, searchParams]);

  useEffect(() => {
    return () => dispatch(resetTotalCount());
  }, [dispatch]);

  return (
    <div className={s.statisticsWrapper}>
      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Статистика по кликам</h3>
      </div>
      <div className={s.filtersWrapper}>
        <h5 className={s.filtersTitle}>Фильтрация</h5>
        <div className={s.filters}>
          {
            <div className={s.selectDateWrapper}>
              <MultiSelect
                isClearable={false}
                options={sortDateOptions}
                setSelectedOptions={(value) =>
                  onSortDateHandler(value, updateSearchParams, setSortDate)
                }
                value={sortDate}
                placeholder="Дата"
                isMulti={false}
              />
            </div>
          }

          {sortDate?.value === "custom_date" && (
            <div className={s.datePickersWrapper}>
              <div className={s.datePicker}>
                <MuiDatePicker
                  label="Дата от"
                  value={stringToDayJs(createdAtAfterParam)}
                  onChange={(e) =>
                    updateSearchParams(
                      created_at_after,
                      formatTimestampToServerDate(e?.toString()),
                    )
                  }
                />
              </div>
              <div className={s.datePicker}>
                <MuiDatePicker
                  label="Дата до"
                  value={stringToDayJs(createdAtBeforeParam)}
                  onChange={(e) => {
                    updateSearchParams(
                      created_at_before,
                      formatTimestampToServerDate(e?.toString()),
                    );
                  }}
                />
              </div>
            </div>
          )}

          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedSub7}
              setSelectedOptions={(values) =>
                updateSearchParams(sub7, values.map((el) => el.value).join(","))
              }
              options={sub7ListOptions}
              placeholder="Sub7"
              isMulti={true}
            />
          </div>

          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedStatus}
              options={statisticsStatusOptions}
              setSelectedOptions={(values) =>
                updateSearchParams(
                  status,
                  values.map((el) => el.value).join(","),
                )
              }
              placeholder="Статус"
              isMulti={true}
            />
          </div>

          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedDomains}
              options={domainsListOptions}
              setSelectedOptions={(values) =>
                updateSearchParams(
                  domain,
                  values.map((el) => el.value).join(","),
                )
              }
              placeholder="Домен"
              isMulti={true}
            />
          </div>

          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedGeos}
              options={geoListOptions}
              setSelectedOptions={(values) =>
                updateSearchParams(geo, values.map((el) => el.value).join(","))
              }
              placeholder="Гео"
              isMulti={true}
            />
          </div>

          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedApplications}
              options={appListOptions}
              setSelectedOptions={(values) =>
                updateSearchParams(
                  application_name,
                  values.map((el) => el.value).join(","),
                )
              }
              placeholder="Приложение"
              isMulti={true}
            />
          </div>
        </div>
      </div>

      <div className={s.searchBar}>
        <div className={s.pageSizeWrapper}>
          <PageSizeDropdown setSize={onSetPageSize} size={pageSize} />
          <div className={s.recordsCount}>
            Всего: {!loading && <span>{totalCount}</span>}
          </div>
        </div>

        <SearchStats value={name} setValue={setName} />
      </div>

      {totalCount === 0 && !loading ? (
        <EmptyList />
      ) : (
        <>
          <table className={`${s.table} ${s.ClicksStatsTable}`}>
            <thead>
              <tr>
                <th>Клик ID</th>

                {clicksStatsOrderingList.map(({ ordering, name }) => (
                  <th key={name}>
                    {name}
                    <ParamsSort
                      sortBy={ordering}
                      searchParams={searchParams}
                      setSearchParams={setSearchParams}
                    />
                  </th>
                ))}
              </tr>
            </thead>

            {loading ? (
              <tbody>
                <CustomSkeleton styles={s.skeletonTable} />
              </tbody>
            ) : (
              <>
                <tbody className={s.tableContent}>
                  {statisticsList?.map(
                    ({
                      domain,
                      geo,
                      tds_clickid,
                      app,
                      sub7,
                      created_at,
                      is_lead,
                      is_sale,
                      is_installed,
                    }) => {
                      const { date, time } = formatDateTime(created_at);

                      return (
                        <tr key={tds_clickid}>
                          <td>{tds_clickid}</td>
                          <td>{geo}</td>
                          <td>{domain}</td>
                          <td>{app?.name}</td>
                          <td>{sub7}</td>
                          <td>
                            <div className={s.booleanIcon}>
                              {is_installed ? (
                                <GlobalSvgSelector id="check-icon" />
                              ) : (
                                <GlobalSvgSelector id="cross-icon" />
                              )}
                            </div>
                          </td>
                          <td>
                            {is_lead ? (
                              <GlobalSvgSelector id="check-icon" />
                            ) : (
                              <GlobalSvgSelector id="cross-icon" />
                            )}
                          </td>
                          <td>
                            {is_sale ? (
                              <GlobalSvgSelector id="check-icon" />
                            ) : (
                              <GlobalSvgSelector id="cross-icon" />
                            )}
                          </td>
                          <td
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div>{date}</div>
                            <div className={s.minorTime}>{time}</div>
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </>
            )}
          </table>
          {totalCount > pageSize && (
            <div className={s.paginationWrapper}>
              <Pagination
                currentPage={parseInt(pageValue) || 1}
                setCurrentPage={(page) => {
                  updateSearchParams(params.page, page);
                }}
                total={totalCount}
                limit={pageSize}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ClicksStats;
