import React, {useCallback, useRef} from "react";
import debounce from "lodash.debounce";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import s from "./searchStats.module.scss";

const SearchStats = ({ value, setValue }) => {
    const inputRef = useRef(null);

    const onClickClear = () => {
        setValue('')
        inputRef.current.focus();
    };

    // const onChangeInput = (event) => {
    //     setValue(event.target.value);
    //     updateSearchValue(event.target.value);
    // };

    return (
        <div className={s.searchInputWrapper}>
            <input
                ref={inputRef}
                // value={searchValue}
                value={value}
                onChange={e => setValue(e.target.value)}
                className={s.searchInput}
                placeholder="Поиск"
            />
            <div className={s.SearchBtn}>
                <GlobalSvgSelector id="search-white-icon" />
            </div>
            {value && (
                <button className={s.clearSearchValue} onClick={onClickClear}>
                    <GlobalSvgSelector id="clear-field-icon" />
                </button>
            )}
        </div>
    );
};

export default SearchStats;
