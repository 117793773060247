import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";

export const fetchDomainsList = createAsyncThunk(
  "globalData/fetchDomains",
  async () => {
    try {
      const { data } = await $api.get(`/domains/domains/`);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchGeosList = createAsyncThunk(
  "application/fetchGeoList",
  async () => {
    try {
      const response = await $api.get("/flow-settings/geo");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchUsersList = createAsyncThunk(
  "balance/fetchUsers",
  async () => {
    try {
      const { data } = await $api.get(`/users/users/`);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchOSList = createAsyncThunk("statistics/fetchOS", async () => {
  try {
    const { data } = await $api.get("/click-data/os/");

    return data;
  } catch (error) {
    throw error;
  }
});

const globalDataSlice = createSlice({
  name: "globalData",
  initialState: {
    domainsList: [],
    geoList: [],
    usersList: [],
    OSList: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDomainsList.fulfilled, (state, action) => {
        state.domainsList = action.payload;
      })

      .addCase(fetchGeosList.fulfilled, (state, action) => {
        state.geoList = action.payload;
      })

      .addCase(fetchUsersList.pending, (state) => {
        state.usersList = [];
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        state.usersList = action.payload;
      })
      .addCase(fetchUsersList.rejected, (state) => {
        state.usersList = [];
      })

      .addCase(fetchOSList.pending, (state) => {
        state.OSList = [];
      })
      .addCase(fetchOSList.fulfilled, (state, action) => {
        state.OSList = action.payload.details;
      })
      .addCase(fetchOSList.rejected, (state, action) => {
        state.OSList = [];
      });
  },
});

export default globalDataSlice.reducer;

export const globalDataSelector = (state) => state.globalData;
