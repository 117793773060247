import React from "react";
import s from "./blueButton.module.scss";
import Loader from "../../Loader/Loader";
const BlueButton = ({ action, text, pending, width, submit, disabled }) => {
  return (
    <button
      type={submit ? "submit" : "button"}
      className={s.blueButton}
      onClick={action}
      disabled={!disabled ? pending : disabled}
      style={width ? { width: width } : null}
    >
      {pending ? <Loader /> : text}
    </button>
  );
};

export default BlueButton;
