import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";
import axios from "axios";
import { errorMessage } from "../../api/helpers/scripts";
import dayjs from "dayjs";
import { created_at_before } from "../../components/Statistics/ClicksStats/ClicksStats";
import { ABORTED_ERROR } from "../../utils/vars/staticVariables";

export const fetchUserBalance = createAsyncThunk(
  "balance/fetchUserBalance",
  async () => {
    try {
      const { data } = await $api.get(`/finance/wallet/get_user_balance/`);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchCryptoWallet = createAsyncThunk(
  "balance/fetchCryptoWallet",
  async () => {
    try {
      const { data } = await $api.get(`/finance/wallet/get_crypto_wallet/`);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchBalances = createAsyncThunk(
  "balance/fetchBalances",
  async ({ email, page, search, signal, ordering }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel("Операцію скасовано користувачем.");
    });

    try {
      let url = `/finance/get-all-balances?page=${page}&page_size=8`;

      if (ordering) {
        url += `&ordering=${ordering}`;
      }

      if (email) {
        url += `&email=${email}`;
      }

      if (search) {
        url += `&search=${search}`;
      }

      const response = await $api.get(url, { signal });
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.detail;

      if (errorMessage) {
        throw new Error(errorMessage);
      } else {
        throw error;
      }
    }
  },
);

export const exportBalances = createAsyncThunk(
  "finance/exportBalances",
  async ({ params, onErrorAction, onSuccessAction }) => {
    try {
      const { data } = await $api.get(
        "/finance/generate-report-refill/?" + params,
      );
      onSuccessAction(data);
      return data;
    } catch (error) {
      let err = errorMessage(error);

      onErrorAction("Произошла ошибка");

      throw new Error(err);
    }
  },
);

export const exportTransactions = createAsyncThunk(
  "finance/exportTransactions",
  async ({ params, onErrorAction, onSuccessAction }) => {
    try {
      const { data } = await $api.get("/finance/generate-report/?" + params);
      onSuccessAction(data);
      return data;
    } catch (error) {
      let err = errorMessage(error);

      onErrorAction("Произошла ошибка");

      throw new Error(err);
    }
  },
);

export const fetchTransactions = createAsyncThunk(
  "finance/fetchTransactions",
  async (params, { signal, rejectWithValue }) => {
    try {
      const copiedParams = new URLSearchParams(params);
      const createdAtBefore = copiedParams.get(created_at_before);
      if (
        createdAtBefore &&
        dayjs(createdAtBefore, "YYYY-MM-DD", true).isValid()
      ) {
        const newDate = dayjs(createdAtBefore)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        copiedParams.set(created_at_before, newDate);
      }

      const { data } = await $api.get(
        "/finance/transactions/?" + copiedParams,
        {
          signal,
        },
      );
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

const financeSlice = createSlice({
  name: "finance",
  initialState: {
    balanceLoading: false,
    balance: 0,
    wallet: null,
    transactionsLoading: false,
    transactionsList: [],
    topUpBalanceIsActive: false,
    currentPage: 1,
    totalCount: null,

    balanceList: [],
    balancesLoading: false,

    exportTransactionsPending: false,
    exportBalancesPending: false,

    transactionsPageContent: null,

    dateRange: [null, null],
  },
  reducers: {
    setTopUpBalanceActive: (state, action) => {
      state.topUpBalanceIsActive = action.payload;
    },
    setTransactionsCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalCount: (state) => {
      state.totalCount = null;
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    setBalance: (state, action) => {
      state.balance = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserBalance.pending, (state) => {
        state.balanceLoading = true;
      })
      .addCase(fetchUserBalance.fulfilled, (state, action) => {
        state.balance = action.payload.user_balance;
        state.balanceLoading = false;
      })
      .addCase(fetchUserBalance.rejected, (state) => {
        state.balanceLoading = false;
      })

      //////////////////////////////////////////

      .addCase(fetchCryptoWallet.pending, (state) => {
        // state.balanceLoading = true;
      })
      .addCase(fetchCryptoWallet.fulfilled, (state, action) => {
        state.wallet = action.payload.crypto_currency_wallet;
        // state.balanceLoading = false;
      })
      .addCase(fetchCryptoWallet.rejected, (state) => {
        // state.balanceLoading = false;
      })

      .addCase(exportTransactions.pending, (state) => {
        state.exportTransactionsPending = true;
      })
      .addCase(exportTransactions.fulfilled, (state) => {
        state.exportTransactionsPending = false;
      })
      .addCase(exportTransactions.rejected, (state) => {
        state.exportTransactionsPending = false;
      })

      .addCase(exportBalances.pending, (state) => {
        state.exportBalancesPending = true;
      })
      .addCase(exportBalances.fulfilled, (state) => {
        state.exportBalancesPending = false;
      })
      .addCase(exportBalances.rejected, (state) => {
        state.exportBalancesPending = false;
      })

      //////////////////////////////////////////

      .addCase(fetchTransactions.pending, (state) => {
        state.transactionsList = [];
        state.transactionsLoading = true;
      })
      .addCase(fetchTransactions.fulfilled, (state, { payload }) => {
        const { results, count } = payload;

        state.transactionsList = results;
        state.totalCount = count;
        state.transactionsLoading = false;
      })
      .addCase(fetchTransactions.rejected, (state, { error }) => {
        const { message } = error;

        state.transactionsLoading = message === ABORTED_ERROR;
        state.transactionsList = [];

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }
      })

      //////////////////////////////////////////

      .addCase(fetchBalances.pending, (state) => {
        state.balancesLoading = true;
        state.balancesList = [];
        // state.totalCount = null
      })
      .addCase(fetchBalances.fulfilled, (state, action) => {
        state.balanceList = action.payload.results;
        state.totalCount = action.payload.count;
        state.balancesLoading = false;
      })
      .addCase(fetchBalances.rejected, (state, action) => {
        if (action.error.message === "Invalid page.") {
          state.currentPage = 1;
        }
        state.balancesList = [];
        state.totalCount = null;
        state.balancesLoading = false;
      });
  },
});

export const {
  setTopUpBalanceActive,
  setTransactionsCurrentPage,
  setCurrentPage,
  setBalance,
} = financeSlice.actions;

export default financeSlice.reducer;

export const financeSelector = (state) => state.finance;
