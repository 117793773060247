import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import CustomSkeleton from "../../../CustomSkeleton/CustomSkeleton";
import MuiTooltip from "../../../UiKit/MuiTooltip/MuiTooltip";
import EmptyList from "../../../EmptyList/EmptyList";
import { convertDate } from "../../../../utils/helpers/formatTime";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
import s from "../transactions.module.scss";

const WriteOffOption = () => {
  const { transactionsLoading, transactionsList, totalCount } =
    useSelector(financeSelector);

  return (
    <div className={s.tableWrapper}>
      {transactionsLoading ? (
        <CustomSkeleton styles={s.skeletonTable} />
      ) : (
        <div className={s.table}>
          {totalCount === 0 && !transactionsLoading ? (
            <EmptyList />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Дата</th>
                  <th>Инсталы</th>
                  <th>Сумма</th>
                  <th>Услуга</th>
                  <th>Отчет</th>
                </tr>
              </thead>
              <tbody>
                {transactionsList.map(
                  ({ id, created_at, amount, description, installs }) => (
                    <tr key={id}>
                      <td>
                        <span>{convertDate(created_at, "DMY")}</span>{" "}
                        <span>{convertDate(created_at, "HM")}</span>
                      </td>
                      <td>{installs ? installs : ""}</td>
                      <td>$ {amount}</td>
                      <td>{description}</td>
                      <td>
                        <div className={s.actionsWrapper}>
                          {description.includes("Списание средств за домен") ? (
                            ""
                          ) : (
                            <div className={s.actions}>
                              <Link
                                to={`/stats/clicks?created_at_after=${convertDate(created_at, "YMD")}&created_at_before=${convertDate(created_at, "YMD")}`}
                                className={s.statsBtn}
                              >
                                <MuiTooltip title="Перейти на статистику по кликам">
                                  <GlobalSvgSelector id="statistics-blue-icon" />
                                </MuiTooltip>
                              </Link>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default WriteOffOption;
