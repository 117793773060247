import React, { useEffect, useRef, useState } from "react";
// import s from "../../Dropdown/dropdown.module.scss";
import s from "../../../Dropdown/dropdown.module.scss";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
// import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
// import GlobalSvgSelector from "../GlobalSvgSelector/GlobalSvgSelector";

const AppSettingsDropdown = ({ desc, selected, setLabel, setName, options }) => {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);

  const onClickHandler = (label, name) => {
    setLabel(label);
    setName(name);
    setIsActive(false);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={s.dropdown} ref={dropdownRef}>
      <div
          className={isActive ? `${s.dropdownBtn} ${s.active}` : s.dropdownBtn}
          onClick={(e) => setIsActive(!isActive)}
          tabIndex="0"
      >
        <div className={s.selectedItemWrapper}>

          {
            desc && selected === null && desc
          }
          <span className={s.selectedItem}>{selected}</span>
        </div>
        <GlobalSvgSelector id="dropdown-arrow-icon" />
      </div>
      {isActive && (
        <ul className={s.dropdownList}>
          {options.map((option, index) => (
            <li className={s.dropdownItem} onClick={() => onClickHandler(option.label, option.name)} key={index}>
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AppSettingsDropdown;
