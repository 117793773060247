import React from 'react';
import {Tooltip as ReactTooltip} from "react-tooltip";
import s from './buttonTip.module.scss'

const ButtonTip = ({id, place = 'bottom', content}) => {

    return (
        <ReactTooltip
            anchorId={id}
            place={place}
            className={s.tipAnimation}
            content={content}
            delayShow={200}
        />
    )
}

export default ButtonTip;