import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // createPrelanding,
  fetchWheelStylesList,
  // setCurrentWheel,
  // setSectors,
  // updateSectorValue
} from "../../../../../../redux/slices/prelandingSlice";
import { toast } from "react-hot-toast";
import defaultLogo from "../../../../../../assets/img/defaultLogo.png";
import { useNavigate, useParams } from "react-router-dom";
import { handleContinue } from "../../../../../../utils/helpers/scrollTop";
// import s from "../../../../../Prelandings/prelandings.module.scss";

import s from "./createPrelanding.module.scss";

import AppSettingsDropdown from "../../../AppSettingsDropdown/AppSettingsDropdown";
import {
  setPopupButtonText,
  setPopupText,
  setPopupTitle,
  setPrelandingName,
  setPrelandTitle,
  setCurrentWheel,
  updateSectorValue,
  setPrelandTypeLabel,
  setSectors,
  setPrelandTypeName,
  setRespinButtonText,
  setSelectedImage,
  setSpinButtonText,
  setSpinCount,
  setStyleId,
  setPrelandingStatus,
  addFlowPrelanding,
  setOfferLink,
} from "../../../../../../redux/slices/flowSlice";
import { prelandTypeList } from "../../../../../../utils/vars/staticVariables";
import GlobalSvgSelector from "../../../../../GlobalSvgSelector/GlobalSvgSelector";
import SpinWheel from "../../../../../Prelandings/SpinWheel/SpinWheel";
import BaseTextInput from "../../../../../BaseTextInput/BaseTextInput";
import LimitedInput from "../../../../../LimitedInput/LimitedInput";
import RedButton from "../../../../../UiKit/Buttons/RedButton/RedButton";
import BlueButton from "../../../../../UiKit/Buttons/BlueButton/BlueButton";

const CreatePrelanding = ({ setStep }) => {
  const [wheelNumbers, setWheelNumbers] = useState(() =>
    new Array(8)
      .fill()
      .map((_, index) => ({
        name: `Сектор ${index}`,
        value: "",
        is_win: index <= 0,
      })),
  );

  const [winSector, setWinSector] = useState(0);

  const nameInputRef = useRef(null);
  const prelandTitleRef = useRef(null);
  const spinButtonTextRef = useRef(null);
  const respinButtonTextRef = useRef(null);
  const popupTitleRef = useRef(null);
  const popupTextRef = useRef(null);
  const popupButtonTextRef = useRef(null);

  const fileInputRef = useRef(null);

  const dispatch = useDispatch();

  const {
    prelandTypeName,
    selectedImage,
    spinCount,
    prelandingName,
    prelandTitle,
    spinButtonText,
    respinButtonText,
    popupTitle,
    popupText,
    popupButtonText,
    styleId,
    currentWheel,
    sectors,
    prelandingStatus,
  } = useSelector((state) => state.flow);

  const { wheelStylesList, wheelStylesLoading } = useSelector(
    (state) => state.prelanding,
  );

  useEffect(() => {
    dispatch(
      setCurrentWheel(wheelStylesList.find((item) => item.id == styleId)),
    );
  }, [wheelStylesList]);

  // const [currentWheel, setCurrentWheel] = useState(wheelStylesList.find(item => item.id == styleId) || {
  //     id: 1,
  //     background: '/static/media/blueBg.91bfaf22c80389297f4d.png',
  //     wheel: "/static/media/blueWheelImg.3361f562dbe24aa60733bdea4e55bb31.svg",
  //     button: "/static/media/blueWheelBtn.058b645cc9b0dd225d251d19e8725a67.svg",
  //     pointer: "/static/media/blueWheelPointer.c6bf6fe15884f8ddb281bb19b71d1484.svg",
  //     popup: "/static/media/bluePopup.0583c1635b8553b21ae568c308ed55c2.svg"
  // })

  useEffect(() => {
    // dispatch(setStyleId(wheelStylesList[0]?.id))

    dispatch(
      setCurrentWheel(wheelStylesList.find((item) => item.id == styleId)),
    );
  }, [wheelStylesList, styleId]);

  useEffect(() => {
    dispatch(
      setCurrentWheel(wheelStylesList.find((item) => item.id == styleId)),
    );

    // wheelStylesList.find(item => item.id == styleId)))
  }, [wheelStylesList, styleId]);

  // const updateSectorValue = (index, newValue) => {
  //     setWheelNumbers(currentSectors =>
  //         currentSectors.map((sector, sectorIndex) =>
  //             sectorIndex === index ? {...sector, value: newValue} : sector
  //         )
  //     );
  // };

  useEffect(() => {
    dispatch(fetchWheelStylesList());
  }, []);

  useEffect(() => {
    setStyleId(wheelStylesList[0]?.id);
  }, [wheelStylesList]);

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  // const handleFileChange = (event) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //             setSelectedImage(reader.result);
  //         };
  //         reader.readAsDataURL(file);
  //     }
  // };

  // const handleFileChange = (event) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //             setSelectedImage(reader.result); // Зберегти base64 зображення у стані
  //         };
  //         reader.readAsDataURL(file); // Читання файлу як base64
  //     }
  // };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Перевірка на тип файлу
      if (!file.type.match("image.*")) {
        toast.error("Пожалуйста, выберите изображение");
        return;
      }

      // Створення тимчасового URL для зображення
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        // Перевірка розмірів зображення
        if (img.width < 50 || img.height < 50) {
          toast.error("Размер изображения меньше 50х50 пикселей");
        } else {
          // Якщо зображення відповідає усім вимогам, читаємо його як base64
          const reader = new FileReader();
          reader.onloadend = () => {
            dispatch(setSelectedImage(reader.result)); // Зберегти base64 зображення у стані
          };
          reader.readAsDataURL(file); // Читання файлу як base64
        }
        // Звільнення пам'яті, виділеної під URL
        URL.revokeObjectURL(img.src);
      };
    }
  };

  useEffect(() => {
    !selectedImage &&
      fetch(defaultLogo)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            dispatch(setSelectedImage(reader.result));
          };
          reader.readAsDataURL(blob);
        });
  }, []);

  const handleImageChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (upload) => {
        dispatch(setSelectedImage(upload.target.result));
        // onImageUpload(upload.target.result); // Callback функція, якщо треба відправити зображення надалі
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    handleImageChange(defaultLogo);
  }, []);

  const { id } = useParams();

  const sendDataObj = {
    flow_id: parseInt(id),
    prelanding_settings: {
      status: prelandingStatus,
      name: prelandingName,
      prelanding_type: 1,
      header_text: prelandTitle,
      style: styleId,
      rotation_button_text: spinButtonText,
      respin_button_text: respinButtonText,
      num_of_revolutions: spinCount,
      sectors: sectors,
      // popup_win_text: popupTitle,
      popup_win_text: popupText,
      winning_button_text: popupButtonText,
      // logo: selectedImage,
      logo: null,
      bonus_button_text: popupTitle,
    },
  };

  const validateAplhaNumeric = (value) => {
    const regex = /^[a-zA-Zа-яА-ЯіІїЇєЄґҐ0-9]+( [a-zA-Zа-яА-ЯіІїЇєЄґҐ0-9]+)*$/;
    return regex.test(value.trim());
  };

  const navigate = useNavigate();

  const scrollToElement = (ref) => {
    if (ref && ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 150; // Віднімаємо 150 пікселів

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const onCreatePrelanding = () => {
    // if (prelandingName.length < )

    if (prelandingName.trim().length < 1) {
      scrollToElement(nameInputRef);
      toast.error("Заполните имя прелендинга");
      return;
    }

    if (prelandTitle.trim().length < 1) {
      scrollToElement(prelandTitleRef);
      toast.error("Заполните текст вращения");
      return;
    }

    if (spinButtonText.trim().length < 1) {
      scrollToElement(spinButtonTextRef);
      toast.error("Заполните текст кнопки вращения");
      return;
    }

    if (respinButtonText.trim().length < 1) {
      scrollToElement(respinButtonTextRef);
      toast.error("Заполните текст кнопки повторного вращения", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    const hasEmptyString = sectors.some((sector) => sector.value.trim() === "");
    if (hasEmptyString) {
      toast.error("Заполните пустые значения секторов");
      return;
    }

    // sectors.forEach(sector => {
    //     if (!validateAplhaNumeric(sector.value) && sector.value.trim() !== "") {
    //         toast.error("Некоректные значения секторов")
    //     }
    // });

    if (popupTitle.trim().length < 1) {
      // scrollToElement(respinButtonTextRef);
      toast.error("Заполните заголовок попапа выигрыша", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    if (popupText.trim().length < 1) {
      // scrollToElement(respinButtonTextRef);
      toast.error("Заполните текст попапа выигрыша", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    if (popupButtonText.trim().length < 1) {
      // scrollToElement(respinButtonTextRef);
      toast.error("Заполните текст кнопки попапа выигрыша", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    const onSuccessAction = () => {
      toast.success("Прелендинг был успешно создан");
      setStep("redirect");
      // navigate("/prelandings");
      handleContinue();
    };

    const onErrorAction = (text) => {
      toast.error(text);
    };

    dispatch(
      addFlowPrelanding({ data: sendDataObj, onSuccessAction, onErrorAction }),
    );
    // dispatch(createPrelanding({data: sendDataObj, onSuccessAction, onErrorAction}))
  };

  // const handleWinSectorChange = (selectedIndex) => {
  //     // Оновлюємо кожен об'єкт в масиві
  //     const updatedWheelNumbers = sectors.map((sector, index) => ({
  //         ...sector,
  //         is_win: index === selectedIndex // Тільки обраний сектор отримає is_win: true
  //     }));
  //
  //     // Оновлюємо стан з новим масивом
  //     dispatch(setSectors(updatedWheelNumbers));
  // };

  const handleWinSectorChange = (selectedIndex) => {
    const updatedSectors = sectors?.map((sector, index) => ({
      ...sector,
      is_win: index === selectedIndex,
    }));

    dispatch(setSectors(updatedSectors));
  };

  const handleSectorsChange = (index, newValue) => {
    dispatch(updateSectorValue({ index, newValue }));
  };

  return (
    <div className={s.prelandingStepWrapper}>
      <div className={s.formWrapper}>
        {/*<h1 className={s.subTitle}>Создание нового прелендинга</h1>*/}
        <div className={s.content}>
          <div className={s.createPrelandingForm}>
            <div>
              {/*<div className={s.checkBoxWrapper}>*/}
              {/*    <Checkbox  defaultChecked />*/}
              {/*</div>*/}
              <div className={s.statusWrapper}>
                {/*<h6>Преленд</h6>*/}
                <h5 className={s.subtitleBorderBottom}>Преленд</h5>

                <div className={s.statusCheckbox}>
                  <input
                    type="checkbox"
                    checked={prelandingStatus}
                    onChange={() =>
                      dispatch(setPrelandingStatus(!prelandingStatus))
                    }
                  />
                  <span>Отображать</span>
                </div>
              </div>

              <div className={s.prelandNameContainer} ref={nameInputRef}>
                {/*<h6>Название</h6>*/}
                <h5 className={s.subTitle}>Название</h5>

                <BaseTextInput
                  action={(e) => dispatch(setPrelandingName(e.target.value))}
                  value={prelandingName}
                  placeholder="Введите текст"
                />
              </div>
              {/*<div className={s.typeWrapper}>*/}
              {/*<h6>Тип преленда</h6>*/}
              <h5 className={s.subTitle}>Тип преленда</h5>

              <div className={s.prelandTypeContainer}>
                <AppSettingsDropdown
                  desc="Выберите параметр"
                  selected={prelandTypeName}
                  setLabel={(item) => dispatch(setPrelandTypeLabel(item))}
                  setName={(item) => dispatch(setPrelandTypeName(item))}
                  options={prelandTypeList}
                />
              </div>

              {/*<h6 className={s.uniqueSettingsTitle} ref={prelandTitleRef}>Уникальные настройки</h6>*/}

              <h5 className={s.subtitleBorderBottom} ref={prelandTitleRef}>
                Уникальные настройки
              </h5>

              <div className={s.uniqueSettings}>
                {/*<div className={s.nameInputWrapper}>*/}
                {/*    <h6>Текст вращения</h6>*/}

                <LimitedInput
                  maxLength={20}
                  value={prelandTitle}
                  action={(e) => dispatch(setPrelandTitle(e.target.value))}
                  subtitle="Заголовок"
                />

                {/*<div className={s.inputWrapper}>*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        className={s.textInput}*/}
                {/*        placeholder="Введите текст"*/}
                {/*        value={prelandTitle}*/}
                {/*        onChange={e => dispatch(setPrelandTitle(e.target.value))}*/}
                {/*        maxLength={20}*/}
                {/*    />*/}
                {/*    {*/}
                {/*        prelandTitle.length > 0 &&*/}
                {/*        <div className={s.lengthIndicator}>{prelandTitle.length}/20</div>*/}
                {/*    }*/}
                {/*</div>*/}
                {/*</div>*/}

                {/*<div className={s.nameInputWrapper} ref={spinButtonTextRef}>*/}
                {/*    <h6>Текст кнопки вращения</h6>*/}
                {/*    <div className={s.inputWrapper}>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            className={s.textInput}*/}
                {/*            placeholder="Введите текст"*/}
                {/*            value={spinButtonText}*/}
                {/*            onChange={e => dispatch(setSpinButtonText(e.target.value))}*/}
                {/*            maxLength={10}*/}
                {/*        />*/}
                {/*        {*/}
                {/*            spinButtonText.length > 0 &&*/}
                {/*            <div className={s.lengthIndicator}>{spinButtonText.length}/10</div>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div ref={spinButtonTextRef}>
                  <LimitedInput
                    maxLength={10}
                    value={spinButtonText}
                    action={(e) => dispatch(setSpinButtonText(e.target.value))}
                    subtitle="Текст кнопки вращения"
                  />
                </div>

                <div ref={respinButtonTextRef}>
                  <LimitedInput
                    maxLength={10}
                    value={respinButtonText}
                    action={(e) =>
                      dispatch(setRespinButtonText(e.target.value))
                    }
                    subtitle="Текст кнопки повторного вращения"
                  />
                </div>

                {/*<div className={s.nameInputWrapper} ref={respinButtonTextRef}>*/}
                {/*    <h6>Текст кнопки повторного вращения</h6>*/}
                {/*    <div className={s.inputWrapper}>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            className={s.textInput}*/}
                {/*            placeholder="Введите текст"*/}
                {/*            value={respinButtonText}*/}
                {/*            onChange={e => dispatch(setRespinButtonText(e.target.value))}*/}
                {/*            maxLength={10}*/}
                {/*        />*/}
                {/*        {*/}
                {/*            respinButtonText.length > 0 &&*/}
                {/*            <div className={s.lengthIndicator}>{respinButtonText.length}/10</div>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<h6 className={s.wheelStyle}>Стиль колеса</h6>*/}

                <h5 className={s.subTitle}>Стиль колеса</h5>
                <ul className={s.wheelCheckboxList}>
                  {wheelStylesList?.map(({ id, wheel }, index) => (
                    <li
                      className={s.wheelStyleItem}
                      key={id}
                      onClick={() => dispatch(setStyleId(id))}
                      // onClick={() => setCurrentWheel(wheelList[index])}
                    >
                      <label className={s.checkboxWrapper}>
                        <img
                          src={wheel}
                          alt="wheel image"
                          style={
                            id === styleId
                              ? {
                                  border: "4px solid #5D87FF",
                                  borderRadius: "50%",
                                }
                              : null
                          }
                        />
                      </label>
                    </li>
                  ))}
                </ul>
                {/*<h6 className={s.spinCountTitle}>Количество оборотов</h6>*/}
                {/*<div className={s.spinCountWrapper}>*/}
                {/*    <label className={s.radioWrapper}>*/}
                {/*        <input*/}
                {/*            className={s.radio}*/}
                {/*            type="radio"*/}
                {/*            value={1}*/}
                {/*            checked={spinCount === 1}*/}
                {/*            onChange={e => dispatch(setSpinCount(1))}*/}
                {/*        />*/}
                {/*        1 оборот*/}
                {/*    </label>*/}
                {/*    <label className={s.radioWrapper}>*/}
                {/*        <input*/}
                {/*            className={s.radio}*/}
                {/*            type="radio"*/}
                {/*            value={3}*/}
                {/*            checked={spinCount === 3}*/}
                {/*            onChange={e => dispatch(setSpinCount(3))}*/}
                {/*        />*/}
                {/*        3 оборота*/}
                {/*    </label>*/}
                {/*</div>*/}
                <div className={s.sectorsWrapper}>
                  <h5 className={s.subTitle}>
                    Значения секторов и сектор финального выигрыша
                  </h5>

                  {/*<h4>Значения секторов и сектор финального выигрыша</h4>*/}
                  <div className={s.sectorsContent}>
                    {sectors?.map((sector, index) => (
                      <div className={s.sectorItem} key={index}>
                        <div className={s.sectorInputWrapper}>
                          <div className={s.sectorInputContainer}>
                            <input
                              type="text"
                              className={s.sectorInput}
                              value={sector.value}
                              onChange={(e) =>
                                handleSectorsChange(index, e.target.value)
                              }
                              placeholder="Введите текст"
                              maxLength={10}
                            />
                            {sector.value.length > 0 && (
                              <div className={s.lengthIndicator}>
                                {sector.value.length}/10
                              </div>
                            )}
                          </div>

                          <label className={s.radioLabel}>
                            <input
                              className={s.radio}
                              type="radio"
                              value={index}
                              // checked={index === winSector}
                              checked={sector.is_win}
                              onChange={() => {
                                handleWinSectorChange(index);
                                setWinSector(index);
                              }}
                              // onChange={e => setWinSector(index)}
                            />
                            <span>WIN</span>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/*<div className={s.nameInputWrapper} ref={popupTitleRef}>*/}
                {/*    <h6>Заголовок в попапе выигрыша</h6>*/}
                {/*    <div className={s.inputWrapper}>*/}

                {/*        <input*/}
                {/*            type="text"*/}
                {/*            className={s.textInput}*/}
                {/*            placeholder="Введите текст"*/}
                {/*            value={popupTitle}*/}
                {/*            onChange={e => dispatch(setPopupTitle(e.target.value))}*/}
                {/*            maxLength={16}*/}
                {/*        />*/}
                {/*        {*/}
                {/*            popupTitle.length > 0 &&*/}
                {/*            <div className={s.lengthIndicator}>{popupTitle.length}/16</div>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div ref={popupTitleRef}>
                  <LimitedInput
                    maxLength={16}
                    value={popupTitle}
                    action={(e) => dispatch(setPopupTitle(e.target.value))}
                    subtitle="Заголовок в попапе выигрыша"
                  />
                </div>

                {/*<div className={s.inputWrapper}>*/}
                {/*    <h5 className={s.subTitle}>{subtitle}</h5>*/}
                {/*    <div className={s.inputContainer}>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            className={s.textInput}*/}
                {/*            placeholder={placeholder}*/}
                {/*            value={value}*/}
                {/*            onChange={action}*/}
                {/*            maxLength={maxLength}*/}
                {/*        />*/}
                {/*        {*/}
                {/*            value.length > 0 &&*/}
                {/*            <div className={s.lengthIndicator}>{value.length}/{maxLength}</div>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div ref={popupTitleRef}>*/}
                <LimitedInput
                  maxLength={30}
                  value={popupText}
                  action={(e) => dispatch(setPopupText(e.target.value))}
                  subtitle="Текст в попапе выигрыша"
                  tip="some tip"
                />

                <h5 className={s.inputTip}>
                  Используйте {`{bonus}`} для того, чтобы подставить значение
                  выигрыша в тексте поздравления
                </h5>

                {/*</div>*/}

                {/*<div className={s.nameInputWrapper}>*/}
                {/*    <h6>Текст в попапе выигрыша</h6>*/}
                {/*    <h6 className={s.greySubtitle}>*/}
                {/*        «Используйте {`{bonus}`} для того чтобы подставить*/}
                {/*        значение выигрыша в тексте поздравления»*/}
                {/*    </h6>*/}
                {/*    <div className={s.inputWrapper}>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            className={s.textInput}*/}
                {/*            placeholder="Введите текст"*/}
                {/*            value={popupText}*/}
                {/*            onChange={e => dispatch(setPopupText(e.target.value))}*/}
                {/*            maxLength={30}*/}
                {/*        />*/}
                {/*        {*/}
                {/*            popupText.length > 0 &&*/}
                {/*            <div className={s.lengthIndicator}>{popupText.length}/30</div>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}

                <LimitedInput
                  maxLength={10}
                  value={popupButtonText}
                  action={(e) => dispatch(setPopupButtonText(e.target.value))}
                  subtitle="Текст в попапе выигрыша"
                />

                {/*<div className={s.nameInputWrapper}>*/}
                {/*    <h6>Текст кнопки бонуса</h6>*/}
                {/*    <div className={s.inputWrapper}>*/}

                {/*        <input*/}
                {/*            // bonusButtonText*/}
                {/*            type="text"*/}
                {/*            className={s.textInput}*/}
                {/*            placeholder="Введите текст"*/}
                {/*            value={popupButtonText}*/}
                {/*            onChange={e => dispatch(setPopupButtonText(e.target.value))}*/}
                {/*            maxLength={10}*/}
                {/*        />*/}
                {/*        {*/}
                {/*            popupButtonText.length > 0 &&*/}
                {/*            <div className={s.lengthIndicator}>{popupButtonText.length}/10</div>*/}
                {/*        }*/}
                {/*    </div>*/}

                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className={s.previewWrapper}>
            <div className={s.previewContent}>
              <h5 className={s.subTitle}>Превью</h5>
              <SpinWheel
                wheelNumbers={sectors?.map((sector) => sector.value)}
                prelandTitle={prelandTitle}
                spinCount={spinCount}
                spinButtonText={spinButtonText}
                respinButtonText={respinButtonText}
                winSector={winSector}
                logo={selectedImage}
                popupTitle={popupTitle}
                currentWheel={currentWheel}
                popupText={popupText}
                popupButtonText={popupButtonText}
              />
            </div>
          </div>
        </div>

        <div className={s.buttonsWrapper}>
          <RedButton
            width={120}
            text="Отменить"
            action={() => {
              navigate("/my-flows");
              handleContinue();
            }}
          />
          <BlueButton
            width={120}
            text="Создать"
            // pending={dataLoading}
            action={onCreatePrelanding}
          />
        </div>

        {/*<div className={s.buttonsWrapper}>*/}
        {/*    <div className={s.buttons}>*/}
        {/*        <button className={s.cancelBtn} onClick={() => {*/}
        {/*            navigate('/prelandings')*/}
        {/*            handleContinue()*/}
        {/*        }}>Отмена*/}
        {/*        </button>*/}
        {/*        <button className={s.createBtn} onClick={() => {*/}
        {/*            onCreatePrelanding()*/}
        {/*        }}>Создать*/}
        {/*        </button>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default CreatePrelanding;
