import React, { useState } from "react";
import GlobalSvgSelector from "../../../../../GlobalSvgSelector/GlobalSvgSelector";
import s from "./ownPreland.module.scss";
import { toast } from "react-hot-toast";
import JSZip from "jszip";
import { handleContinue } from "../../../../../../utils/helpers/scrollTop";
import { useNavigate, useParams } from "react-router-dom";
import RedButton from "../../../../../UiKit/Buttons/RedButton/RedButton";
import BlueButton from "../../../../../UiKit/Buttons/BlueButton/BlueButton";
import { customPrelandingUpload } from "../../../../../../redux/slices/prelandingSlice";
import { useDispatch, useSelector } from "react-redux";
import BaseTextInput from "../../../../../BaseTextInput/BaseTextInput";
import { setCustomPrelandName } from "../../../../../../redux/slices/flowSlice";

export const validateNameString = (inputString) => {
  const regex = /^[ A-Za-z\u0400-\u04FF0-9]+$/;
  return regex.test(inputString);
};

const OwnPreland = ({ setStep }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Загрузить");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { customPrelandName } = useSelector((state) => state.flow);

  const { customPrelandPending } = useSelector((state) => state.prelanding);

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const onFileUpload = async () => {
    if (customPrelandName.trim().length < 1) {
      toast.error("Заполните имя прелендинга");
      return;
    }

    // if (!validateNameString(customPrelandName)) {
    //     toast.error('Некорректное имя прелендинга')
    //     return
    // }

    if (!file) {
      toast.error("Выберите .zip файл");
      return;
    }

    if (file) {
      const formData = new FormData();

      formData.append("prelanding_file", file);
      formData.append("status", "true");
      formData.append("prelanding_type", "4");
      formData.append("flow_id", id.toString());
      formData.append("name", customPrelandName);

      const onSuccessAction = () => {
        toast.success("Преленд был успешно загружен");
        setStep("redirect");
      };

      const onErrorAction = (text) => {
        toast.error(text, {
          style: {
            // minWidth: '400px',
          },
        });
      };

      dispatch(
        customPrelandingUpload({ formData, onSuccessAction, onErrorAction }),
      );
    }
  };

  // setCustomPrelandName
  return (
    <div className={s.ownPrelandWrapper}>
      <div className={s.container}>
        <h5 className={s.subtitleBorderBottom}>Загрузите свой прелендинг</h5>

        <div className={s.prelandNameInputWrapper}>
          <BaseTextInput
            action={(e) => dispatch(setCustomPrelandName(e.target.value))}
            value={customPrelandName}
            placeholder="Введите имя прелендинга"
          />
        </div>

        <label htmlFor="upload-button" className={s.downloadPreland}>
          {/*<GlobalSvgSelector id="zip-icon"/>*/}
          <GlobalSvgSelector id="download-icon" />
          <span>{fileName}</span>
        </label>
        <input
          id="upload-button"
          type="file"
          style={{ display: "none" }}
          onChange={onFileChange}
          accept=".zip"
        />
      </div>

      <div className={s.buttonsWrapper}>
        <RedButton
          text="Отменить"
          action={() => {
            navigate("/prelandings");
            handleContinue();
          }}
          width={120}
        />
        <BlueButton
          width={120}
          text="Загрузить"
          pending={customPrelandPending}
          action={onFileUpload}
        />
      </div>
    </div>
  );
};

export default OwnPreland;
