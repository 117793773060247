import React, { useState } from "react";
import Select from "react-select";
import { getIsStaff } from "../../storage/storage";

export const selectStyles = {
  control: (baseStyles, { isSelected, menuIsOpen }) => ({
    ...baseStyles,
    backgroundColor: "#202936",
    minHeight: 44,
    borderRadius: 10,
    cursor: "pointer",
    color: "#F2F5FA",
    borderColor: isSelected ? "#F2F5FA" : "#5a6a85",
  }),

  // multiValueLabel: (provided, state) => ({
  //   ...provided,
  //   color: "#F2F5FA",
  // }),

  // if (data?.name === "app_list" && !data.is_alive) {
  // customStyle.color = "#FF4136";
  // }

  multiValueLabel: (provided, { data }) => ({
    ...provided,

    // color: data.is_alive ? "#F2F5FA" : "#FF4136",
    color: "#F2F5FA",
  }),

  // multiValueLabel: (provided, { data }) => {
  //   const customStyle = {
  //     ...provided,
  //     color: "#F2F5FA",
  //   };
  //
  //   // if (data.name === "app_list" && !data.is_alive) {
  //   //   customStyle.color = "#FF4136";
  //   // }
  //   if (data.name === "app_list") {
  //     customStyle.color = "#FF4136";
  //   }
  //   console.log("what is DATA", data);
  //
  //   return customStyle;
  // },

  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "red",
  }),

  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#2c3c5e",
    color: "#5D87FF",
    borderRadius: "5px",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    // backgroundColor: '#2c3c5e',
    color: "#F2F5FA",
    // borderRadius: '5px',
  }),

  singleValueRemove: (provided, state) => ({
    ...provided,
    color: "red",
  }),

  menu: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "#202936",
    color: "red",
    // maxHeight: '250px', // Змініть на бажану висоту
    // overflowY: 'auto',
  }),

  menuList: (baseStyles) => ({
    ...baseStyles,
    // height: 380,
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      background: "#0000001A",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 5,
      background: "#5D87FF",
    },
  }),

  placeholder: (styles) => ({
    ...styles,
    color: "#d0d3d9", // Колір placeholder для одиночного селекту
  }),

  input: (styles) => ({
    ...styles,
    color: "white",
    "&::placeholder": {
      color: "green", // Колір placeholder для одиночного селекту
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const customStyle = {
      ...styles,
      color: "#F2F5FA",
      backgroundColor: isFocused ? "rgba(85, 120, 218, 0.50)" : "#202936",
      borderBottom: "1px solid #5A6A85",
      cursor: "pointer",
      "&:last-child": {
        borderBottom: "none",
      },
      "&:active": {
        backgroundColor: "rgba(85, 120, 218, 0.50)",
      },
      "&:hover": {
        backgroundColor: "rgba(85, 120, 218, 0.50)",
      },
    };

    if (data?.name === "app_list" && !data.is_alive && getIsStaff()) {
      customStyle.color = "#FF4136";
    }

    return customStyle;
  },
};

const MultiSelect = ({
  options,
  setSelectedOptions,
  placeholder,
  isMulti,
  value,
  isClearable,
  defaultValue,
}) => {
  return (
    <Select
      noOptionsMessage={() => "Нет опций"}
      defaultValue={!isMulti && defaultValue}
      options={options}
      onChange={setSelectedOptions}
      value={value}
      // onChange={(o) => setItem(o)}
      // onChange={(o) => setSelectedOptions(o.map((i) => i.value))}
      // onChange={(o) => console.log('console.log o', o)}
      isMulti={isMulti}
      styles={selectStyles}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

export default MultiSelect;
