import React, { useEffect } from "react";
import s from "./topUpBalancePopup.module.scss";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
import { useDispatch, useSelector } from "react-redux";
import {
  financeSelector,
  setTopUpBalanceActive,
} from "../../../../redux/slices/financeSlice";
import BaseTextInput from "../../../BaseTextInput/BaseTextInput";

const TopUpBalancePopup = () => {
  const { wallet, topUpBalanceIsActive } = useSelector(financeSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleEscClose = (event) => {
      if (event.key === "Escape") {
        handleClosePopup();
      }
    };

    if (topUpBalanceIsActive) {
      document.addEventListener("keydown", handleEscClose);
    }

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [topUpBalanceIsActive, dispatch]);

  const handleClosePopup = () => {
    dispatch(setTopUpBalanceActive(false));
  };

  const handleWrapperClick = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      handleClosePopup();
    }
  };

  return (
    <div
      className={
        topUpBalanceIsActive ? `${s.popupWrapper} ${s.active}` : s.popupWrapper
      }
      onClick={handleWrapperClick}
    >
      <div
        className={topUpBalanceIsActive ? `${s.popup} ${s.active}` : s.popup}
      >
        <div className={s.header}>
          <span>Пополнение баланса</span>
          <button className={s.closeBtn} onClick={handleClosePopup}>
            <GlobalSvgSelector id="close-big-icon" />
          </button>
        </div>
        <div className={s.body}>
          <div>
            <h5 className={s.subTitle}>Криптовалюта</h5>
            <BaseTextInput value="USDT TRC20" placeholder="Ссылка" />
          </div>
          <div>
            <h5 className={s.subTitle}>Криптокошелек</h5>
            <BaseTextInput
              value={wallet}
              placeholder="Загрузка..."
              copy={true}
            />
          </div>
          <div className={s.infoSupport}>
            При переводе на указанный адрес ваш баланс обновится автоматически.
            В случае проблем обратитесь в
            <a href="https://t.me/yaroslava_support_wwa" target="_blank">
              Поддержку
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUpBalancePopup;
