import React, { useEffect } from "react";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
import s from "./setNotePopup.module.scss";
import BaseTextInput from "../../../BaseTextInput/BaseTextInput";
import RedButton from "../../Buttons/RedButton/RedButton";
import BlueButton from "../../Buttons/BlueButton/BlueButton";

const SetNotePopup = ({
  active,
  setActive,
  flowId,
  action,
  title,
  notes,
  noteText,
  setNoteText,
}) => {
  const data = {
    flow_id: flowId,
    notes: noteText.trim(),
  };

  useEffect(() => {
    const handleEnterPress = (e) => {
      if (e.key === "Enter") {
        action(data);
      }
    };

    if (active) {
      document.addEventListener("keydown", handleEnterPress);
    }

    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [action, active, data, noteText, notes]);

  useEffect(() => {
    if (notes) {
      setNoteText(notes);
    }

    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        setActive(false);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };

    if (active) {
      document.addEventListener("keydown", handleEscClose);
    }

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [notes, active, setNoteText, setActive]);

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      setActive(false);
    }
  };

  return (
    <div
      onClick={handleClosePopup}
      className={active ? `${s.popupWrapper} ${s.active}` : s.popupWrapper}
    >
      <div className={active ? `${s.popup} ${s.active}` : s.popup}>
        <div className={s.alertHeaderWrapper}>
          <div className={s.header}>
            <GlobalSvgSelector id="popup-alert-icon" />
          </div>
          <div className={s.content}>
            <span>{title}</span>
          </div>
        </div>

        <div className={s.popupContent}>
          <BaseTextInput
            action={(e) => setNoteText(e.target.value)}
            value={noteText}
            placeholder="Текст заметки"
          />

          <div className={s.buttonsWrapper}>
            <RedButton
              text="Отменить"
              action={() => setActive(false)}
              width={120}
            />
            <BlueButton
              width={120}
              text="Сохранить"
              action={() => action(data)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNotePopup;
