import React from 'react';
// import s from "../Flows/EditFlowSettings/Steps/PrelandingStep/CreatePrelanding/createPrelanding.module.scss";
// import {setPrelandTitle} from "../../redux/slices/flowSlice";
import s from './limitedInput.module.scss'


const LimitedInput = ({value, action, placeholder = "Введите текст", maxLength, subtitle, tip}) => {

    return (
        <div className={s.inputWrapper} style={tip && {marginBottom: 10}}>
            <h5 className={s.subTitle}>{subtitle}</h5>
            <div className={s.inputContainer}>
                <input
                    type="text"
                    className={s.textInput}
                    placeholder={placeholder}
                    value={value}
                    onChange={action}
                    maxLength={maxLength}
                />
                {
                    value.length > 0 &&
                    <div className={s.lengthIndicator}>{value.length}/{maxLength}</div>
                }
            </div>
        </div>
    )
}

export default LimitedInput;