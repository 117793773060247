import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRedirectorById,
  editRedirector,
  setEditRedirectorObj,
} from "../../../redux/slices/redirectorSlice";

import { fetchAppList } from "../../../redux/slices/applicationSlice";
import {
  handleContinue,
  scrollToBottom,
} from "../../../utils/helpers/scrollTop";
import CustomSkeleton from "../../CustomSkeleton/CustomSkeleton";
import s from "../allRedirectors.module.scss";
import EditApplicationItem from "./EditApplicationItem";
import { toast } from "react-hot-toast";
import Loader from "../../UiKit/Loader/Loader";
import { getUser } from "../../../storage/storage";
import BaseTextInput from "../../BaseTextInput/BaseTextInput";
import RedButton from "../../UiKit/Buttons/RedButton/RedButton";
import BlueButton from "../../UiKit/Buttons/BlueButton/BlueButton";

const EditRedirector = () => {
  const [applicationPercentages, setApplicationPercentages] = useState([]);
  const [editedApplicationPercentages, setEditedApplicationPercentages] =
    useState([]);

  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { appListLoading, appList } = useSelector((state) => state.application);
  const { editRedirectorObj, redirectorLoading, editRedirectorLoading } =
    useSelector((state) => state.redirector);

  const { user_id } = getUser();

  const hasNullApplication = applicationPercentages.some(
    (item) => item.application === null,
  );

  const onSaveHandler = async () => {
    if (hasNullApplication) {
      toast.error("Заполните приложения");
      return;
    }

    const onSuccessAction = () => {
      toast.success("Редирект был успешно отредактирован");
      navigate("/all-redirectors");
      handleContinue();
    };

    const onErrorAction = (text) => {
      toast.error(text, {
        style: {
          minWidth: "500px",
        },
      });
    };

    await dispatch(
      editRedirector({
        id,
        updatedData: sendDataObj,
        onSuccessAction,
        onErrorAction,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchAppList());
    dispatch(fetchRedirectorById(id));
  }, []);

  const sendDataObj = {
    sub7: editRedirectorObj?.sub7,
    user_id: user_id,
    application_percentages: applicationPercentages,
  };

  useEffect(() => {
    // setEditRedirectorObj()
    if (editRedirectorObj && editRedirectorObj.application_percentages) {
      setEditedApplicationPercentages([]);
      setApplicationPercentages([]);

      editRedirectorObj?.application_percentages.map(
        ({ percentage, application }) =>
          setEditedApplicationPercentages((prev) => [
            ...prev,
            {
              percentage: percentage,
              appName: application.name,
              appId: application.id,
            },
          ]),
      );
      editRedirectorObj?.application_percentages.map(
        ({ percentage, application }) =>
          setApplicationPercentages((prev) => [
            ...prev,
            {
              percentage: percentage,
              application: application.id,
            },
          ]),
      );
    }

    return () => {
      setEditedApplicationPercentages([]);
      setApplicationPercentages([]);
      setEditRedirectorObj();
    };
  }, [editRedirectorObj]);

  const addNextAddition = () => {
    if (applicationPercentages.length < 100) {
      setApplicationPercentages((prevState) => [
        ...prevState,
        { application: null, percentage: 0 },
      ]);
    }
    if (editedApplicationPercentages.length < 100) {
      setEditedApplicationPercentages((prevState) => [
        ...prevState,
        { appId: null, appName: null, percentage: 0 },
      ]);
    }
  };

  // debugger

  // const appListOptions = appList.map(({ name, id }) => ({
  //   value: id,
  //   label: name,
  // }));

  const appListOptions = useMemo(() => {
    return appList.map((item) => ({
      label: item.name,
      value: item.id,
      is_alive: item.is_alive,
      name: "app_list",
    }));
  }, [appList]);

  return (
    <div className={s.editRedirectorsWrapper}>
      {/*<div className={s.redirectorsHeader}>*/}
      {/*  <h3>Редактировать редирект</h3>*/}
      {/*</div>*/}

      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Редактирование редиректа</h3>
      </div>

      <div className={s.container}>
        {redirectorLoading || appListLoading ? (
          <CustomSkeleton styles={s.skeleton} />
        ) : (
          <div className={s.editRedirectWrapper}>
            <div className={s.sub7InputWrapper}>
              <h5 className={s.subTitle}>Sub 7</h5>
              <BaseTextInput
                // action={(e) => setSub7(e.target.value)}
                value={editRedirectorObj?.sub7}
                placeholder="Sub7"
              />
            </div>

            {/*<span className={s.sub7}>Sub 7</span>*/}
            {/*<div className={s.sub7InputName}>*/}
            {/*  <input*/}
            {/*    type="text"*/}
            {/*    className={s.textInput}*/}
            {/*    value={editRedirectorObj?.sub7}*/}
            {/*    readOnly*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<h3 className={s.appsTitle}>Приложения</h3>*/}

            <h5 className={s.subtitleBorderBottom}>Приложения</h5>

            <div className={s.additionWrapper}>
              <div className={s.additionContent}>
                {editedApplicationPercentages?.map((obj, index) => (
                  <div key={index}>
                    <EditApplicationItem
                      app={obj}
                      index={index}
                      options={appListOptions}
                      // options={appList}
                      editRedirectorObj={editRedirectorObj}
                      setEditedApplicationPercentages={
                        setEditedApplicationPercentages
                      }
                      setApplicationPercentages={setApplicationPercentages}
                    />
                  </div>
                ))}
              </div>

              <div className={s.addNext}>
                <BlueButton
                  action={() => {
                    addNextAddition();
                    scrollToBottom();
                  }}
                  pending={applicationPercentages.length === 100}
                  text="Добавить"
                  width={120}
                />
              </div>

              {/*<div className={s.addNext}>*/}
              {/*  <button*/}
              {/*      className={s.addNextBtn}*/}
              {/*      disabled={applicationPercentages.length === 100}*/}
              {/*      onClick={addNextAddition}*/}
              {/*  >*/}
              {/*    Добавить следующий*/}
              {/*  </button>*/}
              {/*</div>*/}
            </div>
          </div>
        )}

        <div className={s.buttonsWrapper}>
          <RedButton
            width={120}
            text="Отменить"
            action={() => {
              navigate("/all-redirectors");
              handleContinue();
            }}
          />
          <BlueButton
            width={120}
            text="Сохранить"
            pending={editRedirectorLoading || redirectorLoading}
            action={onSaveHandler}
          />
        </div>

        {/*<div className={s.buttonsWrapper}>*/}
        {/*  <button*/}
        {/*      className={s.cancelBtn}*/}
        {/*      onClick={() => {*/}
        {/*        navigate("/all-redirectors");*/}
        {/*        handleContinue();*/}
        {/*      }}*/}
        {/*  >*/}
        {/*    Отмена*/}
        {/*  </button>*/}
        {/*  <button*/}
        {/*      className={s.nextStepBtn}*/}
        {/*      onClick={onSaveHandler}*/}
        {/*      disabled={editRedirectorLoading || redirectorLoading}*/}
        {/*  >*/}
        {/*    {redirectorLoading || editRedirectorLoading*/}
        {/*        ? <Loader/>*/}
        {/*        : "Сохранить"}*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default EditRedirector;
