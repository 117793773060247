import React, {useCallback, useEffect, useRef, useState} from "react";
import debounce from "lodash.debounce";
import GlobalSvgSelector from "../GlobalSvgSelector/GlobalSvgSelector";
import s from "./search.module.scss";

const Search = ({ setSearchValue, searchValue }) => {
  const [value, setValue] = useState("");
  const inputRef = useRef(null);


    useEffect(() => {
        if (searchValue) {
            setValue(searchValue)
            setSearchValue(searchValue)
        }
    }, [searchValue]);

  const onClickClear = () => {
    setSearchValue("");
    setValue('')
    inputRef.current.focus();
  };

  const updateSearchValue = useCallback(
    debounce((str) => {
      setSearchValue(str);
    }, 700),
    [],
  );

  const onChangeInput = (event) => {
    setValue(event.target.value);
    updateSearchValue(event.target.value);
  };

  return (
    <div className={s.searchInputWrapper}>
      <input
        ref={inputRef}
        // value={searchValue}
        value={value}
        onChange={onChangeInput}
        className={s.searchInput}
        placeholder="Поиск"
      />
      <div className={s.SearchBtn}>
        <GlobalSvgSelector id="search-white-icon" />
      </div>
      {value && (
        <button className={s.clearSearchValue} onClick={onClickClear}>
          <GlobalSvgSelector id="clear-field-icon" />
        </button>
      )}
    </div>
  );
};

export default Search;
