import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { token } from "../../localStorage/localStorage";
import axios from "axios";
import $api, { API_URL } from "../../api/url";

export const fetchPixelApi = createAsyncThunk(
  "pixelApi/fetchData",
  async ({ page, search, page_size = 8 }) => {
    try {
      const response = await $api.get(
        `/pixel/pixel-api?page_size=${page_size}&page=${page}&search=${search}`,
      );
      return response.data;
    } catch (error) {
      const errorMessage = error.response.data.detail;

      if (errorMessage === "Invalid page.") {
        throw new Error(errorMessage);
      } else {
        throw error;
      }
    }
  },
);

export const checkAllTokens = createAsyncThunk(
  "pixelApi/checkAllTokens",
  async () => {
    try {
      const { data } = await $api.get(`/pixel/check-all-valid-token/`);
      console.log("token detail fff", data);

      return data?.detail;
    } catch (error) {
      throw error;

      // const errorMessage = error.response.data.detail;

      // if (errorMessage === "Invalid page.") {
      //   throw new Error(errorMessage);
      // } else {
      //   throw error;
      // }
    }
  },
);

export const editPixelApiItem = createAsyncThunk(
  "pixelApi/editPixelApiItem",
  async ({ successAction, id, updatedData, onErrorAction }) => {
    try {
      const response = await $api.put(`/pixel/pixel-api/${id}/`, updatedData);
      successAction();
    } catch (error) {
      const errorMessage = error.response.data.non_field_errors[0];

      if (
        errorMessage ===
        "Для этого пользователя уже существует пиксель с таким ID."
      ) {
        onErrorAction("Pixel с таким ID уже существует");
        throw new Error(errorMessage);
      } else {
        onErrorAction("Произошла ошибка");
        throw error;
      }
    }
  },
);

export const postPixelApiItem = createAsyncThunk(
  "pixelApi/postPixelApiItem",
  async ({ successAction, data, onErrorAction }) => {
    try {
      const response = await $api.post("/pixel/pixel-api/", data);
      successAction();
      return response.data;
    } catch (error) {
      const errorMessage = error.response.data.details;

      if (
        errorMessage ===
        "Для этого пользователя уже существует пиксель с таким ID."
      ) {
        onErrorAction("Pixel с таким ID уже существует");
        throw new Error(errorMessage);
      } else {
        onErrorAction("Произошла ошибка");
        throw error;
      }
    }
  },
);

export const deletePixelApiItem = createAsyncThunk(
  "pixelApi/deletePixelApiItem",
  async (id) => {
    try {
      const response = await $api.delete(`/pixel/pixel-api/${id}/`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

// відповідає за чекбокс в піксель апі для сапортів
export const switchSupportHandle = createAsyncThunk(
  "pixelApi/switchSupportHandle",
  async (dataObj) => {
    try {
      const response = await $api.post(`/pixel/handle-pixels-api/`, dataObj);
      const { data, status } = response;
      return { data, status };
    } catch (error) {
      throw error;
    }
  },
);

export const checkPixelTokenValid = createAsyncThunk(
  "pixelApi/checkPixelTokenValid",
  async (token) => {
    try {
      const { data } = await $api.get(
        `/pixel/handle-pixels-api?token=${token}`,
      );

      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchPixelApiItem = createAsyncThunk(
  "pixelApi/fetchPixelApiItem",
  async (id) => {
    try {
      const response = await $api.get(`/pixel/pixel-api/${id}/`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const pixelApiSlice = createSlice({
  name: "pixelApi",
  initialState: {
    pixelApiList: [],
    pixelApiListLoading: false,
    error: null,
    PixelApiItem: {},
    PixelApiItemLoading: true,
    totalCount: 0,
    currentPage: 1,
    createLoading: false,
    isValidPixelApiTokens: true,
  },
  reducers: {
    pixelApiSetCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPixelApi.pending, (state) => {
        state.pixelApiListLoading = true;
      })
      .addCase(fetchPixelApi.fulfilled, (state, action) => {
        // state.pixelApiList = action.payload.results;
        state.pixelApiList = action.payload.results;
        // state.pixelApiList = []
        // state.totalCount = action.payload.count
        state.totalCount = action.payload.count;
        state.pixelApiListLoading = false;
      })
      .addCase(fetchPixelApi.rejected, (state, action) => {
        if (action.error.message === "Invalid page.") {
          state.currentPage = 1;
        }
        state.flowListLoading = false;
        state.pixelApiListLoading = false;
      })

      .addCase(fetchPixelApiItem.pending, (state) => {
        state.PixelApiItemLoading = true;
      })
      .addCase(fetchPixelApiItem.fulfilled, (state, action) => {
        state.singlePixelApi = {
          pixel_id: action.payload.pixel_id,
          token: action.payload.token,
        };
        state.PixelApiItemLoading = false;
      })
      .addCase(fetchPixelApiItem.rejected, (state) => {
        state.PixelApiItemLoading = false;
      })

      .addCase(postPixelApiItem.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(postPixelApiItem.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(postPixelApiItem.rejected, (state) => {
        state.createLoading = false;
      })

      // .addCase(checkAllTokens.pending, (state) => {
      //   state.isValidPixelApiTokens = true;
      // })
      .addCase(checkAllTokens.fulfilled, (state, action) => {
        state.isValidPixelApiTokens = action.payload;
      });
    // .addCase(checkAllTokens.rejected, (state) => {
    //   state.isValidPixelApiTokens = false;
    // });
  },
});

export const { pixelApiSetCurrentPage } = pixelApiSlice.actions;

export default pixelApiSlice.reducer;
