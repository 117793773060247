import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import s from "./flows.module.scss";
import Pagination from "../Pagination/Pagination";
import FlowItem from "./FlowItem";
import { useDispatch, useSelector } from "react-redux";
import CustomSkeleton from "../CustomSkeleton/CustomSkeleton";
import { toast } from "react-hot-toast";
import { handleContinue } from "../../utils/helpers/scrollTop";
import {
  deleteFlow,
  fetchFlowList,
  flowSelector,
  setCurrentPage,
  setTotalCount,
} from "../../redux/slices/flowSlice";

import qs from "qs";
import { Link, useNavigate } from "react-router-dom";
import Search from "../Search/Search";
import MultiSelect from "../MultiSelect/MultiSelect";
import { fetchAppList } from "../../redux/slices/applicationSlice";
import { fetchDomainsList } from "../../redux/slices/globalDataSlice";
import { fetchRedirectorsList } from "../../redux/slices/redirectorSlice";
import BlueButton from "../UiKit/Buttons/BlueButton/BlueButton";
import EmptyList from "../EmptyList/EmptyList";

const Flows = () => {
  const [searchValue, setSearchValue] = useState("");

  const [selectedApps, setSelectedApps] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [selectedSub7, setSelectedSub7] = useState([]);

  const [domainSelectInput, setDomainSelectInput] = useState([]);
  const [appSelectInput, setAppSelectInput] = useState(null);
  const [sub7SelectInput, setSub7SelectInput] = useState(null);

  const isSearch = useRef(false);
  const isMounted = useRef(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { domainsList } = useSelector((state) => state.globalData);
  const { appList } = useSelector((state) => state.application);

  const filteredAppList = useMemo(() => {
    return appList.filter((item) => item?.is_alive === true);
  }, [appList]);

  const { redirectorsList } = useSelector((state) => state.redirector);
  const { isPartner } = useSelector((state) => state.auth);

  const {
    flowListLoading,
    flowList,
    totalCount,
    currentPage,
    duplicateFlowPending,
  } = useSelector(flowSelector);

  const getFlows = useCallback(() => {
    const fetchFlowParams = {
      domains: selectedDomains.join(","),
      applications: selectedApps.join(","),
      page: currentPage || 1,
      sub7: selectedSub7.join(","),
      search: searchValue,
    };

    dispatch(fetchFlowList(fetchFlowParams));
  }, [
    currentPage,
    dispatch,
    searchValue,
    selectedApps,
    selectedDomains,
    selectedSub7,
  ]);

  useEffect(() => {
    dispatch(fetchAppList());
    dispatch(fetchDomainsList());
    dispatch(fetchRedirectorsList({}));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(1));
      dispatch(setTotalCount(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (isMounted.current) {
      const queryString = qs.stringify({
        domains: selectedDomains.join(","),
        applications: selectedApps.join(","),
        page: currentPage,
        sub7: selectedSub7.join(","),
        search: searchValue,
      });

      navigate(`?${queryString}`);
    }

    isMounted.current = true;
  }, [
    selectedDomains,
    selectedApps,
    selectedSub7,
    currentPage,
    searchValue,
    navigate,
  ]);

  useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));

      if (params.domains) {
        const domains = params.domains.split(",");
        const domainSelectInputList = domains.map((item) => ({
          value: item,
          label: item,
        }));
        setDomainSelectInput(domainSelectInputList);
        setSelectedDomains(domains);
      } else {
        setDomainSelectInput([]);
        setSelectedDomains([]);
      }

      if (params.applications) {
        const applications = params.applications.split(",");
        const appSelectInputList = applications.map((item) => ({
          value: item,
          label: item,
        }));
        setAppSelectInput(appSelectInputList);
        setSelectedApps(applications);
      } else {
        setAppSelectInput(null);
        setSelectedApps([]);
      }

      if (params.page) {
        dispatch(setCurrentPage(parseInt(params.page)));
      }

      if (params.search) {
        setSearchValue(params.search);
      }

      if (params.sub7) {
        const sub7s = params.sub7.split(",");
        const sub7SelectInputList = sub7s.map((item) => ({
          value: item,
          label: item,
        }));
        setSub7SelectInput(sub7SelectInputList);
        setSelectedSub7(sub7s);
      } else {
        setSub7SelectInput(null);
        setSelectedSub7([]);
      }

      isSearch.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isSearch.current) {
      getFlows();
    }

    isSearch.current = false;
  }, [
    selectedDomains,
    selectedApps,
    selectedSub7,
    currentPage,
    searchValue,
    getFlows,
  ]);

  const onDeleteHandler = async (id) => {
    await dispatch(deleteFlow({ id }));
    toast.success("Запись была успешно удалена");
    handleContinue();
    await getFlows();
  };

  const domainListOptions = domainsList.map(({ address }) => ({
    value: address,
    label: address,
  }));
  // const appListOptions = filteredAppList.map(({ name }) => ({
  //   value: name,
  //   label: name,
  // }));

  const appListOptions = useMemo(() => {
    return appList.map((item) => ({
      label: item.name,
      value: item.name,
      is_alive: item.is_alive,
      name: "app_list",
    }));
  }, [appList]);

  const sub7ListOptions = redirectorsList.map(({ sub7 }) => ({
    value: sub7,
    label: sub7,
  }));

  console.log("flowListLoading", flowListLoading);

  return (
    <div className={s.streamsWrapper}>
      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Мои потоки</h3>
        <Link to="/my-flows/create-flow" className={s.blueButton}>
          Создать
        </Link>
        {/*<BlueButton*/}
        {/*  width={120}*/}
        {/*  text="Создать"*/}
        {/*  action={() => navigate("/my-flows/create-flow")}*/}
        {/*/>*/}
      </div>

      <div className={s.filtersWrapper}>
        <h5 className={s.filtersTitle}>Фильтрация</h5>
        <div className={s.filters}>
          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={sub7SelectInput}
              options={sub7ListOptions}
              setSelectedOptions={(o) => {
                setSelectedSub7(o.map((i) => i.value));
                setSub7SelectInput(o);
              }}
              placeholder="Sub7"
              isMulti={true}
            />
          </div>
          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={domainSelectInput}
              options={domainListOptions}
              setSelectedOptions={(o) => {
                setSelectedDomains(o.map((i) => i.value));
                setDomainSelectInput(o);
              }}
              placeholder="Домен"
              isMulti={true}
            />
          </div>
          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={appSelectInput}
              options={appListOptions}
              setSelectedOptions={(o) => {
                setSelectedApps(o.map((i) => i.value));
                setAppSelectInput(o);
              }}
              placeholder="Приложение"
              isMulti={true}
            />
          </div>
        </div>
      </div>

      <div className={s.searchBar}>
        <div className={s.recordsCount}>
          Всего: {totalCount && <span>{totalCount}</span>}
        </div>
        <Search searchValue={searchValue} setSearchValue={setSearchValue} />
      </div>
      <div className={s.tableWrapper}>
        {flowListLoading ? (
          <CustomSkeleton styles={s.skeletonTable} />
        ) : totalCount === 0 && !flowListLoading ? (
          <EmptyList />
        ) : (
          <div className={s.table}>
            <table>
              <thead>
                <tr>
                  <th>Заметка</th>
                  <th>Домен</th>
                  <th>Приложение</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                {flowList.map((item) => (
                  <tr key={item.id}>
                    <FlowItem
                      isPartner={isPartner}
                      duplicateFlowPending={duplicateFlowPending}
                      getFlows={getFlows}
                      item={item}
                      onDeleteHandler={onDeleteHandler}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {totalCount && (
          <div className={s.paginationWrapper}>
            <Pagination
              currentPage={parseInt(currentPage)}
              setCurrentPage={(page) => dispatch(setCurrentPage(page))}
              total={totalCount}
              limit={7}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Flows;
