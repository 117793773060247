import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { startCreateFlow } from "../../../../redux/slices/applicationSlice";
import ConfirmPopup from "../../../UiKit/Popups/ConfirmPopup/ConfirmPopup";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
import { handleContinue } from "../../../../utils/helpers/scrollTop";
import s from "../createFlow.module.scss";
import BlueButton from "../../../UiKit/Buttons/BlueButton/BlueButton";
import RedButton from "../../../UiKit/Buttons/RedButton/RedButton";

const DomainStep = () => {
  const [activeCreateFlowPopup, setActiveCreateFlowPopup] = useState(false);
  const [activeFlowCreateLimitPopup, setActiveFlowCreateLimitPopup] =
    useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { createFlowLoading } = useSelector((state) => state.application);
  const { isPartner } = useSelector((state) => state.auth);

  const startCreateFlowHandler = () => {
    const onSuccessAction = (id) => {
      navigate(`/my-flows/edit/${id}`);
    };

    const onErrorAction = (text) => {
      toast.error(text, {
        style: {
          minWidth: "400px",
        },
      });

      navigate("/my-flows");
      handleContinue();
    };

    const setLimitPopup = () => {
      setActiveFlowCreateLimitPopup(true);
    };

    dispatch(
      startCreateFlow({
        onErrorAction,
        onSuccessAction: onSuccessAction,
        setLimitPopup,
      }),
    );
  };

  return (
    <div className={s.domainContainer}>
      <div className={s.domainContent}>
        <ConfirmPopup
          active={activeFlowCreateLimitPopup}
          setActive={setActiveFlowCreateLimitPopup}
          action={() => {
            setActiveFlowCreateLimitPopup(false);
            navigate("/my-flows");
          }}
          confirmBtnText="Продолжить"
          confirmName="Создание потока"
          content="Вы достигли дневного лимита по количеству новых потоков"
          support
          alert
        />
        <ConfirmPopup
          active={activeCreateFlowPopup}
          setActive={setActiveCreateFlowPopup}
          action={startCreateFlowHandler}
          confirmBtnText="Продолжить"
          confirmName="Создание потока"
          content={
            !isPartner &&
            "При создании потока с вашего баланса спишется $6 за домен"
          }
        />
        <div className={s.domainStep}>
          <h5 className={s.subtitleBorderBottom}>Домен</h5>
          <div className={s.message}>
            <GlobalSvgSelector id="check-circle-icon" />
            <span>Вам будет предоставлен уже готовый к работе домен</span>
          </div>
        </div>
      </div>

      <div className={s.buttonsWrapper}>
        <RedButton
          text="Отменить"
          action={() => navigate("/my-flows")}
          width={120}
        />
        <BlueButton
          width={120}
          text="Создать"
          pending={createFlowLoading}
          action={() => setActiveCreateFlowPopup(true)}
        />
      </div>
    </div>
  );
};

export default DomainStep;
