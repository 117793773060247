import React from 'react';
import s from './baseTextInput.module.scss'
import {toast} from "react-hot-toast";
import GlobalSvgSelector from "../GlobalSvgSelector/GlobalSvgSelector";
import MuiTooltip from "../UiKit/MuiTooltip/MuiTooltip";

const BaseTextInput = ({value, placeholder, action, copy, redirect}) => {

    const copyText = async () => {
        await navigator.clipboard.writeText(value);
        toast.success("Значение скопировано");
    };

    return (
        <div className={s.mainInputWrapper}>
            <input
                readOnly={!action}
                type="text"
                value={value}
                onChange={action}
                className={s.textInput}
                placeholder={placeholder}
                style={redirect ? {paddingRight: 85} : null}
            />


            {/*<a*/}
            {/*    href={app_store_url}*/}
            {/*    target="_blank"*/}
            {/*    className={s.followLink}*/}
            {/*>*/}
            {/*    <MuiTooltip title='Открыть в App Store'>*/}
            {/*        <GlobalSvgSelector id="follow-link-icon"/>*/}
            {/*    </MuiTooltip>*/}
            {/*</a>*/}

            {
                copy && value && <div className={s.copyRedirectContainer}>
                    <button className={s.copyBtn} onClick={copyText}>
                        <MuiTooltip title='Скопировать'>
                            <GlobalSvgSelector id='copy-link-icon'/>
                        </MuiTooltip>
                    </button>


                    {
                        redirect && <a
                            href={value}
                            target="_blank"
                            className={s.followLink}
                        >
                            <MuiTooltip title='Открыть ссылку'>
                                <GlobalSvgSelector id="follow-link-icon"/>
                            </MuiTooltip>
                        </a>
                    }

                    {/*<button className={s.copyBtn} onClick={copyText}>*/}
                    {/*    <MuiTooltip title='Скопировать'>*/}
                    {/*        <GlobalSvgSelector id='copy-link-icon'/>*/}
                    {/*    </MuiTooltip>*/}
                    {/*</button>*/}
                </div>
            }
        </div>
    )
}

export default BaseTextInput;