import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import $api, { API_URL } from "../../api/url";

export const login = createAsyncThunk(
  "auth/login",
  async ({ form, setError }) => {
    try {
      const response = await axios.post(`${API_URL}/users/token/obtain/`, {
        ...form,
      });

      const { data, config } = response;

      return { data, config };
    } catch (error) {
      if (error.response.status === 401) {
        const message = "Неверные данные";
        setError("email", { type: "manual", message });
        setError("password", { type: "manual", message });

        throw new Error("wrong credentials");
      } else {
        throw error;
      }
    }
  },
);

export const resetPassword = createAsyncThunk(
  "auth/reset_password",
  async ({ data, successAction, setError }) => {
    try {
      const response = await axios.post(
        `${API_URL}/auth/users/reset_password/`,
        data,
      );
      successAction();
      return response.data;
    } catch (error) {
      const emailError = error?.response?.data;

      if (emailError.includes("User with given email does not exist.")) {
        setError("email", {
          type: "manual",
          message: "Записи с такой почтой не существует",
        });
      }

      throw error;
    }
  },
);

export const resetPasswordConfirm = createAsyncThunk(
  "auth/reset_password_confirm",
  async ({ data, onSuccessAction, onErrorAction }) => {
    try {
      const response = await axios.post(
        `${API_URL}/auth/users/reset_password_confirm/`,
        data,
      );
      onSuccessAction();
      return response.data;
    } catch (error) {
      onErrorAction();
      throw error;
    }
  },
);

export const register = createAsyncThunk(
  "auth/register",
  async ({ data, successAction, setError }) => {
    console.log("hereee");

    try {
      const response = await axios.post(`${API_URL}/auth/users/`, data);
      successAction();
      return response.data;
    } catch (error) {
      const emailError = error?.response?.data?.email;
      const passwordError = error?.response?.data?.password;

      if (
        emailError?.includes("user with this email address already exists.")
      ) {
        setError("email", {
          type: "manual",
          message: "Запись с такой почтой уже существует",
        });
      }

      if (
        passwordError?.includes(
          "The password is too similar to the email address.",
        )
      ) {
        setError("newPassword1", {
          type: "manual",
          message: "Пароль должен отличаться от почты",
        });
      }

      if (passwordError?.includes("This password is too common.")) {
        setError("newPassword1", {
          type: "manual",
          message: "Пароль слишком легкий",
        });
      }

      throw error;
    }
  },
);

export const refreshTokens = createAsyncThunk("auth/checkAuth", async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await $api.post("/users/token/refresh/", {
      refresh: refreshToken,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchMyUserInfo = createAsyncThunk("fetchMyUserInfo", async () => {
  try {
    const response = await $api.get("/auth/users/me/");

    return response.data;
  } catch (error) {
    throw error;
  }
});

export const changePassword = createAsyncThunk(
  "account/changePassword",
  async ({ data, setError, onSuccessAction }) => {
    try {
      const response = await $api.post("/auth/users/set_password/", data);
      onSuccessAction();
      return response.data;
    } catch (error) {
      const currentPasswordError = error?.response?.data?.current_password;
      const newPasswordError = error?.response?.data?.new_password;

      if (
        newPasswordError?.includes(
          "The password is too similar to the email address.",
        )
      ) {
        setError("newPassword1", {
          type: "manual",
          message: "Пароль должен отличаться от почты",
        });
      }

      if (newPasswordError?.includes("This password is too common.")) {
        setError("newPassword1", {
          type: "manual",
          message: "Пароль слишком легкий",
        });
      }

      if (currentPasswordError) {
        setError("currentPassword", {
          type: "manual",
          message: "Текущий пароль не совпадает",
        });
      }

      throw error;
    }
  },
);

export const isUserAuthenticated = () => {
  const accessToken = localStorage.getItem("accessToken");
  return !!accessToken;
};

const initialState = {
  user: {},
  loading: false,
  error: "",
  changePasswordPending: false,
  isPartner: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        localStorage.setItem("accessToken", action.payload.data.access);
        localStorage.setItem("refreshToken", action.payload.data.refresh);
        window.history.pushState({}, "", "/app-catalog");
        window.location.reload();
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        // state.error = true;
        state.loading = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
      })

      // .addCase(fetchMyUserInfo.pending, (state) => {
      //   state.isPartner = null;
      // })
      .addCase(fetchMyUserInfo.fulfilled, (state, action) => {
        state.isPartner = action?.payload?.is_wwa_partner;
      })
      .addCase(fetchMyUserInfo.rejected, (state, action) => {
        state.isPartner = true;
      })

      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(resetPasswordConfirm.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPasswordConfirm.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(resetPasswordConfirm.rejected, (state, action) => {
        state.loading = false;
      })

      //////////////////////////////////////////////////////////////

      .addCase(refreshTokens.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshTokens.fulfilled, (state, action) => {
        localStorage.setItem("accessToken", action.payload.access);
        localStorage.setItem("refreshToken", action.payload.refresh);
        state.loading = false;
      })
      .addCase(refreshTokens.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {} = authSlice.actions;

export default authSlice.reducer;

export const authSelector = (state) => state.auth;
