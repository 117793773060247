import React, { useState } from "react";
import s from "../allRedirectors.module.scss";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import { handleContinue } from "../../../utils/helpers/scrollTop";
import { Link, useNavigate } from "react-router-dom";
import ConfirmPopup from "../../UiKit/Popups/ConfirmPopup/ConfirmPopup";
import { useDispatch } from "react-redux";

import { colorList } from "../../../utils/vars/staticVariables";
import MuiTooltip from "../../UiKit/MuiTooltip/MuiTooltip";

const RedirectorCard = ({
  id,
  application_percentages,
  sub7,
  onDeleteHandler,
}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);

  const dispatch = useDispatch();

  // const followFlow = () => {
  //     console.log('i am before dispatch')
  //     dispatch(fetchFlowBySub7({sub7}))
  // }

  return (
    <div className={s.redirectorCardWrapper}>
      <ConfirmPopup
        active={active}
        setActive={setActive}
        action={() => onDeleteHandler(id)}
        confirmName="Удаление"
        confirmBtnText="Удалить"
      />
      <div className={s.cardHeader}>
        <div className={s.headerInfo}>
          <span className={s.name}>sub7</span>
          <span className={s.sub7}>{sub7}</span>
        </div>
        <div className={s.manageButtons}>
          {/*<button onClick={followFlow}>*/}
          {/*  <GlobalSvgSelector id="pointer-icon" />*/}
          {/*</button>*/}

          <div className={s.manageButton}>
            <Link to={`/all-redirectors/edit/${id}`} className={s.editBtn}>
              <MuiTooltip title="Редактировать редирект">
                <GlobalSvgSelector id="edit-icon" />
              </MuiTooltip>
            </Link>
            {/*<button*/}
            {/*    className={s.editBtn}*/}
            {/*    onClick={() => {*/}
            {/*        navigate(`/all-redirectors/edit/${id}`);*/}
            {/*        handleContinue();*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <MuiTooltip title='Редактировать редирект'>*/}
            {/*        <GlobalSvgSelector id="edit-icon"/>*/}
            {/*    </MuiTooltip>*/}
            {/*</button>*/}
          </div>

          <div className={s.manageButton}>
            <button className={s.removeBtn} onClick={() => setActive(true)}>
              <MuiTooltip title="Удалить редирект">
                <GlobalSvgSelector id="remove-icon" />
              </MuiTooltip>
            </button>
          </div>
        </div>
      </div>
      <div className={s.redirectorContent}>
        {application_percentages.map((item, index) => (
          <div className={s.item} key={item.id}>
            <a href={item.application.redirect_url} className={s.header}>
              {item.application.name}
            </a>
            <div className={s.transitions}>
              Переходы: <span>{item.service_count}</span>
            </div>
            <div className={s.percentage}>
              Проценты:{" "}
              <span style={{ color: colorList[index] }}>
                {Math.round(item.percentage)}%
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RedirectorCard;
