import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { createRedirector } from "../../../redux/slices/redirectorSlice";
import { fetchAppList } from "../../../redux/slices/applicationSlice";
import ApplicationItem from "../ApplicationItem/ApplicationItem";
import Loader from "../../UiKit/Loader/Loader";
import {
  handleContinue,
  scrollToBottom,
} from "../../../utils/helpers/scrollTop";

import {
  containsNonSpaceCharacter,
  hasNullApplicationArr,
  hasNullPercentageArr,
  validateAlphaNumbericStr,
} from "../../../utils/helpers/validation";
import s from "../allRedirectors.module.scss";
import { getUser } from "../../../storage/storage";
import BlueButton from "../../UiKit/Buttons/BlueButton/BlueButton";
import BaseTextInput from "../../BaseTextInput/BaseTextInput";
import RedButton from "../../UiKit/Buttons/RedButton/RedButton";

const CreateRedirector = () => {
  const [applicationPercentages, setApplicationPercentages] = useState([
    { application: null, percentage: 0 },
  ]);
  const [sub7, setSub7] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { appList } = useSelector((state) => state.application);
  const { redirectorListLoading } = useSelector((state) => state.redirector);

  const { user_id } = getUser();

  const sendDataObj = {
    sub7: sub7.trim(),
    user_id: user_id,
    application_percentages: applicationPercentages,
  };

  useEffect(() => {
    dispatch(fetchAppList());
  }, []);

  const addApplication = () => {
    if (applicationPercentages.length < 100) {
      setApplicationPercentages((prevState) => [
        ...prevState,
        { application: null, percentage: 0 },
      ]);
    }
  };

  const onCreateHandler = () => {
    if (!validateAlphaNumbericStr(sub7)) {
      toast.error("Некорректное название для sub7");
      return;
    }

    if (!containsNonSpaceCharacter(sub7)) {
      toast.error("Заполните поле sub7");
      return;
    }

    if (hasNullApplicationArr(applicationPercentages)) {
      toast.error("Заполните приложения");
      return;
    }

    if (hasNullPercentageArr(applicationPercentages)) {
      toast.error("Общая сумма процентов не равна 100");
      return;
    }

    const successAction = () => {
      toast.success("Новый редирект успешно создан");
      navigate("/all-redirectors");
      handleContinue();
    };

    const onErrorAction = (text) => {
      toast.error(text, {
        style: {
          minWidth: "500px",
        },
      });
    };

    dispatch(
      createRedirector({ successAction, data: sendDataObj, onErrorAction }),
    );
  };

  // const appListOptions = appList.map(({ name, id }) => ({
  //   value: id,
  //   label: name,
  // }));

  const appListOptions = useMemo(() => {
    return appList.map((item) => ({
      label: item.name,
      value: item.id,
      is_alive: item.is_alive,
      name: "app_list",
    }));
  }, [appList]);

  return (
    <div className={s.createRedirectorWrapper}>
      {/*<div className={s.redirectorsHeader}>*/}
      {/*    <h3>Создание редиректа</h3>*/}
      {/*</div>*/}

      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Создание редиректа</h3>
      </div>

      <div className={s.pageContentContainer}>
        <div className={s.createWrapper}>
          <div className={s.sub7InputWrapper}>
            <h5 className={s.subTitle}>Sub 7</h5>
            <BaseTextInput
              action={(e) => setSub7(e.target.value)}
              value={sub7}
              placeholder="Введите Sub7"
            />
          </div>

          <h5 className={s.subtitleBorderBottom}>Приложения</h5>

          <div className={s.additionWrapper}>
            <div className={s.additionContent}>
              {applicationPercentages.map((obj, index) => (
                <div key={index}>
                  <ApplicationItem
                    index={index}
                    options={appListOptions}
                    setApplicationPercentages={setApplicationPercentages}
                  />
                </div>
              ))}
            </div>

            <div className={s.addNext}>
              <BlueButton
                action={() => {
                  addApplication();
                  scrollToBottom();
                }}
                pending={applicationPercentages.length === 100}
                text="Добавить"
                width={120}
              />
            </div>
          </div>
        </div>

        <div className={s.buttonsWrapper}>
          <RedButton
            width={120}
            text="Отменить"
            action={() => {
              navigate("/all-redirectors");
              handleContinue();
            }}
          />
          <BlueButton
            width={120}
            text="Создать"
            pending={redirectorListLoading}
            action={onCreateHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateRedirector;
