import React from 'react';
import inactiveSortIcon from '../../../assets/img/inactiveSortIcon.svg'
import activeSortIcon from '../../../assets/img/activeSortIcon.svg'
import s from './sortIcon.module.scss'

const SortIcon = ({ onClick, isActive, currentSort }) => {

    return (
        <img
            width={32}
            height={24}
            onClick={onClick}
            src={isActive ? activeSortIcon : inactiveSortIcon}
            alt="sort icon"
            className={`${s.sortIcon} ${isActive && currentSort.includes('-') && s.rotation}`}
        />
    );
}
export default SortIcon;
