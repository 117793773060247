import React from "react";
import { Link } from "react-router-dom";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import s from "../appCatalog.module.scss";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ButtonTip from "../../UiKit/ButtonTip/ButtonTip";
import MuiTooltip from "../../UiKit/MuiTooltip/MuiTooltip";

const Card = ({
  id,
  image,
  name,
  app_store_url,
  blocked_geo,
  navigate,
  app_type,
  app_age,
  icon,
  pageWidth,
}) => {
  return (
    <div className={s.card}>
      <div className={s.cardHeader}>
        <img src={icon} alt="icon" className={s.iconImage} />
        <div className={s.titleWrapper}>
          <a className={s.title} id={name}>
            {pageWidth <= 1000 && <ButtonTip content={name} id={name} />}
            {name || ""}
          </a>

          <div className={s.settings}>
            <a href={app_store_url} target="_blank" className={s.followLink}>
              <MuiTooltip title="Открыть в App Store">
                <GlobalSvgSelector id="follow-link-icon" />
              </MuiTooltip>
            </a>
            <Link
              className={s.settingsBtn}
              // onMouseDown={(e) =>
              //   handleMiddleMouseClick(e, `/my-flows/create-flow`, navigate)
              // }
              // onClick={() => navigate("/my-flows/create-flow")}
              to="/my-flows/create-flow"
            >
              <MuiTooltip title="Создать поток">
                <GlobalSvgSelector id="add-icon" />
              </MuiTooltip>
            </Link>
            <Link
              className={s.settingsBtn}
              id={`myFlowsTip${id}`}
              to={name ? `/my-flows?applications=${name}` : "/app-catalog"}
            >
              <MuiTooltip title="Мои потоки">
                <GlobalSvgSelector id="burger-menu-icon" />
              </MuiTooltip>
            </Link>
          </div>
        </div>
      </div>
      <div className={s.cardContent}>
        <img src={image || ""} alt="application picture" />
      </div>
      <div className={s.cardFooter}>
        <div className={s.techInfo}>
          <div className={s.size}>
            {/*<span className={s.pipe}>{app_size}</span>*/}
            <span>{app_age}</span>
          </div>
          <div className={s.appName}>
            <GlobalSvgSelector id="gamepad-icon" />
            <span className={s.app}>{app_type}</span>
          </div>
        </div>
        <div className={s.countries}>
          <div className={s.wifiIcon}>
            <GlobalSvgSelector id="no-wifi-icon" />
          </div>
          <div className={s.countriesList}>
            {blocked_geo?.split(", ").map((item) => (
              <span key={item}>{item}</span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
