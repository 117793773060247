import React, { useState } from "react";
import ConfirmPopup from "../UiKit/Popups/ConfirmPopup/ConfirmPopup";
import s from "./pixelApi.module.scss";
import { handleContinue } from "../../utils/helpers/scrollTop";
import GlobalSvgSelector from "../GlobalSvgSelector/GlobalSvgSelector";
import { Link, useNavigate } from "react-router-dom";
import MuiTooltip from "../UiKit/MuiTooltip/MuiTooltip";
import { getIsStaff } from "../../storage/storage";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  checkAllTokens,
  switchSupportHandle,
} from "../../redux/slices/pixelApiSlice";

const PixelApiTr = ({
  id,
  pixel_id,
  token,
  onDeleteHandler,
  status,
  offer,
  email,
  is_support_handled,
}) => {
  const [active, setActive] = useState(false);
  const [isSupportHandled, setSupportHandle] = useState(is_support_handled);
  const dispatch = useDispatch();

  const switchHandleSupportHandler = async (e) => {
    const dataObj = { id, is_support_handled: e.target.checked };
    const switchDetails = await dispatch(switchSupportHandle(dataObj));

    if (switchDetails?.payload?.status === 200) {
      setSupportHandle((prev) => !prev);
    }
  };

  return (
    <>
      <td>{pixel_id}</td>
      <td className={s.token}>
        <div className={s.tokenInner}>{token}</div>
      </td>
      {getIsStaff() && <td>{offer}</td>}
      <td>
        {status ? (
          <span className={s.trueStatus}>Активный</span>
        ) : (
          <span className={s.falseStatus}>Неактивный</span>
        )}
      </td>
      {getIsStaff() && (
        <>
          <td>{email}</td>
          <td>
            <FormControlLabel
              style={{
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              control={
                <Checkbox
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                  checked={isSupportHandled}
                  onChange={switchHandleSupportHandler}
                  color="primary"
                />
              }
              sx={{ color: "white" }}
            />
          </td>
        </>
      )}
      <td>
        <ConfirmPopup
          active={active}
          setActive={setActive}
          action={async () => {
            await onDeleteHandler(id);
            dispatch(checkAllTokens());
          }}
          confirmName="Удаление"
          confirmBtnText="Удалить"
        />
        <div className={s.actionsWrapper}>
          <div className={s.actions}>
            <Link to={`/pixel-api/edit/${id}`} className={s.editBtn}>
              <MuiTooltip title="Редактировать Pixel API">
                <GlobalSvgSelector id="edit-icon" />
              </MuiTooltip>
            </Link>
            <button className={s.removeBtn} onClick={() => setActive(!active)}>
              <MuiTooltip title="Удалить Pixel API">
                <GlobalSvgSelector id="remove-icon" />
              </MuiTooltip>
            </button>
          </div>
        </div>
      </td>
    </>
  );
};

export default PixelApiTr;
