import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { colorList } from "../../../utils/vars/staticVariables";

const DoughnutChart = ({ list }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  const dataValue = list.map((item) => item.percentage);

  useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    if (chartRef.current) {
      chartInstanceRef.current = new Chart(chartRef.current, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [...dataValue],
              backgroundColor: [...colorList],
              borderWidth: 0,
              borderRadius: 8,
            },
          ],
        },
        options: {
          cutout: "80%",
        },
      });
    }
  }, [list]);

  return (
    <div style={{ width: 140 }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default DoughnutChart;
