import React, { useEffect, useMemo, useState } from "react";
import s from "../../../../../AllRedirectors/allRedirectors.module.scss";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  editRedirector,
  fetchRedirectorById,
  setEditRedirectorObj,
} from "../../../../../../redux/slices/redirectorSlice";
import { fetchAppList } from "../../../../../../redux/slices/applicationSlice";
import CustomSkeleton from "../../../../../CustomSkeleton/CustomSkeleton";
import EditApplicationItem from "../../../../../AllRedirectors/EditRedirector/EditApplicationItem";
import { setSelectedRedirectors } from "../../../../../../redux/slices/flowSlice";
import { getUser } from "../../../../../../storage/storage";
import RedButton from "../../../../../UiKit/Buttons/RedButton/RedButton";
import {
  handleContinue,
  scrollToBottom,
} from "../../../../../../utils/helpers/scrollTop";
import BlueButton from "../../../../../UiKit/Buttons/BlueButton/BlueButton";
import BaseTextInput from "../../../../../BaseTextInput/BaseTextInput";

const EditRedirect = ({ setContent }) => {
  const [applicationPercentages, setApplicationPercentages] = useState([]);
  const [editedApplicationPercentages, setEditedApplicationPercentages] =
    useState([]);

  const dispatch = useDispatch();

  const { appListLoading, appList } = useSelector((state) => state.application);
  const { editRedirectorObj, redirectorLoading, editRedirectorLoading } =
    useSelector((state) => state.redirector);

  const { currentEditRedirectorId, selectedRedirectors } = useSelector(
    (state) => state.flow,
  );

  const { user_id } = getUser();

  const updateRedirectorData = (originalArray, updatesArray) => {
    return originalArray.map((item) => {
      let update = updatesArray.find(
        (update) => update.application === item.application.id,
      );
      if (update) {
        return { ...item, percentage: update.percentage };
      }
      return item;
    });
  };

  const hasNullApplication = applicationPercentages.some(
    (item) => item.application === null,
  );

  const sendDataObj = {
    sub7: editRedirectorObj?.sub7,
    user_id: user_id,
    application_percentages: applicationPercentages,
  };

  const onSaveHandler = () => {
    if (hasNullApplication) {
      toast.error("Заполните приложения");
      return;
    }

    // const oneMoreArr = applicationPercentages.map(item => objectToUpdate.application_percentages)
    //
    // console.log('objectToUpdate', objectToUpdate)
    //
    // // if (objectToUpdate) {
    // //             objectToUpdate.application_percentages = applicationPercentages
    // //         }
    //
    // const applicationPercentageToUpdate= objectToUpdate?.application_percentages.find
    //
    // console.log('MY APPLICATION PERC', applicationPercentages)
    // console.log('objectToUpdate with key', objectToUpdate.application_percentages)

    const onSuccessAction = (obj) => {
      try {
        let arrSelectedRedirectorsCopy = selectedRedirectors.filter(
          (redirector) => redirector.id !== currentEditRedirectorId,
        );
        // let objectToUpdate = arrSelectedRedirectorsCopy.find(obj => obj.id === currentEditRedirectorId);
        //
        // objectToUpdate = {...objectToUpdate};
        //
        // const newRedirectorsArr = arrSelectedRedirectorsCopy.filter(redirector => redirector.id !== currentEditRedirectorId);
        //
        // if (objectToUpdate.application_percentages) {
        //     let updatedApplicationPercentages = updateRedirectorData(objectToUpdate.application_percentages, applicationPercentages);
        //
        //     // Тепер ми можемо змінити властивість в копії об'єкта
        //     objectToUpdate.application_percentages = updatedApplicationPercentages;
        //     console.log('----------updatedApplicationPercentages', updatedApplicationPercentages);
        //
        //     newRedirectorsArr.unshift(objectToUpdate);
        //     dispatch(setSelectedRedirectors([...newRedirectorsArr]))
        // }

        arrSelectedRedirectorsCopy.unshift(obj);
        dispatch(setSelectedRedirectors(arrSelectedRedirectorsCopy));

        toast.success("Редирект был успешно отредактирован");
        setContent("myRedirects");
      } catch (error) {
        throw error;
      }
    };

    const onErrorAction = (text) => {
      toast.error(text);
    };

    dispatch(
      editRedirector({
        id: currentEditRedirectorId,
        updatedData: sendDataObj,
        onSuccessAction,
        onErrorAction,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchAppList());
    dispatch(fetchRedirectorById(currentEditRedirectorId));
  }, []);

  useEffect(() => {
    // setEditRedirectorObj()
    if (editRedirectorObj && editRedirectorObj.application_percentages) {
      setEditedApplicationPercentages([]);
      setApplicationPercentages([]);

      editRedirectorObj?.application_percentages.map(
        ({ percentage, application }) =>
          setEditedApplicationPercentages((prev) => [
            ...prev,
            {
              percentage: percentage,
              appName: application.name,
              appId: application.id,
            },
          ]),
      );
      editRedirectorObj?.application_percentages.map(
        ({ percentage, application }) =>
          setApplicationPercentages((prev) => [
            ...prev,
            {
              percentage: percentage,
              application: application.id,
            },
          ]),
      );
    }

    return () => {
      setEditedApplicationPercentages([]);
      setApplicationPercentages([]);
      setEditRedirectorObj();
    };
  }, [editRedirectorObj]);

  const addNextAddition = () => {
    if (applicationPercentages.length < 100) {
      setApplicationPercentages((prevState) => [
        ...prevState,
        { application: null, percentage: 0 },
      ]);
    }
    if (editedApplicationPercentages.length < 100) {
      setEditedApplicationPercentages((prevState) => [
        ...prevState,
        { appId: null, appName: null, percentage: 0 },
      ]);
    }
  };

  // debugger

  // const appListOptions = appList.map(({ name, id }) => ({
  //   value: id,
  //   label: name,
  // }));

  const appListOptions = useMemo(() => {
    return appList.map((item) => ({
      label: item.name,
      value: item.id,
      is_alive: item.is_alive,
      name: "app_list",
    }));
  }, [appList]);

  return (
    <div
      className={s.editRedirectorsWrapper}
      // style={{ padding: 0, borderRadius: "0px 0px 8px 8px" }}
    >
      {/*<div className={s.redirectorsHeader}>*/}
      {/*    <h3>Редактировать редирект</h3>*/}
      {/*</div>*/}

      <div className={s.container}>
        {redirectorLoading || appListLoading ? (
          <div
            className={s.editWrapper}
            // style={{
            //     overflow: 'hidden',
            //     padding: "20px 40px",
            //     marginTop: 0,
            //     borderRadius: "0px 0px 8px" + " 8px",
            // }}
          >
            <CustomSkeleton styles={s.editRedirectinFlowSkeleton} />
          </div>
        ) : (
          <div
            className={s.editWrapper}
            // style={{
            //     padding: "20px 40px",
            //     marginTop: 0,
            //     borderRadius: "0px 0px 8px" + " 8px",
            // }}
          >
            {/*<span className={s.sub7}>Sub 7</span>*/}
            {/*<div className={s.sub7InputName}>*/}
            {/*    <input*/}
            {/*        type="text"*/}
            {/*        className={s.textInput}*/}
            {/*        value={editRedirectorObj?.sub7}*/}
            {/*        readOnly*/}
            {/*    />*/}
            {/*</div>*/}

            <div className={s.sub7InputWrapper}>
              <h5 className={s.subTitle}>Sub 7</h5>
              <BaseTextInput
                // action={(e) => setSub7(e.target.value)}
                value={editRedirectorObj?.sub7}
                placeholder="Sub7"
              />
            </div>

            <h5 className={s.subtitleBorderBottom}>Приложения</h5>

            <div className={s.additionWrapper}>
              <div className={s.additionContent}>
                {editedApplicationPercentages?.map((obj, index) => (
                  <div key={index}>
                    <EditApplicationItem
                      app={obj}
                      index={index}
                      options={appListOptions}
                      // options={appList}
                      editRedirectorObj={editRedirectorObj}
                      setEditedApplicationPercentages={
                        setEditedApplicationPercentages
                      }
                      setApplicationPercentages={setApplicationPercentages}
                    />
                  </div>
                ))}
              </div>

              {/*<div className={s.addNext}>*/}
              {/*    <button*/}
              {/*        className={s.addNextBtn}*/}
              {/*        disabled={applicationPercentages.length === 100}*/}
              {/*        onClick={addNextAddition}*/}
              {/*    >*/}
              {/*        Добавить следующий*/}
              {/*    </button>*/}
              {/*</div>*/}

              <div className={s.addNext}>
                <BlueButton
                  action={() => {
                    addNextAddition();
                    scrollToBottom();
                  }}
                  pending={applicationPercentages.length === 100}
                  text="Добавить"
                  width={120}
                />
              </div>
            </div>
          </div>
        )}

        <div className={s.buttonsWrapper}>
          <RedButton
            width={120}
            text="Вернуться"
            action={() => {
              setContent("myRedirects");
              handleContinue();
            }}
          />
          <BlueButton
            width={120}
            text="Сохранить"
            pending={editRedirectorLoading || redirectorLoading}
            action={onSaveHandler}
          />
        </div>

        {/*<div className={s.buttonsWrapper}>*/}
        {/*    <button*/}
        {/*        className={s.cancelBtn}*/}
        {/*        onClick={() => setContent('myRedirects')}*/}
        {/*    >*/}
        {/*        Вернуться*/}
        {/*    </button>*/}
        {/*    <button*/}
        {/*        className={s.nextStepBtn}*/}
        {/*        onClick={onSaveHandler}*/}
        {/*        disabled={editRedirectorLoading || redirectorLoading}*/}
        {/*    >*/}
        {/*        {redirectorLoading || editRedirectorLoading*/}
        {/*            ? "Загрузка..."*/}
        {/*            : "Сохранить"}*/}
        {/*    </button>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default EditRedirect;
