import React, { useState } from "react";
import s from "./flows.module.scss";
import GlobalSvgSelector from "../GlobalSvgSelector/GlobalSvgSelector";
import { Link, useNavigate } from "react-router-dom";
import SetNotePopup from "../UiKit/Popups/SetNotePopup/SetNotePopup";
import { useDispatch } from "react-redux";
import {
  createNote,
  duplicateFlow,
  testFlow,
  updateNote,
} from "../../redux/slices/flowSlice";
import { toast } from "react-hot-toast";
import ConfirmPopup from "../UiKit/Popups/ConfirmPopup/ConfirmPopup";
import GetLinksPopup from "../UiKit/Popups/GetLinksPopup/GetLinksPopup";
import MuiTooltip from "../UiKit/MuiTooltip/MuiTooltip";
import TestFlowPopup from "../UiKit/Popups/TestFlowPopup/TestFlowPopup";

const FlowItem = ({
  item,
  getFlows,
  onDeleteHandler,
  duplicateFlowPending,
  isPartner,
}) => {
  const [isActiveCreateNote, setActiveCreateNote] = useState(false);
  const [isActiveEditNote, setActiveEditNote] = useState(false);
  const [activeRemovePopup, setActiveRemovePopup] = useState(false);
  const [copyLinksPopupIsActive, setCopyLinksPopup] = useState(false);
  const [activeCreateFlowPopup, setActiveCreateFlowPopup] = useState(false);
  // const [activeTestFlowPopup, setActiveTestFlowPopup] = useState(false);
  // const [activeOffTestFlowPopup, setActiveOffTestFlowPopup] = useState(false);

  const [activeFlowCreateLimitPopup, setActiveFlowCreateLimitPopup] =
    useState(false);

  const [noteText, setNoteText] = useState("");

  const dispatch = useDispatch();

  const disabledDuplicate = !item?.settings;

  const onDuplicateFlowHandler = async () => {
    const toastId = toast.loading("Выполняется дублирование потока");
    const onSuccessAction = () => {
      toast.success("Поток был успешно дублирован", {
        id: toastId,
      });
      getFlows();
    };
    const onErrorAction = (text) => {
      toast.error(text, {
        id: toastId,
        style: {
          minWidth: "470px",
        },
      });
    };
    const dismiss = () => {
      toast.dismiss(toastId);
    };

    const setLimitPopup = () => {
      setActiveFlowCreateLimitPopup(true);
    };

    const requestData = { flow_id: item.id };

    await dispatch(
      duplicateFlow({
        requestData,
        onSuccessAction,
        onErrorAction,
        setLimitPopup,
        dismiss,
      }),
    );
  };

  const setNoteAction = (data) => {
    const onSuccessAction = () => {
      setActiveEditNote(false);
      toast.success("Заметка была добавлена");
      getFlows();
    };

    const onErrorAction = () => {
      setActiveEditNote(false);
      toast.error("Не удалось добавить заметку");
      getFlows();
    };

    dispatch(
      createNote({ payload: data, onSuccessAction, onErrorAction, toast }),
    );
  };

  const updateNoteAction = (data) => {
    const onSuccessAction = () => {
      setActiveCreateNote(false);
      toast.success("Заметка была отредактирована");
      getFlows();
    };

    const onErrorAction = () => {
      setActiveCreateNote(false);
      toast.error("Не удалось отредактировать заметку");
      getFlows();
    };

    dispatch(updateNote({ payload: data, onSuccessAction, onErrorAction }));
  };

  const onTestFlow = () => {
    const data = {
      flow_id: item?.id,
      mode: true,
    };

    const toastId = toast.loading("Обработка запроса тестирования");

    const onSuccessAction = () => {
      setActiveCreateNote(false);
      toast.success("Режим тестирования запущен на 10 минут", {
        id: toastId,
        style: {
          minWidth: "380px",
        },
      });
      getFlows();
    };

    const onErrorAction = (text) => {
      setActiveCreateNote(false);
      // toast.error("Не удалось запустить режим тестирования");
      toast.error(text, {
        id: toastId,
        style: {
          minWidth: "500px",
        },
      });
    };

    dispatch(testFlow({ payload: data, onSuccessAction, onErrorAction }));
  };

  const offTestFlow = () => {
    const data = {
      flow_id: item?.id,
      mode: false,
    };

    const toastId = toast.loading("Обработка запроса выключения тестирования", {
      style: {
        minWidth: "430px",
      },
    });

    const onSuccessAction = () => {
      setActiveCreateNote(false);
      toast.success("Режим тестирования отключен", {
        id: toastId,
        style: {
          minWidth: "380px",
        },
      });
      getFlows();
    };

    const onErrorAction = (text) => {
      setActiveCreateNote(false);
      // toast.error("Не удалось запустить режим тестирования");
      toast.error(text, {
        id: toastId,
        style: {
          minWidth: "500px",
        },
      });
    };

    dispatch(testFlow({ payload: data, onSuccessAction, onErrorAction }));
  };

  const { allowed_ips = [] } = item?.settings || {};

  return (
    <>
      <td>
        <ConfirmPopup
          active={activeFlowCreateLimitPopup}
          setActive={setActiveFlowCreateLimitPopup}
          action={() => setActiveFlowCreateLimitPopup(false)}
          confirmBtnText="Продолжить"
          confirmName="Дублирование потока"
          content="Вы достигли дневного лимита по количеству новых потоков"
          support
          alert
        />
        {/*<TestFlowPopup*/}
        {/*  active={activeTestFlowPopup}*/}
        {/*  setActive={setActiveTestFlowPopup}*/}
        {/*  action={() => onTestFlow()}*/}
        {/*  confirmBtnText="Запустить"*/}
        {/*  confirmName="Тестирование потока"*/}
        {/*  content="Режим тестирования - полностью отключает клоаку на 10 минут, после чего возвращается в обычный статус фильтрации"*/}
        {/*  allowedIps={allowed_ips}*/}
        {/*/>*/}
        {/*<ConfirmPopup*/}
        {/*  active={activeOffTestFlowPopup}*/}
        {/*  setActive={setActiveOffTestFlowPopup}*/}
        {/*  action={() => offTestFlow()}*/}
        {/*  confirmBtnText="Выключить"*/}
        {/*  confirmName="Тестирование потока"*/}
        {/*  content="Выключить режим тестирования потока?"*/}
        {/*/>*/}

        <ConfirmPopup
          active={activeCreateFlowPopup}
          setActive={setActiveCreateFlowPopup}
          action={() => onDuplicateFlowHandler()}
          confirmBtnText="Продолжить"
          confirmName="Дублирование потока"
          content={
            !isPartner &&
            "При дублировании потока с вашего баланса спишется $6 за домен"
          }
        />
        <ConfirmPopup
          active={activeRemovePopup}
          setActive={setActiveRemovePopup}
          action={() => onDeleteHandler(item.id)}
          confirmBtnText="Удалить"
          confirmName="Удаление потока"
          content="Не удаляйте поток, если у вас есть активный траффик по нему, иначе
              пользователи не попадут на оффер"
        />
        <SetNotePopup
          title="Добавить заметку"
          active={isActiveCreateNote}
          setActive={setActiveCreateNote}
          flowId={item?.id}
          notes={item?.notes}
          action={setNoteAction}
          noteText={noteText}
          setNoteText={setNoteText}
        />
        <SetNotePopup
          title="Редактировать заметку"
          active={isActiveEditNote}
          setActive={setActiveEditNote}
          flowId={item?.id}
          notes={item?.notes}
          action={updateNoteAction}
          noteText={noteText}
          setNoteText={setNoteText}
        />

        <GetLinksPopup
          redirectors={item?.redirector}
          active={copyLinksPopupIsActive}
          domain={item?.domain?.address}
          setActive={setCopyLinksPopup}
        />
        {item.notes ? (
          <div
            className={s.editNoteField}
            onClick={() => setActiveEditNote(true)}
          >
            <span className={s.noteText}>{item.notes}</span>
            <button className={s.editNoteBtn}>
              <GlobalSvgSelector id="edit-icon" />
            </button>
          </div>
        ) : (
          <button
            className={s.addNote}
            onClick={() => {
              setActiveCreateNote(true);
            }}
          >
            Добавить <GlobalSvgSelector id="add-note-icon" />
          </button>
        )}
      </td>
      <td>{item?.domain?.address || "-"}</td>
      <td>
        {item?.redirector?.length > 0
          ? Array.from(
              new Set(
                item?.redirector.flatMap((item) =>
                  item.application_percentages.map(
                    (item) => item.application.name,
                  ),
                ),
              ),
            ).join(", ")
          : "-"}
      </td>
      <td className={s.actionsTd}>
        <div className={s.actionsWrapper}>
          <div className={s.actions}>
            {/*<button*/}
            {/*  className={`${s.testFlowBtn} ${item?.settings?.status === "Money" && s.testing}`}*/}
            {/*  onClick={() => {*/}
            {/*    if (item?.settings?.status === "Money") {*/}
            {/*      setActiveOffTestFlowPopup(true);*/}
            {/*    } else {*/}
            {/*      setActiveTestFlowPopup(true);*/}
            {/*    }*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <MuiTooltip title="Режим тестирования">*/}
            {/*    <GlobalSvgSelector id="flow-test-icon" />*/}
            {/*  </MuiTooltip>*/}
            {/*</button>*/}

            <button
              className={s.getLinkBtn}
              onClick={() => setCopyLinksPopup(true)}
            >
              <MuiTooltip title="Получить ссылки">
                <GlobalSvgSelector id="copy-link-icon" />
              </MuiTooltip>
            </button>

            <Link
              to={`/stats/clicks?domain=${item?.domain?.address}`}
              className={s.statisticsBtn}
            >
              <MuiTooltip title="Перейти на статистику по кликам">
                <GlobalSvgSelector id="statistics-blue-icon" />
              </MuiTooltip>
            </Link>
            <Link to={`/my-flows/edit/${item.id}`} className={s.editBtn}>
              <MuiTooltip title="Редактировать поток">
                <GlobalSvgSelector id="edit-icon" />
              </MuiTooltip>
            </Link>

            {/*<button*/}
            {/*  className={s.editBtn}*/}
            {/*  onMouseDown={(e) =>*/}
            {/*    handleMiddleMouseClick(e, `/my-flows/edit/${item.id}`, navigate)*/}
            {/*  }*/}
            {/*  // onClick={() => {*/}
            {/*  //     navigate(`/my-flows/edit/${item.id}`)*/}
            {/*  // }}*/}
            {/*>*/}
            {/*  <MuiTooltip title="Редактировать поток">*/}
            {/*    <GlobalSvgSelector id="edit-icon" />*/}
            {/*  </MuiTooltip>*/}
            {/*</button>*/}

            <button
              className={s.copyBtn}
              onClick={() => setActiveCreateFlowPopup(true)}
              // disabled={duplicateFlowPending}
              disabled={disabledDuplicate || duplicateFlowPending}
            >
              <MuiTooltip
                title={
                  disabledDuplicate
                    ? "Настройте White Page"
                    : duplicateFlowPending
                      ? "Поток дублируется"
                      : "Дублировать поток"
                }
                disabled={disabledDuplicate}
              >
                <GlobalSvgSelector id="copy-icon" />
              </MuiTooltip>
            </button>

            <button
              className={s.removeBtn}
              onClick={() => setActiveRemovePopup(true)}
            >
              <MuiTooltip title="Удалить поток">
                <GlobalSvgSelector id="remove-icon" />
              </MuiTooltip>
            </button>
          </div>
        </div>
      </td>
    </>
  );
};

export default FlowItem;
