import React, { useEffect, useMemo, useRef, useState } from "react";
import s from "../../../../../AllRedirectors/allRedirectors.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppList } from "../../../../../../redux/slices/applicationSlice";
import ApplicationItem from "../../../../../AllRedirectors/ApplicationItem/ApplicationItem";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  handleContinue,
  scrollToBottom,
} from "../../../../../../utils/helpers/scrollTop";
import { createRedirector } from "../../../../../../redux/slices/redirectorSlice";
import { getUser } from "../../../../../../storage/storage";
import BaseTextInput from "../../../../../BaseTextInput/BaseTextInput";
import { setOfferLink } from "../../../../../../redux/slices/flowSlice";
import BlueButton from "../../../../../UiKit/Buttons/BlueButton/BlueButton";
import RedButton from "../../../../../UiKit/Buttons/RedButton/RedButton";
import { scrollToElement } from "../MyRedirects/MyRedirects";

const CreateNewRedirect = ({ setContent }) => {
  const [applicationPercentages, setApplicationPercentages] = useState([
    { application: null, percentage: 0 },
  ]);

  const buttonsRef = useRef(null);

  const [sub7, setSub7] = useState("");

  const dispatch = useDispatch();

  const { appList, createRedirectorStepLoading } = useSelector(
    (state) => state.application,
  );

  const { user_id } = getUser();

  useEffect(() => {
    dispatch(fetchAppList());
  }, [dispatch]);

  const sendDataObj = {
    sub7: sub7.trim(),
    user_id: user_id,
    application_percentages: applicationPercentages,
  };

  const addNextAddition = () => {
    if (applicationPercentages.length < 100) {
      setApplicationPercentages((prevState) => [
        ...prevState,
        { application: null, percentage: 0 },
      ]);
    }
  };

  useEffect(() => {
    dispatch(fetchAppList());
  }, [dispatch]);

  const navigate = useNavigate();

  const containsNonSpaceCharacter = (str) => {
    return /\S/.test(str);
  };
  const hasNullApplication = applicationPercentages.some(
    (item) => item.application === null,
  );

  const hasNullPercentage = applicationPercentages.some(
    (item) => item.percentage === null,
  );

  const validateString = (s) => {
    const regex = /^[A-Za-z0-9]+$/;

    return regex.test(s.trim());
  };

  const onCreateRedirect = async () => {
    if (!containsNonSpaceCharacter(sub7)) {
      toast.error("Заполните поле sub7");
      return;
    }

    if (!validateString(sub7)) {
      toast.error("Некорректное название для sub7");
      return;
    }

    if (hasNullApplication) {
      toast.error("Заполните приложения");
      return;
    }

    if (hasNullPercentage) {
      toast.error("Общая сумма процентов не равна 100");
      return;
    }

    const successAction = () => {
      toast.success("Новый редирект успешно создан");
      setContent("myRedirects");
    };

    const onErrorAction = (text) => {
      toast.error(text);
    };

    dispatch(
      createRedirector({ successAction, data: sendDataObj, onErrorAction }),
    );
  };

  // const appListOptions = appList.map(({ name, id }) => ({
  //   value: id,
  //   label: name,
  // }));

  const appListOptions = useMemo(() => {
    return appList.map((item) => ({
      label: item.name,
      value: item.id,
      is_alive: item.is_alive,
      name: "app_list",
    }));
  }, [appList]);

  return (
    <div
      className={s.editRedirectorsWrapper}
      // style={{ padding: 0, borderRadius: "0px 0px 8px 8px" }}
    >
      <div
        className={s.editWrapper}
        // style={{
        //   padding: "20px 40px",
        //   marginTop: 0,
        //   borderRadius: "0px 0px 8px" + " 8px",
        // }}
      >
        <div className={s.sub7InputWrapper}>
          <h5 className={s.subTitle}>Sub 7</h5>
          <BaseTextInput
            action={(e) => setSub7(e.target.value)}
            value={sub7}
            placeholder="Введите Sub7"
          />
        </div>

        {/*<span className={s.sub7}>Sub 7</span>*/}
        {/*<div className={s.sub7InputName}>*/}
        {/*  <input*/}
        {/*      type="text"*/}
        {/*      placeholder="Введите Sub7"*/}
        {/*      className={s.textInput}*/}
        {/*      value={sub7}*/}
        {/*      onChange={(e) => setSub7(e.target.value)}*/}
        {/*  />*/}
        {/*</div>*/}

        <h5 className={s.subtitleBorderBottom}>Приложения</h5>

        <div className={s.additionWrapper}>
          <div className={s.additionContent}>
            {applicationPercentages.map((obj, index) => (
              <div key={index}>
                <ApplicationItem
                  index={index}
                  options={appListOptions}
                  setApplicationPercentages={setApplicationPercentages}
                />
              </div>
            ))}
          </div>
          <div className={s.addNext}>
            <BlueButton
              action={() => {
                addNextAddition();
                scrollToBottom();
                // scrollToElement(buttonsRef)
              }}
              pending={applicationPercentages.length === 100}
              text="Добавить"
              width={120}
            />
          </div>
        </div>
      </div>

      <div className={s.buttonsWrapper} ref={buttonsRef}>
        <RedButton
          width={120}
          text="Вернуться"
          action={() => {
            setContent("myRedirects");
            handleContinue();
          }}
        />
        <BlueButton
          width={120}
          text="Создать"
          pending={createRedirectorStepLoading}
          action={onCreateRedirect}
        />
      </div>

      {/*<div className={s.buttonsWrapper}>*/}
      {/*  <button*/}
      {/*      className={s.cancelBtn}*/}
      {/*      onClick={() => {*/}
      {/*        setContent('myRedirects')*/}
      {/*        handleContinue();*/}
      {/*      }}*/}
      {/*  >*/}
      {/*    Отмена*/}
      {/*  </button>*/}
      {/*  <button*/}
      {/*      className={s.nextStepBtn}*/}
      {/*      onClick={onCreateRedirect}*/}
      {/*      disabled={createRedirectorStepLoading}*/}
      {/*  >*/}
      {/*    {createRedirectorStepLoading ? "Загрузка" : "Сохранить"}*/}
      {/*  </button>*/}
      {/*</div>*/}
    </div>
  );
};

export default CreateNewRedirect;
