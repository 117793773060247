import React, { useEffect } from "react";
import s from "./sidebar.module.scss";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { handleContinue } from "../../../utils/helpers/scrollTop";
import {
  menuList,
  sidebarList,
  statisticsList,
  supportList,
} from "../../../utils/vars/staticVariables";
import { motion, useAnimation } from "framer-motion";
import ButtonTip from "../../UiKit/ButtonTip/ButtonTip";
import MuiTooltip from "../../UiKit/MuiTooltip/MuiTooltip";
import {
  checkAllTokens,
  fetchPixelApi,
} from "../../../redux/slices/pixelApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { getIsStaff, getUser } from "../../../storage/storage";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { pixelApiList, isValidPixelApiTokens } = useSelector(
    (state) => state.pixelApi,
  );

  // console.log("sidebar pixelApiList", pixelApiList);
  // console.log(
  //   "sidebar pixelApiList filter",
  //   pixelApiList.filter((item) => item?.status === false),
  // );

  const falseStatus = pixelApiList.some((item) => item?.status === false);

  const listItemVariants = {
    hidden: { opacity: 0 },
    visible: (custom) => ({
      opacity: 1,
      transition: { delay: custom * 0.1 },
    }),
  };

  useEffect(() => {
    // dispatch(fetchPixelApi({ page: 1, search: "", page_size: 1000 }));
    dispatch(checkAllTokens());
  }, [dispatch]);

  return (
    <div className={s.sidebarWrapper}>
      <div className={s.sidebar}>
        {sidebarList.slice(0, 1).map(({ title, pages }, index) => (
          <div className={s.chapterWrapper} key={index}>
            <h3>{title}</h3>
            {/*{*/}
            {/*  iconId: "pixel-api-icon",*/}
            {/*  text: "Pixel API",*/}
            {/*  path: "/pixel-api",*/}
            {/*},*/}
            {/*<div className={s.menu}>*/}
            {/*  <div className={s.menuBtnWrapper}>*/}
            {/*    <Link*/}
            {/*      className={`${s.link} ${*/}
            {/*        location.pathname.startsWith("/pixel-api") ? s.active : ""*/}
            {/*      }`}*/}
            {/*    >*/}
            {/*      <MuiTooltip title="Pixel API">*/}
            {/*        <GlobalSvgSelector id="pixel-api-icon" />*/}
            {/*      </MuiTooltip>*/}

            {/*      <span className={s.pageTitle}>Pixel API</span>*/}
            {/*    </Link>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className={s.menu}>
              {pages.slice(0, 4).map(({ path, text, iconId }, index) => (
                <motion.div
                  key={index}
                  variants={listItemVariants}
                  initial="hidden"
                  animate="visible"
                  custom={index}
                  className={s.menuBtnWrapper}
                >
                  <Link
                    to={path}
                    className={`${s.link} ${
                      location.pathname.startsWith(path) ? s.active : ""
                    }`}
                  >
                    <MuiTooltip title={text}>
                      <GlobalSvgSelector id={iconId} />
                    </MuiTooltip>
                    <span className={s.pageTitle}>{text}</span>
                  </Link>
                </motion.div>
              ))}
              <motion.div
                // key={4}
                variants={listItemVariants}
                initial="hidden"
                animate="visible"
                custom={4}
                className={s.pixelApiWrapper}
              >
                {/*<div className={s.pixelApiWrapper}>*/}
                <Link
                  className={`${s.link} ${
                    location.pathname.startsWith("/pixel-api") ? s.active : ""
                  }`}
                  to="/pixel-api"
                >
                  <MuiTooltip title="Pixel API">
                    <GlobalSvgSelector id="pixel-api-icon" />
                  </MuiTooltip>

                  <span className={s.pageTitle}>Pixel API</span>
                  {!isValidPixelApiTokens && (
                    <div className={s.alertContainer}>
                      <GlobalSvgSelector id="alert-circle-icon" />
                    </div>
                  )}
                </Link>
              </motion.div>

              {/*</div>*/}
              {pages.slice(4).map(({ path, text, iconId }, index) => (
                <motion.div
                  key={index}
                  variants={listItemVariants}
                  initial="hidden"
                  animate="visible"
                  custom={index + 1}
                  className={s.menuBtnWrapper}
                >
                  <Link
                    to={path}
                    className={`${s.link} ${
                      location.pathname.startsWith(path) ? s.active : ""
                    }`}
                  >
                    <MuiTooltip title={text}>
                      <GlobalSvgSelector id={iconId} />
                    </MuiTooltip>
                    <span className={s.pageTitle}>{text}</span>
                  </Link>
                </motion.div>
              ))}
            </div>
          </div>
        ))}

        {sidebarList.slice(1).map(({ title, pages }, index) => (
          <div className={s.chapterWrapper} key={index}>
            <h3>{title}</h3>
            <div className={s.menu}>
              {pages.map(({ path, text, iconId }, index) => (
                <motion.div
                  key={index}
                  variants={listItemVariants}
                  initial="hidden"
                  animate="visible"
                  custom={index}
                  className={s.menuBtnWrapper}
                >
                  <Link
                    to={path}
                    className={`${s.link} ${
                      location.pathname.startsWith(path) ? s.active : ""
                    }`}
                  >
                    <MuiTooltip title={text}>
                      <GlobalSvgSelector id={iconId} />
                    </MuiTooltip>
                    <span className={s.pageTitle}>{text}</span>
                  </Link>
                </motion.div>
              ))}
            </div>
          </div>
        ))}

        <motion.a
          variants={listItemVariants}
          initial="hidden"
          animate="visible"
          custom={2}
          href="https://t.me/yaroslava_support_wwa"
          target="_blank"
          className={s.redirectLink}
          style={{ padding: 7 }}
        >
          <div className={s.iconContainer}>
            <MuiTooltip title="Пуш уведомления">
              <GlobalSvgSelector id="message-icon" />
            </MuiTooltip>
          </div>

          <span className={s.pageTitle}>Пуш уведомления</span>
        </motion.a>
        <motion.a
          style={{ padding: 7 }}
          variants={listItemVariants}
          initial="hidden"
          animate="visible"
          custom={3}
          href="https://t.me/yaroslava_support_wwa"
          target="_blank"
          className={s.redirectLink}
        >
          <div className={s.iconContainer}>
            <MuiTooltip title="Поддержка">
              <GlobalSvgSelector id="support-icon" />
            </MuiTooltip>
          </div>
          <span className={s.pageTitle}>Поддержка</span>
        </motion.a>
      </div>
    </div>
  );
};

export default Sidebar;
