import React from "react";
import s from "../transactions.module.scss";
import { useSelector } from "react-redux";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import CustomSkeleton from "../../../CustomSkeleton/CustomSkeleton";
import EmptyList from "../../../EmptyList/EmptyList";
import { convertDate } from "../../../../utils/helpers/formatTime";

const RefillOption = () => {
  const { transactionsLoading, transactionsList, totalCount } =
    useSelector(financeSelector);

  return (
    <div className={s.tableWrapper}>
      {transactionsLoading ? (
        <CustomSkeleton styles={s.skeletonTable} />
      ) : (
        <div className={s.table}>
          {totalCount === 0 && !transactionsLoading ? (
            <EmptyList />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Дата</th>
                  <th>Сумма</th>
                  <th>Hash Транзакции</th>
                </tr>
              </thead>
              <tbody>
                {transactionsList.map(
                  ({ id, created_at, amount, crypto_currency_transaction }) => (
                    <tr key={id}>
                      <td>
                        <span>{convertDate(created_at, "DMY")}</span>{" "}
                        <span>{convertDate(created_at, "HM")}</span>
                      </td>
                      <td>$ {amount}</td>
                      <td>
                        {crypto_currency_transaction?.txid ? (
                          <a
                            target="_blank"
                            href={`https://tronscan.org/#/transaction/${crypto_currency_transaction?.txid}`}
                            className={s.hashContent}
                          >
                            {crypto_currency_transaction?.txid}
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default RefillOption;
