import React from "react";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import {documentationList, lessonsList} from "../../../utils/vars/documentation";
import s from "./documentation.module.scss";

const Documentation = () => {
  return (
      <div className={s.documentationWrapper}>

        <div className={s.pageHeader}>
          <h5 className={s.pageTitle}>Документация</h5>
        </div>


        <div className={s.documentation}>
          {/*<h3>Документация</h3>*/}
          <ul className={s.documentationLinkList}>
            {documentationList.map((item, index) => (
                <li key={index}>
                  <a href={item.link} target="_blank">
                    <GlobalSvgSelector id={item.icon}/>
                    <span>{item.name}</span>
                  </a>
                </li>
            ))}
          </ul>
          {/*<h3 style={{marginTop: 15}}>Видеоуроки</h3>*/}

          <h5 className={s.videoLessons}>Видеоуроки</h5>

          <ul className={s.documentationLinkList}>
            {lessonsList.map((item, index) => (
                <li key={index}>
                  <a href={item.link} target="_blank">
                    <GlobalSvgSelector id={item.icon}/>
                    <span>{item.name}</span>
                  </a>
                </li>
            ))}
          </ul>
        </div>
      </div>
  );
};

export default Documentation;