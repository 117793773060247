import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {createTheme, ThemeProvider} from "@mui/material";


const theme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    // paddingTop: 0,
                    cursor: 'default',
                    marginTop: 0,
                    backgroundColor: '#202936',
                    color: 'white',
                    fontSize: 14,
                    letterSpacing: 0.5,
                    fontWeight: 400
                }
            }
        }
    }
});

const MuiTooltip = ({title, children, disabled}) => {

    const popperProps = {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, -10], // Змініть це значення, щоб підняти тултип вверх
                },
            },
        ],
    };

    return (
        <ThemeProvider theme={theme}>
            <Tooltip
                title={title}
                PopperProps={popperProps}
                style={disabled ? {cursor: 'not-allowed'} : null}
            >
                <IconButton>
                    {children}
                </IconButton>
            </Tooltip>
        </ThemeProvider>
    );
};

export default MuiTooltip;
