import React, { useEffect, useMemo, useState } from "react";
import s from "./commonStats.module.scss";
import Pagination from "../../Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCommonStats,
  resetTotalCount,
  statisticsSelector,
} from "../../../redux/slices/statisticsSlice";
import CustomSkeleton from "../../CustomSkeleton/CustomSkeleton";
import { applicationSelector } from "../../../redux/slices/applicationSlice";
import { globalDataSelector } from "../../../redux/slices/globalDataSlice";
import { redirectorSelector } from "../../../redux/slices/redirectorSlice";
import { useSearchParams } from "react-router-dom";
import MultiSelect from "../../MultiSelect/MultiSelect";
import EmptyList from "../../EmptyList/EmptyList";
import {
  commonClicksStatsOrderingList,
  params,
  sortDateOptions,
} from "../../../utils/vars/params";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import {
  useSearchParamsOptions,
  useSelectOptions,
} from "../../../utils/helpers/useSelectHelpers";
import {
  application_name,
  created_at_after,
  created_at_before,
  domain,
  geo,
  onSortDateHandler,
  page,
  sort,
  sub7,
} from "../ClicksStats/ClicksStats";
import { fetchClickStatsFilters } from "../../../api/helpers/fetchFilters";
import { getDefaultParams } from "../../../api/helpers/scripts";
import { INVALID_PAGE_ERROR } from "../../../utils/vars/staticVariables";
import { MuiDatePicker } from "../../Blocks/MuiDatePicker/MuiDatePicker";
import {
  formatTimestampToServerDate,
  stringToDayJs,
} from "../../../utils/helpers/formatTime";
import ParamsSort from "../../Blocks/ParamsSort/ParamsSort";

export const updateOrdering = (field, setOrdering) => {
  setOrdering((prevOrdering) =>
    prevOrdering === field
      ? `-${field}`
      : prevOrdering === `-${field}`
        ? field
        : field,
  );
};

const CommonStats = () => {
  const [sortDate, setSortDate] = useState(sortDateOptions[0]);
  const [selectedGeos, setSelectedGeos] = useState([]);

  const dispatch = useDispatch();

  const { redirectorsList } = useSelector(redirectorSelector);
  const { domainsList, geoList } = useSelector(globalDataSelector);
  const { appList } = useSelector(applicationSelector);

  const filteredAppList = useMemo(() => {
    return appList.filter((item) => item?.is_alive === true);
  }, [appList]);

  const {
    statisticsList,
    loading,
    totalCount,
    totalInstalls,
    totalLeads,
    totalSales,
    totalClicks,
    uniqueClicks,
  } = useSelector(statisticsSelector);

  console.log("filteredAppList", filteredAppList);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    sort: sortValue,
    page: pageValue,
    created_at_after: createdAtAfterParam,
    created_at_before: createdAtBeforeParam,
  } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const [selectedDomains, selectedSub7, selectedApplications] =
    useSearchParamsOptions(searchParams, [domain, sub7, application_name]);

  const domainsListOptions = useSelectOptions(
    domainsList,
    "address",
    "address",
  );
  const sub7ListOptions = useSelectOptions(redirectorsList, "sub7", "sub7");
  const geoListOptions = useSelectOptions(geoList, "name", "code");
  // const appListOptions = useSelectOptions(filteredAppList, "name", "name");

  const appListOptions = useMemo(() => {
    return appList.map((item) => ({
      label: item.name,
      value: item.name,
      is_alive: item.is_alive,
      name: "app_list",
    }));
  }, [appList]);

  useEffect(() => {
    fetchClickStatsFilters(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (
      createdAtAfterParam ||
      createdAtBeforeParam ||
      sortDate.value === "custom_date"
    ) {
      setSortDate(sortDateOptions.find((item) => item.value === "custom_date"));
    } else {
      if (!sortValue) {
        setSortDate(sortDateOptions[0]);
      } else {
        setSortDate(sortDateOptions.find(({ value }) => value === sortValue));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    const newGeoListOptions = searchParams
      .get(geo)
      ?.split(",")
      .filter((value) => value !== "")
      .map((value) => {
        return geoListOptions.find((item) => item.value === value);
      });

    setSelectedGeos(newGeoListOptions);
  }, [searchParams, geoListOptions]);

  useEffect(() => {
    const promise = dispatch(
      fetchCommonStats(
        getDefaultParams({
          searchParams,
          sort,
          defaultPageSize: 30,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return () => {
      promise.abort();
      // dispatch(resetTotalCount());
    };
  }, [dispatch, searchParams]);

  useEffect(() => {
    return () => dispatch(resetTotalCount());
  }, [dispatch]);

  return (
    <div className={s.clickStatisticsWrapper}>
      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Общая статистика</h3>
      </div>

      <div className={s.filtersWrapper}>
        <h5 className={s.filtersTitle}>Фильтрация</h5>
        <div className={s.filters}>
          {
            <div className={s.selectDateWrapper}>
              <MultiSelect
                isClearable={false}
                options={sortDateOptions}
                setSelectedOptions={(value) =>
                  onSortDateHandler(value, updateSearchParams, setSortDate)
                }
                value={sortDate}
                placeholder="Дата"
                isMulti={false}
              />
            </div>
          }

          {sortDate?.value === "custom_date" && (
            <div className={s.datePickersWrapper}>
              <div className={s.datePicker}>
                <MuiDatePicker
                  label="Дата от"
                  value={stringToDayJs(createdAtAfterParam)}
                  onChange={(e) =>
                    updateSearchParams(
                      created_at_after,
                      formatTimestampToServerDate(e?.toString()),
                    )
                  }
                />
              </div>
              <div className={s.datePicker}>
                <MuiDatePicker
                  label="Дата до"
                  value={stringToDayJs(createdAtBeforeParam)}
                  onChange={(e) => {
                    updateSearchParams(
                      created_at_before,
                      formatTimestampToServerDate(e?.toString()),
                    );
                  }}
                />
              </div>
            </div>
          )}

          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedSub7}
              setSelectedOptions={(values) =>
                updateSearchParams(sub7, values.map((el) => el.value).join(","))
              }
              options={sub7ListOptions}
              placeholder="Sub7"
              isMulti={true}
            />
          </div>

          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedDomains}
              options={domainsListOptions}
              setSelectedOptions={(values) =>
                updateSearchParams(
                  domain,
                  values.map((el) => el.value).join(","),
                )
              }
              placeholder="Домен"
              isMulti={true}
            />
          </div>

          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedGeos}
              options={geoListOptions}
              setSelectedOptions={(values) =>
                updateSearchParams(geo, values.map((el) => el.value).join(","))
              }
              placeholder="Гео"
              isMulti={true}
            />
          </div>

          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedApplications}
              options={appListOptions}
              setSelectedOptions={(values) =>
                updateSearchParams(
                  application_name,
                  values.map((el) => el.value).join(","),
                )
              }
              placeholder="Приложение"
              isMulti={true}
            />
          </div>
        </div>
      </div>

      <div className={s.searchBar}>
        <div className={s.recordsCount}>
          Всего: {!loading && <span>{totalCount}</span>}
        </div>
      </div>

      {totalCount === 0 && !loading ? (
        <EmptyList />
      ) : (
        <>
          <table className={`${s.table} ${s.commonStatsTable}`}>
            <thead>
              <tr>
                {commonClicksStatsOrderingList.map(({ ordering, name }) => (
                  <th key={name}>
                    {name}
                    <ParamsSort
                      sortBy={ordering}
                      searchParams={searchParams}
                      setSearchParams={setSearchParams}
                    />
                  </th>
                ))}
              </tr>
            </thead>

            {loading ? (
              <tbody>
                <CustomSkeleton styles={s.skeletonTable} />
              </tbody>
            ) : (
              <>
                <tbody className={s.tableContent}>
                  {statisticsList?.map(
                    (
                      {
                        domain,
                        geo,
                        app,
                        sub7,
                        total_installs,
                        total_leads,
                        total_sales,
                        total_clicks,
                        unique_clicks,
                      },
                      index,
                    ) => {
                      return (
                        <tr key={index}>
                          <td>{geo}</td>
                          <td>{app?.name}</td>
                          <td>{domain}</td>
                          <td>{sub7}</td>
                          <td>{total_clicks}</td>
                          <td>{unique_clicks}</td>
                          <td>{total_installs}</td>
                          <td>{total_leads}</td>
                          <td>{total_sales}</td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
                <tbody className={s.footer}>
                  <tr className={s.footerTr}>
                    <td className={s.total}>Всего</td>
                    <td className={s.total}></td>
                    <td className={s.total}></td>
                    <td className={s.total}></td>
                    <td className={s.total}>{totalClicks}</td>
                    <td className={s.total}>{uniqueClicks}</td>
                    <td className={s.total}>{totalInstalls}</td>
                    <td className={s.total}>{totalLeads}</td>
                    <td className={s.total}>{totalSales}</td>
                  </tr>
                </tbody>
              </>
            )}
          </table>
          {totalCount > 30 && (
            <div className={s.paginationWrapper}>
              <Pagination
                currentPage={parseInt(pageValue) || 1}
                setCurrentPage={(page) => {
                  updateSearchParams(params.page, page);
                }}
                total={totalCount}
                limit={30}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CommonStats;
