import React from 'react';
import s from './upPage.module.scss'
import GlobalSvgSelector from "../GlobalSvgSelector/GlobalSvgSelector";

const UpPage = () => {

    const smoothScroll = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <button className={s.upPageContainer} onClick={smoothScroll}>
                <GlobalSvgSelector id='arrow-top'/>
        </button>
    )
}

export default UpPage;