import React, {useEffect} from "react";
import s from "./percentageBtn.module.scss";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";

const PercentageBtn = ({percentageValue, setPercentageValue }) => {

  function handleKeyDown(event) {
    const forbiddenKeys = ["e", ".", "-"];

    if (forbiddenKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  // useEffect(() => {
  //   if (percentageValue === null) {
  //     setPercentageValue(null)
  //   }
  // },[percentageValue])

  function handleInputChange(event) {
    const inputValue =
      event.target.value === "" ? "" : parseInt(event.target.value, 10);


    const checkZero = inputValue.toString();

    // if (checkZero === "0" && checkZero.length > 0) {
    //   dispatch(setPercentageValue(""));
    //   return;
    // }

    if (inputValue == 0) {
      setPercentageValue(0)
    }

    const isValidInput = /^[1-9]\d?$|^100$/.test(inputValue);

    if (isValidInput) {
      setPercentageValue(inputValue)
    }



    if (inputValue === "") {
      setPercentageValue(null)
      // dispatch(setPercentageValue(null));
    }
  }


  return (
    <div className={s.percentageWrapper}>
      {/*<h6>Процент</h6>*/}
      <h5 className={s.subTitle}>Процент</h5>

      <div className={s.percentage}>
        <button
          className={s.PercentageBtn}
          // onClick={() => setPercentageValue(prev => prev - 1)}
          onClick={() => {
            // Перевіряємо, чи значення відсотка менше або дорівнює 1
            if (percentageValue <= 1) {
              setPercentageValue(null);
            } else {
              // Якщо значення більше за 1, зменшуємо його на 1
              setPercentageValue(prev => prev - 1);
            }
          }}

          // onClick={() => {
          //   console.log('onClick percentageValue', percentageValue)
          //   if (percentageValue == 1) {
          //     setPercentageValue(null)
          //   } else {
          //     setPercentageValue(prev => prev - 1)
          //   }
          // }}
        >
          <GlobalSvgSelector id="dec-icon" />
        </button>
        <div className={s.percentageValueWrapper}>
          <input
            className={s.percentageValue}
            type="number"
            placeholder="Введите значение"
            onChange={handleInputChange}
            // value={percentageValue}
            value={percentageValue === null ? '' : percentageValue}
            onKeyDown={handleKeyDown}
          />
          <span className={s.percentSign}>%</span>
        </div>
        <button
          className={s.PercentageBtn}
          // onClick={() => setPercentageValue(prev => prev + 1)}
          onClick={() => {
            // Перевіряємо, чи значення відсотка менше або дорівнює 1
            if (percentageValue < 100) {
              setPercentageValue(prev => prev + 1);
            }
            // else {
              // Якщо значення більше за 1, зменшуємо його на 1
              // setPercentageValue(prev => prev - 1);
            // }
          }}

        >
          <GlobalSvgSelector id={"inc-icon"} />
        </button>
      </div>
    </div>
  );
};

export default PercentageBtn;