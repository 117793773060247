import { jwtDecode } from "jwt-decode";

export const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const getUser = () => {
  const token = getAccessToken();

  if (typeof token === "string") {
    // Переконуємося, що токен є рядком
    return jwtDecode(token);
  }
  return null;
};

export const removeTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const getIsStaff = () => {
  return getUser()?.user?.is_staff;
};

export const getIsAllowedStatisticsAccess = () => {
  return getUser()?.user?.allow_access_to_statistics;
};
