import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";

export const fetchRedirectorsList = createAsyncThunk(
  "redirector/fetchRedirectorList",
  async ({ sub7 }) => {
    try {
      let url = "/redirectors/?page_size=1000";

      if (sub7) {
        url += `&sub7=${sub7}`;
      }

      const response = await $api.get(url);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

// export const fetchFlowBySub7 = createAsyncThunk(
//     "redirector/fetchFlowBySub7",
//     async ({sub7}) => {
//       console.log('i am before try')
//
//
//       try {
//
//         console.log('i am in try')
//
//         const response = await $api.get(`/configuration/flows?sub7=${sub7}`);
//
//         console.log('AFTER RESPONSE')
//
//         console.log('fetchFlowBySub7   ID', response.data.results[0].id)
//
//         return response.data;
//       } catch (error) {
//         throw error;
//       }
//     },
// );

export const fetchRedirectorsWithParams = createAsyncThunk(
  "redirector/fetchRedirectorsWithParams",
  async ({ domains, applications, search }) => {
    try {
      const response = await $api.get(
        `/redirectors/?domain_address=${domains}&application_name=${applications}&search=${search}&page_size=1000`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchRedirectorById = createAsyncThunk(
  "redirector/fetchRedirectorById",
  async (id) => {
    try {
      const response = await $api.get(`/redirectors/${id}/`);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const editRedirector = createAsyncThunk(
  "redirector/editRedirector",
  async ({ id, updatedData, onSuccessAction, onErrorAction }) => {
    try {
      const response = await $api.put(`/redirectors/${id}/`, updatedData);

      onSuccessAction(response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error.response.data.details;
      const status = error?.response?.status;

      if (status >= 400 && status < 500) {
        onErrorAction(errorMessage);
        throw new Error(errorMessage);
      } else {
        throw error;
      }
    }
  },
);

export const createRedirector = createAsyncThunk(
  "redirector/createRedirector",
  async ({ successAction, data, onErrorAction }) => {
    try {
      const response = await $api.post("/redirectors/", data);
      successAction();
      // setPopupActive(true)
      return response.data;
    } catch (error) {
      // const errorMessage = error.response.data.detail;
      const errorMessage = error.response.data.details;
      const status = error?.response?.status;

      if (status >= 400 && status < 500) {
        onErrorAction(errorMessage);
        throw new Error(errorMessage);
      } else {
        throw error;
      }

      // if (errorMessage === 'Вы выбрали одинаковые приложения') {
      //   onErrorAction("Вы выбрали одинаковые приложения")
      //   throw new Error(errorMessage);
      // }
      // if (errorMessage === 'Такой sub7 уже существует') {
      //   onErrorAction("Редирект с таким sub7 уже существует")
      //   throw new Error(errorMessage);
      // }
      // if (errorMessage === 'Для такого юзера уже существует редиректор с таким sub7') {
      //   onErrorAction("Редирект с таким sub7 уже существует")
      //   throw new Error(errorMessage);
      // }
      // if (errorMessage === 'Сумма процентов не равна 100') {
      //   onErrorAction("Общая сумма процентов не равна 100")
      //   throw new Error(errorMessage);
      // } else {
      //   throw error
      // }
    }
  },
);

export const deleteRedirector = createAsyncThunk(
  "redirector/deleteRedirector",
  async (id) => {
    try {
      const response = await $api.delete(`/redirectors/${id}/`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const redirectorSlice = createSlice({
  name: "redirector",
  initialState: {
    redirectorsList: [],
    redirectorListLoading: false,

    error: null,
    redirectorLoading: false,

    popupActive: false,

    editRedirectorObj: null,
    editRedirectorLoading: false,

    totalCount: 0,

    flowBySub7Id: null,
  },
  reducers: {
    setEditRedirectorObj: (state) => {
      state.editRedirectorObj = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRedirectorsList.pending, (state) => {
        state.redirectorListLoading = true;
      })
      .addCase(fetchRedirectorsList.fulfilled, (state, action) => {
        state.redirectorsList = action.payload.results;
        state.redirectorListLoading = false;
        state.popupActive = true;
      })
      .addCase(fetchRedirectorsList.rejected, (state, action) => {
        state.redirectorListLoading = false;
      })

      .addCase(fetchRedirectorsWithParams.pending, (state) => {
        state.redirectorListLoading = true;
      })
      .addCase(fetchRedirectorsWithParams.fulfilled, (state, action) => {
        state.totalCount = action.payload.count;
        state.redirectorsList = action.payload.results;
        state.redirectorListLoading = false;
        state.popupActive = true;
      })
      .addCase(fetchRedirectorsWithParams.rejected, (state, action) => {
        state.redirectorListLoading = false;
      })

      /////////
      // .addCase(editRedirector.pending, (state) => {
      //     state.editRedirectorLoading = true;
      // })
      // .addCase(editRedirector.fulfilled, (state) => {
      //     // state.redirectorList = action.payload.results.reverse();
      //     state.editRedirectorLoading = false;
      // })
      // .addCase(editRedirector.rejected, (state, action) => {
      //     state.editRedirectorLoading = false;
      // })

      .addCase(createRedirector.pending, (state) => {
        state.redirectorListLoading = true;
      })
      .addCase(createRedirector.fulfilled, (state, action) => {
        // state.redirectorList = action.payload
        state.redirectorListLoading = false;
      })
      .addCase(createRedirector.rejected, (state, action) => {
        state.redirectorListLoading = false;
        // state.error = true
      })

      .addCase(fetchRedirectorById.pending, (state) => {
        state.redirectorLoading = true;
      })
      .addCase(fetchRedirectorById.fulfilled, (state, action) => {
        state.editRedirectorObj = action.payload;
        state.redirectorLoading = false;
      })
      .addCase(fetchRedirectorById.rejected, (state, action) => {
        state.redirectorLoading = false;
      });
  },
});

export const { setEditRedirectorObj } = redirectorSlice.actions;

export default redirectorSlice.reducer;

export const redirectorSelector = (state) => state.redirector;
