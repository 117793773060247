import {
  getIsAllowedStatisticsAccess,
  getIsStaff,
} from "../../storage/storage";

export const wordPressOptions = [
  { value: "music", label: "Музыка" },
  { value: "beauty", label: "Красота" },
  { value: "travel", label: "Путешествия" },
  { value: "food", label: "Рецепты" },
  { value: "science", label: "Наука" },
  { value: "gaming", label: "Игры" },
  { value: "energy", label: "Энергетика" },
  { value: "news", label: "Новости" },
  { value: "sport", label: "Спорт" },
  { value: "tech", label: "Технологии" },
  // { value: "world", label: "Мир" },
  { value: "finance", label: "Финансы" },
  { value: "politics", label: "Политика" },
  { value: "business", label: "Бизнес" },
  { value: "economics", label: "экономика" },
  { value: "entertainment", label: "Развлечения" },
];

export const prelandTypeList = [{ label: "wheel", name: "Колесо" }];

export const ABORTED_ERROR = "Aborted";
export const REJECTED_ERROR = "Rejected";
export const INVALID_PAGE_ERROR = "Invalid page.";
export const UNEXPECTED_ERROR = "An unexpected error occurred";

export const INVALID_PIXEL_API_TOKEN =
  "Invalid OAuth access token - Cannot parse access token";

export const VALID_TOKEN = "Токен валидный";

export const PURPOSE_REFILL = "refill";
export const PURPOSE_WRITE_OFF = "write_off";

export const menuList = [
  {
    iconId: "app-catalog-icon",
    text: "Каталог приложений",
    path: "/app-catalog",
  },
  {
    iconId: "my-flows-home-icon",
    text: "Мои потоки",
    path: "/my-flows",
  },
  {
    iconId: "all-redirectors-icon",
    text: "Все редиректы",
    path: "/all-redirectors",
  },
  {
    iconId: "pixel-api-icon",
    text: "Pixel API",
    path: "/pixel-api",
  },
  {
    iconId: "balance-icon",
    text: "Транзакции",
    path: "/transactions",
  },
  getIsStaff()
    ? {
        iconId: "wallet-icon",
        text: "Балансы",
        path: "/balances",
      }
    : null,
  {
    iconId: "save-icon",
    text: "Прелендинги",
    path: "/prelandings",
  },
].filter((item) => item !== null);

export const faqText =
  "В случае бана одного или нескольких приложений, на которые вы заливаете траффик, система" +
  " автоматически заменит их на рабочие.\n" +
  "С помощью этой таблицы вы можете выбрать, в каком порядке будет происходить замена.";

export const sidebarList = [
  {
    title: "Меню",
    pages: [
      {
        iconId: "app-catalog-icon",
        text: "Каталог приложений",
        path: "/app-catalog",
      },
      {
        iconId: "my-flows-home-icon",
        text: "Мои потоки",
        path: "/my-flows",
      },
      {
        iconId: "all-redirectors-icon",
        text: "Все редиректы",
        path: "/all-redirectors",
      },
      // {
      //   iconId: "app-priority-icon",
      //   text: "Приоритет приложений",
      //   path: "/app-priority",
      // },
      // {
      //   iconId: "pixel-api-icon",
      //   text: "Pixel API",
      //   path: "/pixel-api",
      // },
      {
        iconId: "balance-icon",
        text: "Транзакции",
        path: "/transactions",
      },
      getIsStaff()
        ? {
            iconId: "wallet-icon",
            text: "Балансы",
            path: "/balances",
          }
        : null,
      {
        iconId: "save-icon",
        text: "Прелендинги",
        path: "/prelandings",
      },
    ].filter((item) => item !== null),
  },
  {
    title: "Статистика",
    pages: [
      {
        iconId: "statistics-icon",
        text: "Общая статистика",
        path: "/stats/common",
      },
      {
        iconId: "mouse-icon",
        text: "Статистика по кликам",
        path: "/stats/clicks",
      },
      {
        iconId: "reports-icon",
        text: "Статистика по клоаке",
        path: "/stats/cloaca",
      },
      getIsStaff() || getIsAllowedStatisticsAccess()
        ? {
            iconId: "admin-icon",
            text: "GEO + APP",
            path: "/stats/geo+app",
          }
        : null,
      getIsStaff() || getIsAllowedStatisticsAccess()
        ? {
            iconId: "user-table-icon",
            text: "Статистика по пользователям",
            path: "/stats/users",
          }
        : null,
    ].filter((item) => item !== null),
  },
  {
    title: "Другое",
    pages: [
      {
        iconId: "documentation-icon",
        text: "Документация",
        path: "/documentation",
      },
    ],
  },
].filter((item) => item !== null);

export const supportList = [
  {
    iconId: "documentation-icon",
    text: "Документация",
    path: "/documentation",
  },
  // {
  //     iconId: "support-icon",
  //     text: "Поддержка",
  //     path: "/support",
  // }
];

export const statisticsList = [
  {
    iconId: "statistics-icon",
    text: "Общая статистика",
    path: "/stats/common",
  },
  {
    iconId: "mouse-icon",
    text: "Статистика по кликам",
    path: "/stats/clicks",
  },
  {
    iconId: "reports-icon",
    text: "Статистика по клоаке",
    path: "/stats/cloaca",
  },
  getIsStaff()
    ? {
        iconId: "admin-icon",
        text: "GEO + APP",
        path: "/stats/geo+app",
      }
    : null,
  getIsStaff()
    ? {
        iconId: "user-table-icon",
        text: "Статистика по пользователям",
        path: "/stats/users",
      }
    : null,
].filter((item) => item !== null);

export const colorList = [
  "#5d87ff",
  "#8FF89A",
  "#FF5DC8",
  "#D98B60",
  "#FF3A3A",
  "#BCBCBC",
  "#958c65",
  "#b2dad7",
  "#2056a4",
  "#e7fdc7",
  "#369e6d",
  "#fa4696",
  "#b16969",
  "#2389b9",
  "#f70b2e",
  "#21d8c9",
  "#2703eb",
  "#d61616",
  "#86f463",
  "#1749aa",
  "#7ee056",
  "#7e54c9",
  "#21d434",
  "#9e45d2",
  "#8993b9",
  "#167755",
  "#f82dbd",
  "#469085",
  "#640187",
  "#4cf1b4",
  "#b07d54",
  "#266533",
  "#403dd8",
  "#548912",
  "#4622e8",
  "#88642c",
  "#23b2b5",
  "#3298d1",
  "#9c4be0",
  "#796e13",
  "#36dfb5",
  "#efab40",
  "#b3285c",
  "#415241",
  "#c72ce9",
  "#4c771d",
  "#ccdc79",
  "#23f789",
  "#ba1e05",
  "#dc6ce1",
  "#9ad9a3",
  "#357657",
  "#6f6cbc",
  "#6e190b",
  "#1aa022",
  "#247aca",
  "#569b1e",
  "#c3198c",
  "#f68e75",
  "#6adaac",
  "#5a633e",
  "#faf200",
  "#e0f6e7",
  "#2acdb4",
  "#940f60",
  "#e5249e",
  "#94fe5e",
  "#e51686",
  "#530ee3",
  "#bac3c1",
  "#7a79d9",
  "#6e9946",
  "#ee14a9",
  "#57ea7d",
  "#3f7e04",
  "#7f2169",
  "#91d98d",
  "#e9f6af",
  "#908bd5",
  "#780385",
  "#f3d3bd",
  "#f4f234",
  "#427ed0",
  "#2056a4",
  "#e6eb04",
  "#f0d4b1",
  "#81490a",
  "#50f1cb",
  "#59e523",
  "#f4a6be",
  "#f3a517",
];
