import React, { useEffect, useRef, useState } from "react";
import s from "../prelandings.module.scss";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import wheelImg from "../../../assets/img/speenWheel.png";
import { useDispatch, useSelector } from "react-redux";
import AppSettingsDropdown from "../../Flows/EditFlowSettings/AppSettingsDropdown/AppSettingsDropdown";
import {
  setPopupButtonText,
  setPopupText,
  setPopupTitle,
  setPrelandingName,
  setPrelandTitle,
  setPrelandTypeLabel,
  setPrelandTypeName,
  setRespinButtonText,
  setSpinButtonText,
} from "../../../redux/slices/flowSlice";
import { prelandTypeList } from "../../../utils/vars/staticVariables";
import SpinWheel from "../SpinWheel/SpinWheel";

import {
  createPrelanding,
  customPrelandingUpload,
  fetchWheelStylesList,
} from "../../../redux/slices/prelandingSlice";
import { toast } from "react-hot-toast";
import { handleContinue } from "../../../utils/helpers/scrollTop";
import { useNavigate } from "react-router-dom";
import Loader from "../../UiKit/Loader/Loader";
import BlueButton from "../../UiKit/Buttons/BlueButton/BlueButton";
import BaseTextInput from "../../BaseTextInput/BaseTextInput";
import LimitedInput from "../../LimitedInput/LimitedInput";
import RedButton from "../../UiKit/Buttons/RedButton/RedButton";
import { validateNameString } from "../../Flows/EditFlowSettings/Steps/PrelandingStep/OwnPreland/OwnPreland";

const CreatePrelanding = () => {
  const [selectedImage, setSelectedImage] = useState("");
  const [spinCount, setSpinCount] = useState(1);

  const [prelandingName, setPrelandingName] = useState("");

  const [prelandTitle, setPrelandTitle] = useState("Welcome");
  const [spinButtonText, setSpinButtonText] = useState("Spin");
  const [respinButtonText, setRespinButtonText] = useState("Spin");

  const [popupTitle, setPopupTitle] = useState("OK");
  const [popupText, setPopupText] = useState("You get {bonus}");
  const [popupButtonText, setPopupButtonText] = useState("Get");

  const [styleId, setStyleId] = useState(1);

  const [prelandingStatus, setPrelandingStatus] = useState(true);

  const [wheelNumbers, setWheelNumbers] = useState(() =>
    new Array(8)
      .fill()
      .map((_, index) => ({
        name: `Сектор ${index}`,
        value: "",
        is_win: index <= 0,
      })),
  );

  const [winSector, setWinSector] = useState(0);

  const nameInputRef = useRef(null);
  const prelandTitleRef = useRef(null);
  const spinButtonTextRef = useRef(null);
  const respinButtonTextRef = useRef(null);
  const popupTitleRef = useRef(null);
  const popupTextRef = useRef(null);
  const popupButtonTextRef = useRef(null);

  const fileInputRef = useRef(null);
  const logoRef = useRef(null);

  const dispatch = useDispatch();

  const { prelandTypeName } = useSelector((state) => state.flow);

  const { wheelStylesList, wheelStylesLoading, prelandPending } = useSelector(
    (state) => state.prelanding,
  );

  const [currentWheel, setCurrentWheel] = useState(
    wheelStylesList?.find((item) => item.id == styleId),
  );

  useEffect(() => {
    setCurrentWheel(wheelStylesList?.find((item) => item.id == styleId));
  }, [wheelStylesList, styleId]);

  const updateSectorValue = (index, newValue) => {
    setWheelNumbers((currentSectors) =>
      currentSectors.map((sector, sectorIndex) =>
        sectorIndex === index ? { ...sector, value: newValue } : sector,
      ),
    );
  };

  useEffect(() => {
    dispatch(fetchWheelStylesList());
  }, []);

  useEffect(() => {
    setStyleId(wheelStylesList[0]?.id);
  }, [wheelStylesList]);

  const sendDataObj = {
    status: prelandingStatus,
    name: prelandingName,
    prelanding_type: 1,
    header_text: prelandTitle,
    style: styleId,
    rotation_button_text: spinButtonText,
    respin_button_text: respinButtonText,
    num_of_revolutions: spinCount,
    sectors: wheelNumbers,
    // popup_win_text: popupTitle,
    popup_win_text: popupText,
    winning_button_text: popupButtonText,
    // logo: selectedImage,
    logo: null,
    bonus_button_text: popupTitle,
  };

  const validateAplhaNumeric = (value) => {
    const regex = /^[a-zA-Zа-яА-ЯіІїЇєЄґҐ0-9]+( [a-zA-Zа-яА-ЯіІїЇєЄґҐ0-9]+)*$/;
    return regex.test(value.trim());
  };

  const navigate = useNavigate();

  const scrollToElement = (ref) => {
    if (ref && ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 150; // Віднімаємо 150 пікселів

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const onCreatePrelanding = () => {
    // if (prelandingName.length < )

    if (prelandingName.trim().length < 1) {
      scrollToElement(nameInputRef);
      toast.error("Заполните имя прелендинга");
      return;
    }

    // if(selectedImage.length < 1) {
    //     toast.error('Выберите логотип')
    //     scrollToElement(logoRef)
    //     return
    // }

    if (prelandTitle.trim().length < 1) {
      scrollToElement(prelandTitleRef);
      toast.error("Заполните текст вращения");
      return;
    }

    if (spinButtonText.trim().length < 1) {
      scrollToElement(spinButtonTextRef);
      toast.error("Некоректный текст кнопки вращения");
      return;
    }

    if (respinButtonText.trim().length < 1) {
      scrollToElement(respinButtonTextRef);
      toast.error("Заполните текст кнопки повторного вращения", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    const hasEmptyString = wheelNumbers.some(
      (sector) => sector.value.trim() === "",
    );
    if (hasEmptyString) {
      toast.error("Заполните пустые значения секторов");
      return;
    }

    // wheelNumbers.forEach(sector => {
    //     if (!validateAplhaNumeric(sector.value) && sector.value.trim() !== "") {
    //         toast.error("Некоректные значения секторов")
    //     }
    // });

    if (popupTitle.trim().length < 1) {
      // scrollToElement(respinButtonTextRef);
      toast.error("Заполните заголовок попапа выигрыша", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    if (popupText.trim().length < 1) {
      // scrollToElement(respinButtonTextRef);
      toast.error("Заполните текст попапа выигрыша", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    if (popupButtonText.trim().length < 1) {
      // scrollToElement(respinButtonTextRef);
      toast.error("Заполните текст кнопки попапа выигрыша", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    const onSuccessAction = () => {
      toast.success("Прелендинг был успешно создан");
      navigate("/prelandings");
      handleContinue();
    };

    const onErrorAction = (text) => {
      toast.error(text);
    };

    dispatch(
      createPrelanding({ data: sendDataObj, onSuccessAction, onErrorAction }),
    );
  };

  const handleWinSectorChange = (selectedIndex) => {
    // Оновлюємо кожен об'єкт в масиві
    const updatedWheelNumbers = wheelNumbers.map((sector, index) => ({
      ...sector,
      is_win: index === selectedIndex, // Тільки обраний сектор отримає is_win: true
    }));

    // Оновлюємо стан з новим масивом
    setWheelNumbers(updatedWheelNumbers);
  };

  const [prelandingContent, setPrelandingContent] = useState("create");

  const [customPrelandingName, setCustomPrelandingName] = useState("");
  const [customPrelandingZipName, setCustomPrelandingZipName] =
    useState("Загрузить");
  const [customPrelandingFile, setCustomPrelandingFile] = useState(null);

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setCustomPrelandingFile(selectedFile);
      setCustomPrelandingZipName(selectedFile.name);
    }
  };

  const uploadCustomPreland = async () => {
    if (customPrelandingName.trim().length < 1) {
      toast.error("Заполните имя прелендинга");
      return;
    }

    if (!validateNameString(customPrelandingName)) {
      toast.error("Некорректное имя прелендинга");
      return;
    }

    if (!customPrelandingFile) {
      toast.error("Выберите .zip файл");
      return;
    }

    if (customPrelandingFile) {
      const formData = new FormData();

      formData.append("prelanding_file", customPrelandingFile);
      // formData.append('status', 'true');
      formData.append("prelanding_type", "4");
      // formData.append('flow_id', id.toString());
      formData.append("name", customPrelandingName);

      const onSuccessAction = () => {
        toast.success("Преленд был успешно загружен");
        navigate("/prelandings");
      };

      const onErrorAction = (text) => {
        toast.error(text, {
          style: {
            // minWidth: '400px',
          },
        });
      };

      dispatch(
        createPrelanding({ data: formData, onSuccessAction, onErrorAction }),
      );
      // dispatch(customPrelandingUpload({formData, onSuccessAction, onErrorAction}))
    }
  };

  return (
    <div className={s.prelandingStepWrapper}>
      <div className={s.pageHeader}>
        <h5 className={s.pageTitle}>Прелендинги</h5>
      </div>

      <div className={s.formWrapper}>
        {/*<h5 className={s.subTitle}>Создание прелендинга</h5>*/}

        <div className={s.selectPrelandButtons}>
          <button
            className={`${s.selectPreland} ${
              prelandingContent === "create" ? s.active : ""
            }`}
            onClick={() => setPrelandingContent("create")}
          >
            Создать
          </button>
          <button
            className={`${s.selectPreland} ${
              prelandingContent === "custom" ? s.active : ""
            }`}
            onClick={() => setPrelandingContent("custom")}
          >
            Загрузить
          </button>
        </div>

        {prelandingContent === "create" && (
          <div className={s.content}>
            <div className={s.createPrelandingForm}>
              <form action="">
                {/*<div className={s.checkBoxWrapper}>*/}
                {/*    <Checkbox  defaultChecked />*/}
                {/*</div>*/}
                <div className={s.statusWrapper}>
                  <h5 className={s.subtitleBorderBottom}>Преленд</h5>
                  <div className={s.statusCheckbox}>
                    <input
                      type="checkbox"
                      checked={prelandingStatus}
                      onChange={() => setPrelandingStatus((prev) => !prev)}
                    />
                    <span>Отображать</span>
                    {/*<label*/}
                    {/*    className={s.statusLabel}*/}
                    {/*    onClick={() => setPrelandingStatus(prev => !prev)}*/}
                    {/*>*/}
                    {/*    <input type="checkbox" checked={prelandingStatus}/>*/}
                    {/*    <span>Отображать</span>*/}
                    {/*</label>*/}
                  </div>
                </div>

                <div className={s.prelandNameContainer} ref={nameInputRef}>
                  {/*<h6>Название</h6>*/}
                  <h5 className={s.subTitle}>Название</h5>

                  <BaseTextInput
                    action={(e) => setPrelandingName(e.target.value)}
                    value={prelandingName}
                    placeholder="Введите текст"
                  />
                </div>

                <h5 className={s.subTitle}>Тип преленда</h5>
                <div className={s.typeWrapper}>
                  {/*<h6>Тип преленда</h6>*/}

                  <AppSettingsDropdown
                    desc="Выберите параметр"
                    selected={prelandTypeName}
                    setLabel={(item) => dispatch(setPrelandTypeLabel(item))}
                    setName={(item) => dispatch(setPrelandTypeName(item))}
                    options={prelandTypeList}
                  />
                </div>

                <h5 className={s.subtitleBorderBottom} ref={prelandTitleRef}>
                  Уникальные настройки
                </h5>
                <div className={s.uniqueSettings}>
                  <LimitedInput
                    maxLength={20}
                    value={prelandTitle}
                    action={(e) => setPrelandTitle(e.target.value)}
                    subtitle="Заголовок"
                  />

                  <div ref={spinButtonTextRef}>
                    <LimitedInput
                      maxLength={10}
                      value={spinButtonText}
                      action={(e) => setSpinButtonText(e.target.value)}
                      subtitle="Текст кнопки вращения"
                    />
                  </div>

                  <div ref={respinButtonTextRef}>
                    <LimitedInput
                      maxLength={10}
                      value={respinButtonText}
                      action={(e) => setRespinButtonText(e.target.value)}
                      subtitle="Текст кнопки повторного вращения"
                    />
                  </div>

                  <h5 className={s.subTitle}>Стиль колеса</h5>
                  <ul className={s.wheelCheckboxList}>
                    {wheelStylesList.map(({ id, wheel }, index) => (
                      <li
                        className={s.wheelStyleItem}
                        key={id}
                        onClick={() => setStyleId(id)}
                        // onClick={() => setCurrentWheel(wheelList[index])}
                      >
                        <label className={s.checkboxWrapper}>
                          <img
                            src={wheel}
                            alt="wheel image"
                            style={
                              id === styleId
                                ? {
                                    border: "4px solid #5D87FF",
                                    borderRadius: "50%",
                                  }
                                : null
                            }
                          />
                        </label>
                      </li>
                    ))}
                  </ul>
                  {/*<h6 className={s.spinCountTitle}>Количество оборотов</h6>*/}
                  {/*<div className={s.spinCountWrapper}>*/}
                  {/*    <label className={s.radioWrapper}>*/}
                  {/*        <input*/}
                  {/*            className={s.radio}*/}
                  {/*            type="radio"*/}
                  {/*            value={1}*/}
                  {/*            checked={spinCount === 1}*/}
                  {/*            onChange={e => setSpinCount(1)}*/}
                  {/*        />*/}
                  {/*        1 оборот*/}
                  {/*    </label>*/}
                  {/*    <label className={s.radioWrapper}>*/}
                  {/*        <input*/}
                  {/*            className={s.radio}*/}
                  {/*            type="radio"*/}
                  {/*            value={3}*/}
                  {/*            checked={spinCount === 3}*/}
                  {/*            onChange={e => setSpinCount(3)}*/}
                  {/*        />*/}
                  {/*        3 оборота*/}
                  {/*    </label>*/}
                  {/*</div>*/}
                  <div className={s.sectorsWrapper}>
                    <h5 className={s.subTitle}>
                      Значения секторов и сектор финального выигрыша
                    </h5>

                    <div className={s.sectorsContent}>
                      {wheelNumbers.map((sector, index) => (
                        <div className={s.sectorItem} key={index}>
                          <div className={s.sectorInputWrapper}>
                            <div className={s.sectorInputContainer}>
                              <input
                                type="text"
                                className={s.sectorInput}
                                value={sector.value}
                                onChange={(e) =>
                                  updateSectorValue(index, e.target.value)
                                }
                                placeholder="Введите текст"
                                maxLength={10}
                              />
                              {sector.value.length > 0 && (
                                <div className={s.lengthIndicator}>
                                  {sector.value.length}/10
                                </div>
                              )}

                              {/*<input*/}
                              {/*    type="text"*/}
                              {/*    className={s.textInput}*/}
                              {/*    value={sector.value}*/}
                              {/*    onChange={e => updateSectorValue(index, e.target.value)}*/}
                              {/*    placeholder="Введите текст"*/}
                              {/*    maxLength={10}*/}
                              {/*/>*/}
                              {/*{*/}
                              {/*    sector.value.length > 0 &&*/}
                              {/*    <div*/}
                              {/*        className={s.lengthIndicator}>{sector.value.length}/10</div>*/}
                              {/*}*/}
                            </div>
                            <label className={s.radioLabel}>
                              <input
                                className={s.radio}
                                type="radio"
                                value={index}
                                // checked={index === winSector}
                                checked={sector.is_win}
                                onChange={() => {
                                  handleWinSectorChange(index);
                                  setWinSector(index);
                                }}
                                // onChange={e => setWinSector(index)}
                              />
                              <span>WIN</span>
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div ref={popupTitleRef}>
                    <LimitedInput
                      maxLength={16}
                      value={popupTitle}
                      action={(e) => setPopupTitle(e.target.value)}
                      subtitle="Заголовок в попапе выигрыша"
                    />
                  </div>

                  {/*<div className={s.nameInputWrapper} ref={popupTitleRef}>*/}
                  {/*    <h6>Заголовок в попапе выигрыша</h6>*/}
                  {/*    <div className={s.inputWrapper}>*/}

                  {/*        <input*/}
                  {/*            type="text"*/}
                  {/*            className={s.textInput}*/}
                  {/*            placeholder="Введите текст"*/}
                  {/*            value={popupTitle}*/}
                  {/*            onChange={e => setPopupTitle(e.target.value)}*/}
                  {/*            maxLength={16}*/}
                  {/*        />*/}
                  {/*        {*/}
                  {/*            popupTitle.length > 0 &&*/}
                  {/*            <div className={s.lengthIndicator}>{popupTitle.length}/16</div>*/}
                  {/*        }*/}
                  {/*    </div>*/}

                  {/*</div>*/}

                  <LimitedInput
                    maxLength={30}
                    value={popupText}
                    action={(e) => setPopupText(e.target.value)}
                    subtitle="Текст в попапе выигрыша"
                  />

                  <h5 className={s.inputTip}>
                    Используйте {`{bonus}`} для того, чтобы подставить значение
                    выигрыша в тексте поздравления
                  </h5>

                  {/*<div className={s.nameInputWrapper}>*/}
                  {/*    <h6>Текст в попапе выигрыша</h6>*/}
                  {/*    <h6 className={s.greySubtitle}>*/}
                  {/*        «Используйте {`{bonus}`} для того чтобы подставить*/}
                  {/*        значение выигрыша в тексте поздравления»*/}
                  {/*    </h6>*/}
                  {/*    <div className={s.inputWrapper}>*/}
                  {/*        <input*/}
                  {/*            type="text"*/}
                  {/*            className={s.textInput}*/}
                  {/*            placeholder="Введите текст"*/}
                  {/*            value={popupText}*/}
                  {/*            onChange={e => setPopupText(e.target.value)}*/}
                  {/*            maxLength={30}*/}
                  {/*        />*/}
                  {/*        {*/}
                  {/*            popupText.length > 0 &&*/}
                  {/*            <div className={s.lengthIndicator}>{popupText.length}/30</div>*/}
                  {/*        }*/}
                  {/*    </div>*/}
                  {/*</div>*/}

                  <LimitedInput
                    maxLength={10}
                    value={popupButtonText}
                    action={(e) => setPopupButtonText(e.target.value)}
                    subtitle="Текст кнопки бонуса"
                  />

                  {/*<div className={s.nameInputWrapper}>*/}
                  {/*    <h6>Текст кнопки бонуса</h6>*/}
                  {/*    <div className={s.inputWrapper}>*/}

                  {/*        <input*/}
                  {/*            // bonusButtonText*/}
                  {/*            type="text"*/}
                  {/*            className={s.textInput}*/}
                  {/*            placeholder="Введите текст"*/}
                  {/*            value={popupButtonText}*/}
                  {/*            onChange={e => setPopupButtonText(e.target.value)}*/}
                  {/*            maxLength={10}*/}
                  {/*        />*/}
                  {/*        {*/}
                  {/*            popupButtonText.length > 0 &&*/}
                  {/*            <div className={s.lengthIndicator}>{popupButtonText.length}/10</div>*/}
                  {/*        }*/}
                  {/*    </div>*/}

                  {/*</div>*/}
                </div>
              </form>
            </div>

            <div className={s.previewWrapper}>
              <div className={s.previewContent}>
                <h6>Превью</h6>
                <SpinWheel
                  wheelNumbers={wheelNumbers.map((sector) => sector.value)}
                  prelandTitle={prelandTitle}
                  spinCount={spinCount}
                  spinButtonText={spinButtonText}
                  respinButtonText={respinButtonText}
                  winSector={winSector}
                  logo={selectedImage}
                  popupTitle={popupTitle}
                  currentWheel={currentWheel}
                  popupText={popupText}
                  popupButtonText={popupButtonText}
                />
              </div>
            </div>
          </div>
        )}

        {prelandingContent === "custom" && (
          <div className={s.customPrelandWrapper}>
            <BaseTextInput
              value={customPrelandingName}
              placeholder="Введите имя прелендинга"
              action={(e) => setCustomPrelandingName(e.target.value)}
            />

            <label htmlFor="upload-button" className={s.downloadPreland}>
              <GlobalSvgSelector id="download-icon" />
              <span>{customPrelandingZipName}</span>
            </label>
            <input
              id="upload-button"
              type="file"
              style={{ display: "none" }}
              onChange={onFileChange}
              accept=".zip"
            />
          </div>
        )}
      </div>

      {prelandingContent === "create" && (
        <div className={s.buttonsWrapper}>
          <RedButton
            width={120}
            text="Отменить"
            action={() => {
              navigate("/prelandings");
              handleContinue();
            }}
          />
          <BlueButton
            width={120}
            text="Создать"
            pending={prelandPending}
            action={onCreatePrelanding}
          />
        </div>
      )}

      {prelandingContent === "custom" && (
        <div className={s.buttonsWrapper}>
          <RedButton
            width={120}
            text="Отменить"
            action={() => {
              navigate("/prelandings");
              handleContinue();
            }}
          />
          <BlueButton
            width={120}
            text="Загрузить"
            pending={prelandPending}
            action={uploadCustomPreland}
          />
        </div>
      )}
    </div>
  );
};

export default CreatePrelanding;
