import React, { useEffect, useState } from "react";
import s from "../pixelApi.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkPixelTokenValid,
  editPixelApiItem,
  fetchPixelApiItem,
  postPixelApiItem,
} from "../../../redux/slices/pixelApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleContinue } from "../../../utils/helpers/scrollTop";
import { toast } from "react-hot-toast";
import CustomSkeleton from "../../CustomSkeleton/CustomSkeleton";
import { getUser } from "../../../storage/storage";
import BaseTextInput from "../../BaseTextInput/BaseTextInput";
import RedButton from "../../UiKit/Buttons/RedButton/RedButton";
import BlueButton from "../../UiKit/Buttons/BlueButton/BlueButton";
import { VALID_TOKEN } from "../../../utils/vars/staticVariables";

const EditPixelApi = () => {
  const [pixelId, setPixelId] = useState("");
  const [token, setToken] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { singlePixelApi, PixelApiItemLoading } = useSelector(
    (state) => state.pixelApi,
  );

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchPixelApiItem(id));
  }, []);

  useEffect(() => {
    if (singlePixelApi) {
      setPixelId(singlePixelApi.pixel_id);
      setToken(singlePixelApi.token);
    }
  }, [singlePixelApi]);

  const { user_id } = getUser();

  const validateNumbers = (input) => {
    return /^\d+$/.test(input.trim());
  };

  function validateAlphaNumeric(input) {
    // Перевіряємо, чи рядок містить лише латинські букви (великі або малі) та цифри
    return /^[a-zA-Z0-9]+$/.test(input.trim());
  }

  const onErrorAction = (text) => {
    toast.error(text);
  };

  const onSaveHandler = async () => {
    if (!validateNumbers(pixelId)) {
      toast.error("Некоректное значение Pixel");
      return;
    }

    if (!validateAlphaNumeric(token)) {
      toast.error("Некоректное значение Token");
      return;
    }

    const isValidResult = await dispatch(checkPixelTokenValid(token));

    if (isValidResult?.payload?.detail === VALID_TOKEN) {
      const successAction = () => {
        toast.success("Pixel Api был успешно отредактирован");
        navigate("/pixel-api");
        handleContinue();
      };

      dispatch(
        editPixelApiItem({
          successAction,
          id,
          updatedData: {
            pixel_id: pixelId,
            token: token,
            owner: user_id,
          },
          onErrorAction,
        }),
      );
    } else {
      toast.error("Токен недействителен");
    }
  };

  return (
    <div className={s.pixelWrapper}>
      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Редактирование Pixel API</h3>
      </div>

      {PixelApiItemLoading ? (
        <CustomSkeleton styles={s.editPixelApiSkeleton} />
      ) : (
        <div className={s.pixelApiForm}>
          <div className={s.inputsWrapper}>
            <div>
              <h5 className={s.subTitle}>Pixel</h5>
              <BaseTextInput
                action={(e) => setPixelId(e.target.value)}
                value={pixelId}
                placeholder="Введите Pixel"
              />
            </div>

            <div>
              <h5 className={s.subTitle}>Token</h5>
              <BaseTextInput
                action={(e) => setToken(e.target.value)}
                value={token}
                placeholder="Введите Token"
              />
            </div>
          </div>
        </div>
      )}

      <div className={s.buttonsWrapper}>
        <RedButton
          width={120}
          text="Отменить"
          action={() => {
            navigate("/pixel-api");
            handleContinue();
          }}
        />
        <BlueButton
          width={120}
          text="Сохранить"
          pending={PixelApiItemLoading}
          action={onSaveHandler}
        />
      </div>
    </div>
  );
};

export default EditPixelApi;
