import React, { useEffect, useState } from "react";
// import s from "../../RedirectStep/redirectStep.module.scss";
import s from "./myPrelandings.module.scss";
import MultiSelect from "../../../../../MultiSelect/MultiSelect";
import {
  changeFlowPrelanding,
  setCurrentEditRedirectorId,
  setCurrentPrelandId,
  setPrelandingObjId,
  setSelectedRedirectors,
} from "../../../../../../redux/slices/flowSlice";
import { toast } from "react-hot-toast";
import GlobalSvgSelector from "../../../../../GlobalSvgSelector/GlobalSvgSelector";
import { colorList } from "../../../../../../utils/vars/staticVariables";
import LineChart from "../../../../../UiKit/LineChart/LineChart";
import DoughnutChart from "../../../../../UiKit/DoughnutChart/DoughnutChart";
import { handleContinue } from "../../../../../../utils/helpers/scrollTop";
import {
  fetchAllPrelandings,
  fetchPrelandingList,
} from "../../../../../../redux/slices/prelandingSlice";
import { useDispatch, useSelector } from "react-redux";
import SpeenWheelPopup from "../../../../../UiKit/SpeenWheelPopup/SpeenWheelPopup";
import { useNavigate, useParams } from "react-router-dom";
import {
  containsNonSpaceCharacter,
  hasNullApplicationArr,
  hasNullPercentageArr,
  validateAlphaNumbericStr,
} from "../../../../../../utils/helpers/validation";
import { createRedirector } from "../../../../../../redux/slices/redirectorSlice";
import RedButton from "../../../../../UiKit/Buttons/RedButton/RedButton";
import BlueButton from "../../../../../UiKit/Buttons/BlueButton/BlueButton";
import MuiTooltip from "../../../../../UiKit/MuiTooltip/MuiTooltip";

const MyPrelandings = ({ setStep }) => {
  const [isActivePopup, setActivePopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllPrelandings());
  }, []);

  const { prelandingList } = useSelector((state) => state.prelanding);
  const { currentPrelandId, prelandingObjId } = useSelector(
    (state) => state.flow,
  );

  useEffect(() => {
    // dispatch(setPrelandingObjId)
    if (prelandingList.length > 0) {
      const neededItem = prelandingList.find(
        (item) => item.id == prelandingObjId,
      );

      if (neededItem) {
        setSelectedOption({ value: neededItem.id, label: neededItem.name });
      } else {
        setSelectedOption({ value: null, label: "Не использовать прелендинг" });
      }
    }
  }, [prelandingList]);

  // const prelandingListOptions = prelandingList.map(({ id, name }) => ({
  //     value: id,
  //     label: name,
  // }));

  const prelandingListOptions = [
    { value: null, label: "Не использовать прелендинг" },
    ...prelandingList.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
  ];

  const { id } = useParams();

  const sendDataObj = {
    flow_id: parseInt(id),
    prelanding_id: prelandingObjId
      ? parseInt(prelandingObjId)
      : prelandingObjId,
  };

  const onChangePrelandingHandler = () => {
    // if (!prelandingObjId) {
    //     toast.error('Выберите прелендинг')
    // }

    // if (selectedOption.value)

    const onSuccessAction = () => {
      toast.success("Прелендинг успешно добавлен");
      setStep("redirect");
      // navigate("/all-redirectors");
      handleContinue();
    };

    const onErrorAction = (text) => {
      toast.error(text);
    };

    dispatch(
      changeFlowPrelanding({
        onSuccessAction,
        data: sendDataObj,
        onErrorAction,
      }),
    );
  };

  const openInNewTab = (prelandingId) => {
    // Перевірте, чи prelandingId існує перед тим, як відкрити URL
    if (prelandingId) {
      const url = `/prelandings/edit/${prelandingId}`;
      // Відкрити в новій вкладці
      window.open(url, "_blank");
    }
  };

  return (
    <div className={s.myRedirectWrapper}>
      <>
        {/*<SpeenWheelPopup active={isActivePopup} setActive={setActivePopup}/>*/}
        <div className={s.chooseMyRedirect}>
          <div className={s.contentWrapper}>
            {/*{*/}
            {/*    prelandingObjId && <button*/}
            {/*        className={s.editPrelandBtn}*/}
            {/*        onClick={() => openInNewTab(prelandingObjId)}*/}
            {/*    >*/}
            {/*        <GlobalSvgSelector id="edit-icon"/>*/}
            {/*    </button>*/}
            {/*}*/}

            {prelandingObjId && (
              <div className={s.managePrelandWrapper}>
                <button
                  className={s.editPrelandBtn}
                  onClick={() => openInNewTab(prelandingObjId)}
                >
                  <MuiTooltip title="Редактировать преленд">
                    <GlobalSvgSelector id="edit-icon" />
                  </MuiTooltip>
                </button>
              </div>
            )}

            {/*<button*/}
            {/*    className={s.editBtn}*/}
            {/*    onClick={() => navigate(`/prelanding/edit/${prelandingObjId}`)}*/}
            {/*>*/}
            {/*    <GlobalSvgSelector id="edit-icon"/>*/}
            {/*</button>*/}
            {/*<button onClick={() => setActivePopup(true)}>Превью</button>*/}

            <div className={s.selectWrapper}>
              <MultiSelect
                placeholder="Выберите преленд"
                value={selectedOption}
                // isClearable={true}
                options={prelandingListOptions}
                setSelectedOptions={(o) => {
                  setSelectedOption(o);
                  // dispatch(setPrelandingObjId(o ? o.value : null));
                  dispatch(setPrelandingObjId(o.value));
                }}
              />
            </div>

            {/*<button onClick={() => setActivePopup(true)}>Превью</button>*/}
          </div>
        </div>

        <div className={s.buttonsWrapper}>
          <RedButton
            width={120}
            text="Отменить"
            action={() => {
              navigate("/my-flows");
              handleContinue();
            }}
          />
          <BlueButton
            width={120}
            text="Далее"
            // pending={dataLoading}
            action={onChangePrelandingHandler}
          />
        </div>

        {/*<div className={s.buttonsWrapper}>*/}
        {/*    <button*/}
        {/*        className={s.cancelBtn}*/}
        {/*        onClick={() => {*/}
        {/*            // navigate("/my-flows");*/}
        {/*            handleContinue();*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        Отмена*/}
        {/*    </button>*/}
        {/*    <button*/}
        {/*        className={s.nextStepBtn}*/}
        {/*        onClick={onChangePrelandingHandler}*/}
        {/*        // onClick={() => console.log('DATAAA', sendDataObj)}*/}
        {/*        // onClick={onUpdateRedirect}*/}
        {/*        // disabled={changeRedirectLoading}*/}
        {/*    >*/}
        {/*        Сохранить*/}
        {/*        /!*{changeRedirectLoading ? "Загрузка" : "Сохранить"}*!/*/}
        {/*    </button>*/}
        {/*</div>*/}
      </>
    </div>
  );
};

export default MyPrelandings;
