import React from "react";
import s from "./pagination.module.scss";
import GlobalSvgSelector from "../GlobalSvgSelector/GlobalSvgSelector";
import {useWindowWidth} from "../../hooks/useWindowWidth";

const range = (start, end) => {
  return [...Array(end - start).keys()].map((el) => el + start);
};

const PaginationItem = ({ page, currentPage, onPageChange, isDisabled, style }) => {
  return (
    <li className={`${s.pageItem} ${page === currentPage ? s.active : ""}`}>
      <button
          style={style}
        className={s.paginationBtn}
        disabled={isDisabled}
        onClick={() => onPageChange(page)}
      >
        {page}
      </button>
    </li>
  );
};

const getPagesCut = ({ pagesCount, pagesCutCount, currentPage }) => {
  const ceiling = Math.ceil(pagesCutCount / 2);
  const floor = Math.floor(pagesCutCount / 2);

  if (pagesCount < pagesCutCount) {
    return { start: 1, end: pagesCount + 1 };
  } else if (currentPage >= 1 && currentPage <= ceiling) {
    return { start: 1, end: pagesCutCount + 1 };
  } else if (currentPage + floor >= pagesCount) {
    return { start: pagesCount - pagesCutCount + 1, end: pagesCount + 1 };
  } else {
    return { start: currentPage - ceiling + 1, end: currentPage + floor + 1 };
  }
};

const Pagination = ({ currentPage, setCurrentPage, total, limit }) => {
    const width = useWindowWidth();

  const pagesCount = Math.ceil(total / limit);



  const cutItem = width > 550 ? 5 : 3

  // if(width > 550) {
  //     cutItem = 5
  // } else {
  //     cutItem = 3
  // }

    // до 550 можна 5

  const pagesCut = getPagesCut({ pagesCount, pagesCutCount: cutItem, currentPage });
  const pages = range(pagesCut.start, pagesCut.end);
  const isFirstPage = currentPage == 1;
  const isLastPage = currentPage == pagesCount;

  return (
    <ul className={s.pagination}>
      <PaginationItem
        style={{paddingRight: 1}}
        page={<GlobalSvgSelector id="first-page-icon" />}
        currentPage={currentPage}
        onPageChange={() => setCurrentPage(1)}
        isDisabled={isFirstPage}
      />

      <li className={s.backPage}>
        <button
          className={s.paginationBtn}
          disabled={isFirstPage}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          {/*<GlobalSvgSelector id="previous-page-icon" />*/}
          <GlobalSvgSelector id="arrow-previous-icon" />
        </button>
      </li>

      {pages.map((page) => (
        <PaginationItem
          page={page}
          key={page}
          currentPage={currentPage}
          onPageChange={() => setCurrentPage(page)}
        />
      ))}

      {pagesCount > 99 && currentPage < 100 && currentPage < 97 ? (
        <>
          <li className={s.ellipsis}>
            <span>...</span>
          </li>
          <li className={s.countIndicator}>
            <span>+99</span>
          </li>
        </>
      ) : null}

      <li className={s.forwardPage}>
        <button
          className={s.paginationBtn}
          disabled={isLastPage}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <GlobalSvgSelector id="next-page-icon" />
        </button>
      </li>

      <PaginationItem
          style={{paddingLeft: 2}}
        page={<GlobalSvgSelector id="last-page-icon" />}
        currentPage={currentPage}
        onPageChange={() => setCurrentPage(pagesCount)}
        isDisabled={isLastPage}
      />
    </ul>
  );
};

export default Pagination;
