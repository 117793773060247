import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import qs from "qs";

import s from "./balances.module.scss";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";

import Search from "../../../components/Search/Search";

import CustomSkeleton from "../../../components/CustomSkeleton/CustomSkeleton";
import Pagination from "../../../components/Pagination/Pagination";
import {
  fetchBalances,
  financeSelector,
  setCurrentPage,
  setTransactionsCurrentPage,
} from "../../../redux/slices/financeSlice";
import { updateOrdering } from "../../../components/Statistics/CommonStats/CommonStats";
import SortIcon from "../../../components/UiKit/SortIcon/SortIcon";
import EmptyList from "../../../components/EmptyList/EmptyList";
import {
  fetchUsersList,
  globalDataSelector,
} from "../../../redux/slices/globalDataSlice";

const Balances = () => {
  const [searchValue, setSearchValue] = useState("");
  const [email, setEmail] = useState(null);
  const [ordering, setOrdering] = useState("wallet__balance");

  const abortControllerRef = useRef(null);

  const isSearch = useRef(false);
  const isMounted = useRef(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { balanceList, balancesLoading, currentPage, totalCount } =
    useSelector(financeSelector);

  const { usersList } = useSelector(globalDataSelector);

  useEffect(() => {
    dispatch(fetchUsersList());
  }, [dispatch]);

  const getBalances = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    // signal
    const params = {
      search: searchValue,
      email: email?.value,
      page: currentPage,
      ordering: ordering,
      signal,
    };

    dispatch(fetchBalances(params));
  };

  useEffect(() => {
    if (isMounted.current) {
      const queryString = qs.stringify({
        page: currentPage,
        search: searchValue,
        email: email?.value,
        ordering: ordering,
      });

      navigate(`?${queryString}`);
    }

    isMounted.current = true;
  }, [currentPage, searchValue, email, ordering]);

  useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));

      if (params.email) {
        setEmail({ value: params.email, label: params.email });
      }

      if (params.search) {
        setSearchValue(params.search);
      }

      if (params.ordering) {
        setOrdering(params.ordering);
      }

      if (params.page) {
        dispatch(setCurrentPage(parseInt(params.page)));
      }

      isSearch.current = true;
    }
  }, []);

  useEffect(() => {
    if (!isSearch.current) {
      getBalances();
    }

    isSearch.current = false;
  }, [currentPage, searchValue, email, ordering]);

  const emailOptions = usersList.map(({ email }) => ({
    value: email,
    label: email,
  }));

  return (
    <div className={s.statisticsWrapper}>
      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Балансы</h3>
      </div>

      <div className={s.searchBar}>
        <div className={s.recordsCount}>
          Всего: {!balancesLoading && <span>{totalCount}</span>}
        </div>
        <div className={s.searchWrapper}>
          <div className={s.dropdownWrapper}>
            <MultiSelect
              isClearable={true}
              options={emailOptions}
              value={email}
              setSelectedOptions={(o) => setEmail(o)}
              placeholder="Выберите пользователя"
              isMulti={false}
            />
          </div>
          <Search searchValue={searchValue} setSearchValue={setSearchValue} />
        </div>
      </div>

      {balanceList.length < 1 && !balancesLoading ? (
        <EmptyList />
      ) : (
        <>
          <table className={`${s.table} ${s.balancesTable}`}>
            <thead>
              <tr>
                <th>
                  Email
                  <SortIcon
                    isActive={ordering === "email" || ordering === "-email"}
                    currentSort={ordering}
                    onClick={() => updateOrdering("email", setOrdering)}
                  />
                </th>
                <th>
                  Оффер
                  <SortIcon
                    isActive={ordering === "offer" || ordering === "-offer"}
                    currentSort={ordering}
                    onClick={() => updateOrdering("offer", setOrdering)}
                  />
                </th>
                <th>
                  Баланс
                  <SortIcon
                    isActive={
                      ordering === "wallet__balance" ||
                      ordering === "-wallet__balance"
                    }
                    currentSort={ordering}
                    onClick={() =>
                      updateOrdering("wallet__balance", setOrdering)
                    }
                  />
                </th>
              </tr>
            </thead>

            {balancesLoading ? (
              <tbody>
                <CustomSkeleton styles={s.skeletonTable} />
              </tbody>
            ) : (
              <>
                <tbody className={s.tableContent}>
                  {balanceList?.map(({ email, balance, offer }) => {
                    return (
                      <tr key={email}>
                        <td>{email}</td>
                        <td>{offer}</td>
                        <td>{balance}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
          </table>
          {/*{*/}
          {/*    <div className={s.paginationWrapper}>*/}
          {/*        <Pagination*/}
          {/*            currentPage={currentPage}*/}
          {/*            setCurrentPage={(page) => dispatch(setCurrentPage(page))}*/}
          {/*            total={totalCount}*/}
          {/*            limit={pageSize}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*}*/}
          {totalCount > 8 && (
            <div className={s.paginationWrapper}>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={(page) =>
                  dispatch(setTransactionsCurrentPage(page))
                }
                total={totalCount}
                limit={8}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Balances;
