import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { login } from "../../../redux/slices/authSlice";
import Loader from "../../../components/UiKit/Loader/Loader";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import s from "./auth.module.scss";

export const authTextFieldStyles = {
  width: "100%",
  "& .MuiOutlinedInput-root": {
    marginTop: "3px",
    borderRadius: "12px",
    background: "#202936",
    "& fieldset": {
      borderColor: "#d3d3d3",
    },
    "&:hover fieldset": {
      borderColor: "rgba(208, 211, 217, .75)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(93, 135, 255, .7)",
    },
    "&.Mui-focused:hover fieldset": {
      borderColor: "rgba(93, 135, 255, 1)",
    },
    display: "flex",
    alignItems: "center",
    "& .MuiInputBase-input": {
      color: "#F2F5FA",
      paddingTop: "50px",
      height: "50px",
      padding: "0 55px 0 15px",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      lineHeight: "normal",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#d0d3d9",
      opacity: 1,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#d0d3d9",
  },
  "& .Mui-focused .MuiInputLabel-root": {
    color: "#d0d3d9",
  },
};

const Auth = () => {
  const [isShowedPassword, setShowedPassword] = useState();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    if (!data) return;
    await dispatch(
      login({
        form: { email: data.email.trim(), password: data.password.trim() },
        setError,
      }),
    );
  };

  return (
    <form className={s.authContainer} onSubmit={handleSubmit(onSubmit)}>
      <div className={s.header}>
        <h5>
          Добро пожаловать в WW<span>A</span>
        </h5>
        <span className={s.subtitle}>Войдите в свой аккаунт</span>
      </div>

      <div className={s.content}>
        <div className={s.fieldContainer}>
          <TextField
            {...register("email", {
              required: "Пожалуйста, введите электронный адрес",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Електронный адрес не валиден",
              },
            })}
            label="Email"
            type="email"
            error={!!errors.email}
            helperText={errors.email?.message}
            autoComplete="current-password"
            sx={authTextFieldStyles}
          />
        </div>

        <div className={s.fieldContainer}>
          <button
            className={s.showPasswordIcon}
            onClick={(e) => {
              e.preventDefault();
              setShowedPassword(!isShowedPassword);
            }}
          >
            <GlobalSvgSelector
              id={
                isShowedPassword ? "show-password-icon" : "hide-password-icon"
              }
            />
          </button>

          <TextField
            label="Пароль"
            type={isShowedPassword ? "text" : "password"}
            autoComplete="current-password"
            {...register("password", {
              required: "Пожалуйста, введите свой пароль",
            })}
            error={!!errors.password}
            helperText={errors.password?.message}
            sx={authTextFieldStyles}
          />
        </div>
      </div>
      {/*<div className={s.forgetPasswordContainer}>*/}
      {/*    <Link to='/reset/email-form'>Забыли пароль?</Link>*/}
      {/*</div>*/}
      <div className={s.footer}>
        <button className={s.loginBtn} type="submit" disabled={loading}>
          {loading ? <Loader /> : "Войти"}
        </button>
      </div>
      {/*<div className={s.createAccountContainer}>*/}
      {/*    Еще нет акканута? <Link to='/register/form'>Создать</Link>*/}
      {/*</div>*/}
    </form>
  );
};

export default Auth;
