import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import {
  customPrelandingUpload,
  editPrelanding,
} from "../../../../redux/slices/prelandingSlice";
import s from "../../../Flows/EditFlowSettings/Steps/PrelandingStep/OwnPreland/ownPreland.module.scss";
import BaseTextInput from "../../../BaseTextInput/BaseTextInput";
import { setCustomPrelandName } from "../../../../redux/slices/flowSlice";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
import RedButton from "../../../UiKit/Buttons/RedButton/RedButton";
import { handleContinue } from "../../../../utils/helpers/scrollTop";
import BlueButton from "../../../UiKit/Buttons/BlueButton/BlueButton";
import { validateNameString } from "../../../Flows/EditFlowSettings/Steps/PrelandingStep/OwnPreland/OwnPreland";

const CustomPreland = ({ prelandingObj }) => {
  const { id, name, prelanding_file } = prelandingObj;

  const [file, setFile] = useState(prelanding_file);

  const zipFileName = prelanding_file?.split("/")?.pop();

  const [fileName, setFileName] = useState(zipFileName || "Preland12345.zip");

  const [stateName, setStateName] = useState(name);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const {id} = useParams();

  const { customPrelandName } = useSelector((state) => state.flow);

  const { prelandPending } = useSelector((state) => state.prelanding);

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const onFileUpload = async () => {
    if (stateName.trim().length < 1) {
      toast.error("Заполните имя прелендинга");
      return;
    }

    if (!validateNameString(stateName)) {
      toast.error("Некорректное имя прелендинга");
      return;
    }

    if (!file) {
      toast.error("Выберите .zip файл");
      return;
    }

    if (file) {
      const formData = new FormData();

      formData.append("name", stateName);
      formData.append("prelanding_type", "4");

      if (file !== prelanding_file) {
        formData.append("prelanding_file", file);
      }

      const onSuccessAction = () => {
        toast.success("Преленд был обновлен");
        navigate("/prelandings");
        handleContinue();
      };

      const onErrorAction = (text) => {
        toast.error(text, {
          style: {
            // minWidth: '400px',
          },
        });
      };

      // const dataObj = {
      //     prelanding_file: file,
      //     status: true,
      //     prelanding_type: 4,
      //     flow_id: id,
      //     name: stateName
      // }

      dispatch(
        editPrelanding({ data: formData, id, onSuccessAction, onErrorAction }),
      );

      // dispatch(customPrelandingUpload({formData, onSuccessAction, onErrorAction}))
    }
  };

  return (
    <div className={s.ownPrelandWrapper}>
      <div className={s.container}>
        <h5 className={s.subTitle}>Редактирование кастомного прелендинга</h5>

        <div className={s.prelandNameInputWrapper}>
          <BaseTextInput
            action={(e) => setStateName(e.target.value)}
            value={stateName}
            placeholder="Введите имя прелендинга"
          />
        </div>

        <label htmlFor="upload-button" className={s.downloadPreland}>
          <GlobalSvgSelector id="download-icon" />
          <span>{fileName}</span>
        </label>
        <input
          id="upload-button"
          type="file"
          style={{ display: "none" }}
          onChange={onFileChange}
          accept=".zip"
        />
      </div>

      <div className={s.buttonsWrapper}>
        <RedButton
          text="Отменить"
          action={() => {
            navigate("/prelandings");
            handleContinue();
          }}
          width={120}
        />
        <BlueButton
          width={110}
          text="Сохранить"
          pending={prelandPending}
          action={onFileUpload}
        />
      </div>
    </div>
  );
};

export default CustomPreland;
