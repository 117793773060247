import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { globalDataSelector } from "../../../redux/slices/globalDataSlice";
import {
  fetchCloacaStats,
  resetCloacaStats,
  statisticsSelector,
} from "../../../redux/slices/statisticsSlice";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import MultiSelect from "../../MultiSelect/MultiSelect";
import CustomSkeleton from "../../CustomSkeleton/CustomSkeleton";
import Pagination from "../../Pagination/Pagination";
import {
  formatDateTime,
  formatTimestampToServerDate,
  stringToDayJs,
} from "../../../utils/helpers/formatTime";
import s from "./cloacaStats.module.scss";

import { IpCreatableSelect } from "../../UiKit/Select/IpCreatableSelect/IpCreatableSelect";
import BlueButton from "../../UiKit/Buttons/BlueButton/BlueButton";
import RedButton from "../../UiKit/Buttons/RedButton/RedButton";
import EmptyList from "../../EmptyList/EmptyList";
import { params, sortDateOptions } from "../../../utils/vars/params";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import {
  useSearchParamsOptions,
  useSelectOptions,
} from "../../../utils/helpers/useSelectHelpers";
import {
  country_code,
  created_at_after,
  created_at_before,
  flow,
  ip_address,
  onSortDateHandler,
  os,
  page,
  sort,
} from "../ClicksStats/ClicksStats";
import { fetchCloacaStatsFilters } from "../../../api/helpers/fetchFilters";
import { getDefaultParams } from "../../../api/helpers/scripts";
import { INVALID_PAGE_ERROR } from "../../../utils/vars/staticVariables";
import { MuiDatePicker } from "../../Blocks/MuiDatePicker/MuiDatePicker";
import { toast } from "react-hot-toast";

const CloacaStats = () => {
  const [ipList, setIpList] = useState([]);
  const [sortDate, setSortDate] = useState(sortDateOptions[0]);
  const [selectedGeos, setSelectedGeos] = useState([]);

  const promiseRef = useRef(null);
  const abortControllerRef = useRef(null); // Створення ref для збереження інстансу AbortController

  const dispatch = useDispatch();

  const { geoList, OSList, domainsList } = useSelector(globalDataSelector);
  const { cloacaStatisticsList, cloacaLoading, cloacaTotalCount } =
    useSelector(statisticsSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    sort: sortValue,
    page: pageValue,
    created_at_after: createdAtAfterParam,
    created_at_before: createdAtBeforeParam,
    flow: flowParam,
  } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const [selectedOS, selectedIP] = useSearchParamsOptions(searchParams, [
    os,
    ip_address,
  ]);

  const selectedDomain = useMemo(() => {
    return flowParam ? { value: flowParam, label: flowParam } : null;
  }, [flowParam]);

  const domainsListOptions = useSelectOptions(
    domainsList,
    "address",
    "address",
  );

  const geoListOptions = useSelectOptions(geoList, "name", "code");

  const OSListOptions = useMemo(() => {
    return OSList.map((item) => ({
      label: item,
      value: item,
    }));
  }, [OSList]);

  useEffect(() => {
    fetchCloacaStatsFilters(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (
      createdAtAfterParam ||
      createdAtBeforeParam ||
      sortDate.value === "custom_date"
    ) {
      setSortDate(sortDateOptions.find((item) => item.value === "custom_date"));
    } else {
      if (!sortValue) {
        setSortDate(sortDateOptions[0]);
      } else {
        setSortDate(sortDateOptions.find(({ value }) => value === sortValue));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedIP) {
      setIpList(selectedIP);
    }
  }, []);

  useEffect(() => {
    if (ipList.length > 0)
      updateSearchParams(ip_address, ipList.map((el) => el.value).join(","));
  }, [ipList]);

  useEffect(() => {
    const newGeoListOptions = searchParams
      .get(country_code)
      ?.split(",")
      .filter((value) => value !== "")
      .map((value) => {
        return geoListOptions.find((item) => item.value === value);
      });

    setSelectedGeos(newGeoListOptions);
  }, [searchParams, geoListOptions]);

  const handlerResetFilters = () => {
    updateSearchParams(created_at_after, "", true);
    updateSearchParams(created_at_before, "", true);
    // updateSearchParams(sort, "");
    setSortDate(sortDateOptions[0]);
    updateSearchParams(os, "");
    updateSearchParams(country_code, "");
    updateSearchParams(ip_address, "");
    setIpList([]);
  };

  const handleFetchCloacaStats = () => {
    if (!selectedDomain) {
      return toast.error("Выберите поток");
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const promise = dispatch(
      fetchCloacaStats({
        params: getDefaultParams({ searchParams, sort, defaultPageSize: 100 }),
        signal,
      }),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
        dispatch(
          fetchCloacaStats({
            params: getDefaultParams({
              searchParams,
              sort,
              defaultPageSize: 100,
            }),
            signal,
          }),
        );
      }
    });

    promiseRef.current = promise;
  };

  useEffect(() => {
    return () => dispatch(resetCloacaStats());
  }, []);

  return (
    <div className={s.statisticsWrapper}>
      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Статистика по клоаке</h3>
      </div>

      <div className={s.filtersWrapper}>
        <h5 className={s.filtersTitle}>Фильтрация</h5>
        <div className={s.filters}>
          {
            <div className={s.selectDateWrapper}>
              <MultiSelect
                isClearable={false}
                options={sortDateOptions}
                setSelectedOptions={(value) =>
                  onSortDateHandler(value, updateSearchParams, setSortDate)
                }
                value={sortDate}
                placeholder="Дата"
                isMulti={false}
              />
            </div>
          }

          {sortDate?.value === "custom_date" && (
            <div className={s.datePickersWrapper}>
              <div className={s.datePicker}>
                <MuiDatePicker
                  label="Дата от"
                  value={stringToDayJs(createdAtAfterParam)}
                  onChange={(e) =>
                    updateSearchParams(
                      created_at_after,
                      formatTimestampToServerDate(e?.toString()),
                    )
                  }
                />
              </div>
              <div className={s.datePicker}>
                <MuiDatePicker
                  label="Дата до"
                  value={stringToDayJs(createdAtBeforeParam)}
                  onChange={(e) => {
                    updateSearchParams(
                      created_at_before,
                      formatTimestampToServerDate(e?.toString()),
                    );
                  }}
                />
              </div>
            </div>
          )}

          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedOS}
              options={OSListOptions}
              setSelectedOptions={(values) =>
                updateSearchParams(os, values.map((el) => el.value).join(","))
              }
              // setSelectedOptions={(o) => setSelectedOS(o)}
              placeholder="ОС"
              isMulti={true}
            />
          </div>
          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedDomain}
              options={domainsListOptions}
              setSelectedOptions={(option) => {
                if (option) {
                  const { value } = option;
                  updateSearchParams(flow, value);
                } else {
                  updateSearchParams(flow, "", true);
                }
              }}
              // setSelectedOptions={(values) =>
              //   updateSearchParams(flow, values.map((el) => el.value).join(","))
              // }
              placeholder="Поток"
              // isMulti={true}
            />
          </div>
          <div className={s.selectWrapper}>
            <MultiSelect
              isClearable={true}
              value={selectedGeos}
              options={geoListOptions}
              setSelectedOptions={(values) =>
                updateSearchParams(
                  country_code,
                  values.map((el) => el.value).join(","),
                )
              }
              placeholder="Гео"
              isMulti={true}
            />
          </div>
          <div className={s.selectWrapper}>
            <IpCreatableSelect
              selectedOption={ipList}
              setSelectedOption={(value) => {
                setIpList(value);
              }}
            />
          </div>
          <div className={s.buttons}>
            <BlueButton
              text="Применить"
              width={90}
              action={handleFetchCloacaStats}
            />
            <RedButton
              text="Очистить"
              width={95}
              action={handlerResetFilters}
            />
          </div>
        </div>
      </div>

      {!cloacaTotalCount && !cloacaLoading ? (
        <EmptyList />
      ) : (
        <>
          <table className={`${s.table} ${s.cloacaStatsTable}`}>
            <thead>
              <tr>
                <th>Время</th>
                <th>Поток</th>
                <th>Гео</th>
                <th>ОС</th>
                <th>Браузер</th>
                <th>Языки</th>
                <th>Режим</th>
                <th>Статус</th>
              </tr>
            </thead>

            {cloacaLoading ? (
              <tbody>
                <CustomSkeleton styles={s.skeletonTable} />
              </tbody>
            ) : (
              <>
                <tbody className={s.tableContent}>
                  {cloacaStatisticsList?.map(
                    (
                      {
                        timestamp,
                        // ip_address,
                        // asn,
                        country_code,
                        os,
                        browser,
                        languages,
                        mode,
                        target,
                        flow,
                      },
                      index,
                    ) => {
                      const { date, time } = formatDateTime(timestamp);

                      return (
                        <tr
                          style={{
                            background:
                              target === 0
                                ? "rgba(230, 0, 0, 0.2"
                                : "#15335080",
                          }}
                          key={index}
                        >
                          <td
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div>{date}</div>
                            <div className={s.minorTime}>{time}</div>
                          </td>
                          <td>{flow}</td>
                          <td>{country_code}</td>
                          <td>{os}</td>
                          <td>{browser}</td>
                          <td>{languages?.join(", ")}</td>
                          <td>{mode}</td>
                          <td
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {target === 0 ? (
                              <>
                                <GlobalSvgSelector id="cross-icon" />
                                Несовпадение ОС/ хостинг/VPN
                              </>
                            ) : (
                              <GlobalSvgSelector id="check-icon" />
                            )}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </>
            )}
          </table>
          {cloacaTotalCount > 100 && (
            <div className={s.paginationWrapper}>
              <Pagination
                currentPage={parseInt(pageValue) || 1}
                setCurrentPage={(page) => {
                  updateSearchParams(params.page, page);
                  handleFetchCloacaStats();
                }}
                total={cloacaTotalCount}
                limit={100}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CloacaStats;
