import React, { useEffect, useState } from "react";
import MultiSelect from "../../../MultiSelect/MultiSelect";
import BaseTextInput from "../../../BaseTextInput/BaseTextInput";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
import s from "./getLinksPopup.module.scss";

const GetLinksPopup = ({ domain, active, setActive, redirectors }) => {
  const [currentSub7, setCurrentSub7] = useState(null);

  useEffect(() => {
    if (redirectors && redirectors?.length > 0 && redirectors[0]?.sub7) {
      setCurrentSub7({
        value: redirectors[0]?.sub7,
        label: redirectors[0]?.sub7,
      });
    }

    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        setActive(false);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };

    document.addEventListener("keydown", handleEscClose);

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [redirectors, setActive]);

  const domainLink = `https://${domain}/?pixel_id=PIXEL&sub1={sub1}&sub2={sub2}&sub3={sub3}&sub4={sub4}&sub5={sub5}&sub6={sub6}&sub7=${currentSub7?.value}`;

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      setActive(false);
    }
  };

  const sub7ListOptions = redirectors.map(({ sub7 }) => ({
    value: sub7,
    label: sub7,
  }));

  return (
    <div
      className={active ? `${s.popupWrapper} ${s.active}` : s.popupWrapper}
      onClick={handleClosePopup}
    >
      <div className={active ? `${s.popup} ${s.active}` : s.popup}>
        <div className={s.header}>
          <span>Получение ссылки</span>{" "}
          <button className={s.closeBtn} onClick={() => setActive(false)}>
            <GlobalSvgSelector id="close-big-icon" />
          </button>
        </div>
        <div className={s.body}>
          <div className={s.note}>
            Мы настоятельно рекомендуем проверять корректность создания каждой
            ссылки перед запуском рекламной кампании. Такая проверка позволит
            избежать потери трафика и гарантировать эффективность рекламной
            кампании.
          </div>
          <div className={s.fbLink}>
            <h5 className={s.subTitle}>Sub7</h5>
            <MultiSelect
              value={currentSub7}
              isClearable={false}
              options={sub7ListOptions}
              setSelectedOptions={(o) => {
                setCurrentSub7(o);
              }}
              placeholder="Sub7"
              isMulti={false}
            />
          </div>
          <div>
            <h5 className={s.subTitle}>FB</h5>
            <BaseTextInput
              value={currentSub7?.value && domain && domainLink}
              placeholder="Выберите sub7 выше"
              copy={true}
              redirect
            />
          </div>
          <div>
            <h5 className={s.subtitleBorderBottom}>Постбэки</h5>
            <h5 className={s.subTitle}>Reg</h5>
            <BaseTextInput
              value="https://servapi.wwa-ios.com/event/?event=lead&payout={revenue}&tds_clickid={tds_clickid}"
              placeholder="Reg"
              copy={true}
            />
          </div>
          <div>
            <h5 className={s.subTitle}>Dep</h5>
            <BaseTextInput
              value="https://servapi.wwa-ios.com/event/?event=sale&payout={revenue}&tds_clickid={tds_clickid}"
              placeholder="Reg"
              copy={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetLinksPopup;
