import React, { useCallback, useEffect, useRef, useState } from "react";
import s from "./prelandings.module.scss";
import GlobalSvgSelector from "../GlobalSvgSelector/GlobalSvgSelector";
// import { pixelApiData } from "../../templateContent";
import Pagination from "../Pagination/Pagination";
import CreatePrelanding from "./CreatePrelanding/CreatePrelanding";
import ConfirmPopup from "../UiKit/Popups/ConfirmPopup/ConfirmPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePrelanding,
  fetchPrelandingList,
  setPrelandPage,
} from "../../redux/slices/prelandingSlice";
import BlueButton from "../UiKit/Buttons/BlueButton/BlueButton";
import MultiSelect from "../MultiSelect/MultiSelect";
import Search from "../Search/Search";
import CustomSkeleton from "../CustomSkeleton/CustomSkeleton";
import FlowItem from "../Flows/FlowItem";
import {
  deleteFlow,
  fetchFlowList,
  setCurrentPage,
} from "../../redux/slices/flowSlice";
import { Link, useNavigate } from "react-router-dom";
import PrelandingItem from "./PrelandingItem";
import { toast } from "react-hot-toast";
import { handleContinue } from "../../utils/helpers/scrollTop";
import qs from "qs";
import EmptyList from "../EmptyList/EmptyList";

const Prelandings = () => {
  const [searchValue, setSearchValue] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);

  const isSearch = useRef(false);
  const isMounted = useRef(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { prelandingList, totalCount, prelandingLoading, currentPage } =
    useSelector((state) => state.prelanding);

  const getPrelandings = useCallback(() => {
    const fetchPrelandingParams = {
      page: currentPage,
      search: searchValue,
    };

    dispatch(fetchPrelandingList(fetchPrelandingParams));
  }, [currentPage, dispatch, searchValue]);

  useEffect(() => {
    if (isMounted.current) {
      const queryString = qs.stringify({
        page: currentPage,
        search: searchValue,
      });

      navigate(`?${queryString}`);
    }

    isMounted.current = true;
  }, [currentPage, searchValue, navigate]);

  useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));

      if (params.page) {
        dispatch(setPrelandPage(parseInt(params.page)));
      }

      if (params.search) {
        setSearchValue(params.search);
      }

      isSearch.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    // if (!isSearch.current) {
    getPrelandings();
    // }

    isSearch.current = false;
  }, [currentPage, searchValue]);

  // useEffect(() => {
  //     getPrelandings()
  // }, []);

  const onDeleteHandler = async (id) => {
    await dispatch(deletePrelanding({ id }));
    toast.success("Запись была успешно удалена");
    handleContinue();
    await getPrelandings();
  };

  return (
    <div className={s.streamsWrapper}>
      {/*<div className={s.header}>*/}
      {/*  <h3>Прелендинги</h3>*/}
      {/*  <BlueButton*/}
      {/*      text='Создать'*/}
      {/*      action={() => navigate('/prelandings/create')}*/}
      {/*  />*/}
      {/*</div>*/}

      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Прелендинги</h3>
        <Link to="/prelandings/create" className={s.blueButton}>
          Создать
        </Link>
        {/*<BlueButton*/}
        {/*    width={120}*/}
        {/*    text='Создать'*/}
        {/*    action={() => navigate('/prelandings/create')}*/}
        {/*/>*/}
      </div>

      <div className={s.searchBar}>
        <div className={s.recordsCount}>
          Всего:<span>{totalCount}</span>
        </div>
        <Search searchValue={searchValue} setSearchValue={setSearchValue} />
      </div>
      <div>
        <div className={s.tableWrapper}>
          <div className={s.table}>
            {totalCount === 0 && !prelandingLoading ? (
              <EmptyList />
            ) : prelandingLoading ? (
              <CustomSkeleton styles={s.skeletonTable} />
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>Название</th>
                    <th>Тип</th>
                    <th>Действия</th>
                  </tr>
                </thead>
                <tbody>
                  {prelandingList.map((item) => (
                    <tr key={item.id}>
                      <PrelandingItem
                        item={item}
                        onDeleteHandler={onDeleteHandler}
                        getPrelandings={fetchPrelandingList}
                      />
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {totalCount > 8 && (
            <div className={s.paginationWrapper}>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={(page) => dispatch(setPrelandPage(page))}
                total={totalCount}
                limit={8}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Prelandings;
