export const handleContinue = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const scrollToBottom = () => {
  // window.scrollTo({
  //     top: document.documentElement.offsetHeight,
  //     behavior: "smooth",
  // });

  // window.scrollTo({
  //     top: document.body.scrollHeight,
  //     behavior: 'smooth'
  // });

  window.scrollBy({
    top: 3000, // кількість пікселів, на які скролиться вниз
    behavior: "smooth", // плавний скрол
  });
};
