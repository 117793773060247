import React from 'react';
import './AuthBackground.scss';
import {useWindowWidth} from "../../../hooks/useWindowWidth";

const AuthBackground = () => {
    const width = useWindowWidth()

    const numStars = width > 1200 ? 17 : 7;
    const stars = Array.from({ length: numStars });

    return (
        <div className="night">
            {stars.map((_, index) => (
                <div className="shooting_star" key={index} />
            ))}
        </div>
    );
};

export default AuthBackground;
