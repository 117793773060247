import axios from "axios";
import { getAccessToken, getRefreshToken } from "../storage/storage";
import { logoutHandler } from "./helpers/handlers";

export const API_URL = process.env.REACT_APP_PROD_API_URL;
// export const API_URL = import.meta.env.VITE_STAGE_API_URL;
// export const API_URL = "";

const $api = axios.create({
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.post(`${API_URL}/users/token/refresh/`, {
          refresh: getRefreshToken(),
        });
        localStorage.setItem("accessToken", response.data.access);
        localStorage.setItem("refreshToken", response.data.refresh);
        return $api.request(originalRequest);
      } catch (e) {
        logoutHandler();
      }
    }
    throw error;
  },
);

export default $api;
