import React, { useEffect } from "react";
import s from "./confirmPopup.module.scss";
import RedButton from "../../Buttons/RedButton/RedButton";
import BlueButton from "../../Buttons/BlueButton/BlueButton";

const ConfirmPopup = ({
  active,
  setActive,
  action,
  content,
  confirmName,
  confirmBtnText,
  alert,
  support,
}) => {
  const applyAction = () => {
    setActive(false);
    action();
  };

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      setActive(false);
    }
  };

  useEffect(() => {
    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        setActive(false);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };

    const handleEnterPress = (e) => {
      if (e.key === "Enter") {
        if (document.activeElement) {
          document.activeElement.blur();
        }
        applyAction();
      }
    };

    if (active) {
      document.addEventListener("keydown", handleEscClose);
      document.addEventListener("keydown", handleEnterPress);
    }

    return () => {
      document.removeEventListener("keydown", handleEscClose);
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [active]);

  return (
    <div
      className={active ? `${s.popupWrapper} ${s.active}` : s.popupWrapper}
      onClick={handleClosePopup}
    >
      <div className={active ? `${s.popup} ${s.active}` : s.popup}>
        <div className={s.headerWrapper}>
          <div className={s.header}>
            <span>{confirmName}</span>
          </div>
        </div>
        <div className={s.body}>
          {content && <div className={s.content}>{content}</div>}
          {support && (
            <div className={s.support}>
              Чтобы расширить лимит, обратитесь в
              <a href="https://t.me/yaroslava_support_wwa" target="_blank">
                Поддержку
              </a>
            </div>
          )}

          <div
            className={s.buttonsWrapper}
            style={alert ? { justifyContent: "center" } : null}
          >
            {!alert && (
              <RedButton
                text="Отменить"
                action={() => setActive(false)}
                width={120}
              />
            )}
            <BlueButton
              width={120}
              text={confirmBtnText}
              action={applyAction}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
