import React from 'react';
import s from './redButton.module.scss'
const RedButton = ({action, text, pending, width}) => {
    return (
        <button
            className={s.blueButton}
            onClick={action}
            disabled={pending}
            style={width ? {width: width} : null}
        >
            {text}
        </button>
    )
}

export default RedButton;