import React, { useEffect, useRef, useState } from "react";
import s from "../prelandings.module.scss";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import wheelImg from "../../../assets/img/speenWheel.png";
import { useDispatch, useSelector } from "react-redux";
import AppSettingsDropdown from "../../Flows/EditFlowSettings/AppSettingsDropdown/AppSettingsDropdown";
import {
  setPrelandTypeLabel,
  setPrelandTypeName,
} from "../../../redux/slices/flowSlice";
import { prelandTypeList } from "../../../utils/vars/staticVariables";
import defaultLogo from "../../../assets/img/defaultLogo.png";
import SpinWheel from "../SpinWheel/SpinWheel";

import {
  createPrelanding,
  fetchWheelStylesList,
  getPrelandingById,
  setSelectedImage,
  setStyleId,
  setSpinCount,
  setPrelandingName,
  setPrelandTitle,
  setSpinButtonText,
  setRespinButtonText,
  setPopupTitle,
  setPopupText,
  setPopupButtonText,
  setPrelandingStatus,
  setSectors,
  setCurrentWheel,
  editPrelanding,
  updateSectorValue,
} from "../../../redux/slices/prelandingSlice";
import { toast } from "react-hot-toast";
import { handleContinue } from "../../../utils/helpers/scrollTop";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../UiKit/Loader/Loader";
import BaseTextInput from "../../BaseTextInput/BaseTextInput";
import LimitedInput from "../../LimitedInput/LimitedInput";
import RedButton from "../../UiKit/Buttons/RedButton/RedButton";
import BlueButton from "../../UiKit/Buttons/BlueButton/BlueButton";
import CustomPreland from "./CustomPreland/CustomPreland";
import CustomSkeleton from "../../CustomSkeleton/CustomSkeleton";

const EditPrelanding = () => {
  const [winSector, setWinSector] = useState(0);

  const nameInputRef = useRef(null);
  const prelandTitleRef = useRef(null);
  const spinButtonTextRef = useRef(null);
  const respinButtonTextRef = useRef(null);
  const popupTitleRef = useRef(null);

  const fileInputRef = useRef(null);

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getPrelandingById(id));
  }, [dispatch, id]);

  const { prelandTypeName } = useSelector((state) => state.flow);

  const {
    wheelStylesList,
    wheelStylesLoading,
    prelandingObj,
    selectedImage,
    spinCount,
    prelandingName,
    prelandTitle,
    spinButtonText,
    respinButtonText,
    popupTitle,
    popupText,
    popupButtonText,
    styleId,
    currentWheel,
    prelandingStatus,
    sectors,
    prelandPending,
    prelandingObjLoading,
  } = useSelector((state) => state.prelanding);

  // const updateSectorValue = (index, newValue) => {
  //     dispatch(setSectors(currentSectors =>
  //         currentSectors.map((sector, sectorIndex) =>
  //             sectorIndex === index ? {...sector, value: newValue} : sector
  //         )
  //     ));
  // };

  useEffect(() => {
    if (prelandingObj) {
      dispatch(setSelectedImage(prelandingObj?.logo));
      setWinSector(sectors?.findIndex((item) => item.is_win == true));
    }
  }, [prelandingObj]);

  console.log("prelandingObj", prelandingObj);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    dispatch(fetchWheelStylesList());
  }, []);

  useEffect(() => {
    // dispatch(setStyleId(wheelStylesList[0]?.id))

    dispatch(
      setCurrentWheel(wheelStylesList.find((item) => item.id == styleId)),
    );
  }, [wheelStylesList, styleId]);

  const sendDataObj = {
    status: prelandingStatus,
    name: prelandingName,
    prelanding_type: 1,
    header_text: prelandTitle,
    style: styleId,
    rotation_button_text: spinButtonText,
    respin_button_text: respinButtonText,
    num_of_revolutions: spinCount,
    sectors: sectors,
    popup_win_text: popupText,
    winning_button_text: popupButtonText,
    bonus_button_text: popupTitle,
    logo: null,
  };

  if (selectedImage !== prelandingObj?.logo) {
    sendDataObj.logo = selectedImage;
  }

  const validateAplhaNumeric = (value) => {
    const regex = /^[a-zA-Zа-яА-ЯіІїЇєЄґҐ0-9]+( [a-zA-Zа-яА-ЯіІїЇєЄґҐ0-9]+)*$/;
    return regex.test(value.trim());
  };

  const navigate = useNavigate();

  const scrollToElement = (ref) => {
    if (ref && ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 150; // Віднімаємо 150 пікселів

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const onEditPrelanding = () => {
    // if (prelandingName.length < )

    if (prelandingName.trim().length < 1) {
      scrollToElement(nameInputRef);
      toast.error("Заполните имя прелендинга");
      return;
    }

    if (prelandTitle.trim().length < 1) {
      scrollToElement(prelandTitleRef);
      toast.error("Заполните текст вращения");
      return;
    }

    if (spinButtonText.trim().length < 1) {
      scrollToElement(spinButtonTextRef);
      toast.error("Заполните текст кнопки вращения");
      return;
    }

    if (respinButtonText.trim().length < 1) {
      scrollToElement(respinButtonTextRef);
      toast.error("Заполните текст кнопки повторного вращения", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    const hasEmptyString = sectors.some((sector) => sector.value.trim() === "");
    if (hasEmptyString) {
      toast.error("Заполните пустые значения секторов");
      return;
    }

    // sectors.forEach(sector => {
    //     if (!validateAplhaNumeric(sector.value) && sector.value.trim() !== "") {
    //         toast.error("Некоректные значения секторов")
    //     }
    // });

    if (popupTitle.trim().length < 1) {
      // scrollToElement(respinButtonTextRef);
      toast.error("Заполните заголовок попапа выигрыша", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    if (popupText.trim().length < 1) {
      // scrollToElement(respinButtonTextRef);
      toast.error("Заполните текст попапа выигрыша", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    if (popupButtonText.trim().length < 1) {
      // scrollToElement(respinButtonTextRef);
      toast.error("Заполните текст кнопки попапа выигрыша", {
        style: {
          minWidth: "400px",
        },
      });
      return;
    }

    const onSuccessAction = () => {
      toast.success("Прелендинг был успешно отредактирован");
      navigate("/prelandings");
      handleContinue();
    };

    const onErrorAction = (text) => {
      toast.error(text);
    };

    dispatch(
      editPrelanding({ data: sendDataObj, id, onSuccessAction, onErrorAction }),
    );
  };

  const handleWinSectorChange = (selectedIndex) => {
    // Оновлюємо кожен об'єкт в масиві
    const updatedWheelNumbers = sectors?.map((sector, index) => ({
      ...sector,
      is_win: index === selectedIndex, // Тільки обраний сектор отримає is_win: true
    }));

    // Оновлюємо стан з новим масивом
    dispatch(setSectors(updatedWheelNumbers));
  };

  const handleSectorsChange = (index, newValue) => {
    dispatch(updateSectorValue({ index, newValue }));
  };

  return (
    <div className={s.prelandingStepWrapper}>
      <div className={s.pageHeader}>
        <h5 className={s.pageTitle}>Прелендинги</h5>
      </div>

      {prelandingObjLoading ? (
        <CustomSkeleton styles={s.skeleton} />
      ) : prelandingObj?.prelanding_type === 4 ? (
        <CustomPreland prelandingObj={prelandingObj} />
      ) : (
        <>
          <div className={s.formWrapper}>
            <h5 className={s.subTitle}>Редактирование прелендинга</h5>
            <div className={s.content}>
              <>
                <div className={s.createPrelandingForm}>
                  <form action="">
                    <div className={s.statusWrapper}>
                      <h5 className={s.subtitleBorderBottom}>Преленд</h5>
                      <div className={s.statusCheckbox}>
                        <input
                          type="checkbox"
                          checked={prelandingStatus}
                          onChange={() =>
                            dispatch(setPrelandingStatus(!prelandingStatus))
                          }
                        />
                        <span>Отображать</span>
                      </div>
                    </div>

                    <div className={s.prelandNameContainer} ref={nameInputRef}>
                      <h5 className={s.subTitle}>Название</h5>

                      <BaseTextInput
                        action={(e) =>
                          dispatch(setPrelandingName(e.target.value))
                        }
                        value={prelandingName}
                        placeholder="Введите текст"
                      />
                    </div>

                    <h5 className={s.subTitle}>Тип преленда</h5>
                    <div className={s.typeWrapper}>
                      <AppSettingsDropdown
                        desc="Выберите параметр"
                        selected={prelandTypeName}
                        setLabel={(item) => dispatch(setPrelandTypeLabel(item))}
                        setName={(item) => dispatch(setPrelandTypeName(item))}
                        options={prelandTypeList}
                      />
                    </div>

                    <h5
                      className={s.subtitleBorderBottom}
                      ref={prelandTitleRef}
                    >
                      Уникальные настройки
                    </h5>

                    <div className={s.uniqueSettings}>
                      <LimitedInput
                        maxLength={20}
                        value={prelandTitle}
                        action={(e) =>
                          dispatch(setPrelandTitle(e.target.value))
                        }
                        subtitle="Заголовок"
                      />

                      <div ref={spinButtonTextRef}>
                        <LimitedInput
                          maxLength={10}
                          value={spinButtonText}
                          action={(e) =>
                            dispatch(setSpinButtonText(e.target.value))
                          }
                          subtitle="Текст кнопки вращения"
                        />
                      </div>

                      <div ref={respinButtonTextRef}>
                        <LimitedInput
                          maxLength={10}
                          value={respinButtonText}
                          action={(e) =>
                            dispatch(setRespinButtonText(e.target.value))
                          }
                          subtitle="Текст кнопки повторного вращения"
                        />
                      </div>

                      <h5 className={s.subTitle}>Стиль колеса</h5>

                      <ul className={s.wheelCheckboxList}>
                        {wheelStylesList?.map(({ id, wheel }, index) => (
                          <li
                            className={s.wheelStyleItem}
                            key={id}
                            onClick={() => dispatch(setStyleId(id))}
                          >
                            <label className={s.checkboxWrapper}>
                              <img
                                src={wheel}
                                alt="wheel"
                                style={
                                  id === styleId
                                    ? {
                                        border: "4px solid #5D87FF",
                                        borderRadius: "50%",
                                      }
                                    : null
                                }
                              />
                            </label>
                          </li>
                        ))}
                      </ul>
                      <div className={s.sectorsWrapper}>
                        <h5 className={s.subTitle}>
                          Значения секторов и сектор финального выигрыша
                        </h5>

                        <div className={s.sectorsContent}>
                          {sectors?.map((sector, index) => (
                            <div className={s.sectorItem} key={index}>
                              <div className={s.sectorInputWrapper}>
                                <div className={s.sectorInputContainer}>
                                  <input
                                    type="text"
                                    className={s.sectorInput}
                                    value={sector.value}
                                    onChange={(e) =>
                                      handleSectorsChange(index, e.target.value)
                                    }
                                    placeholder="Введите текст"
                                    maxLength={10}
                                  />
                                  {sector.value.length > 0 && (
                                    <div className={s.lengthIndicator}>
                                      {sector.value.length}/10
                                    </div>
                                  )}
                                </div>
                                <label className={s.radioLabel}>
                                  <input
                                    className={s.radio}
                                    type="radio"
                                    value={index}
                                    checked={sector.is_win}
                                    onChange={() => {
                                      handleWinSectorChange(index);
                                      setWinSector(index);
                                    }}
                                  />
                                  <span>WIN</span>
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div ref={popupTitleRef}>
                        <LimitedInput
                          maxLength={16}
                          value={popupTitle}
                          action={(e) =>
                            dispatch(setPopupTitle(e.target.value))
                          }
                          subtitle="Заголовок в попапе выигрыша"
                        />
                      </div>

                      <LimitedInput
                        maxLength={30}
                        value={popupText}
                        action={(e) => dispatch(setPopupText(e.target.value))}
                        subtitle="Текст в попапе выигрыша"
                      />

                      <h5 className={s.inputTip}>
                        Используйте {`{bonus}`} для того, чтобы подставить
                        значение выигрыша в тексте поздравления
                      </h5>
                      <LimitedInput
                        maxLength={10}
                        value={popupButtonText}
                        action={(e) =>
                          dispatch(setPopupButtonText(e.target.value))
                        }
                        subtitle="Текст кнопки бонуса"
                      />
                    </div>
                  </form>
                </div>
                <div className={s.previewWrapper}>
                  <div className={s.previewContent}>
                    <h6>Превью</h6>
                    <SpinWheel
                      wheelNumbers={sectors?.map((sector) => sector.value)}
                      prelandTitle={prelandTitle}
                      spinCount={spinCount}
                      spinButtonText={spinButtonText}
                      respinButtonText={respinButtonText}
                      winSector={winSector}
                      logo={selectedImage}
                      popupTitle={popupTitle}
                      popupText={popupText}
                      popupButtonText={popupButtonText}
                      currentWheel={currentWheel}
                    />
                  </div>
                </div>
              </>
            </div>
          </div>
          <div className={s.buttonsWrapper}>
            <RedButton
              width={120}
              text="Отменить"
              action={() => {
                navigate("/prelandings");
                handleContinue();
              }}
            />
            <BlueButton
              width={110}
              text="Сохранить"
              pending={prelandPending}
              action={onEditPrelanding}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EditPrelanding;
