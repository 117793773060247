import React from 'react';
import {CircularProgress} from "@mui/material";

const Loader = () => {

    return (
        <div style={{color: "#f2f5fa", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <CircularProgress size={23} color='inherit'/>
        </div>
    )
}

export default Loader;