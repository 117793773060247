import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { fetchAppList } from "../../redux/slices/applicationSlice";
import { getIsStaff } from "../../storage/storage";
import Card from "./Card/Card";
import CustomSkeleton from "../CustomSkeleton/CustomSkeleton";
import EmptyList from "../EmptyList/EmptyList";
import s from "./appCatalog.module.scss";

const AppCatalog = () => {
  const dispatch = useDispatch();
  const { appList, appListLoading } = useSelector((state) => state.application);

  const navigate = useNavigate();

  const filteredAppList = useMemo(() => {
    return !getIsStaff()
      ? appList
      : appList.filter((item) => item?.is_alive === true);
  }, [appList]);

  useEffect(() => {
    dispatch(fetchAppList());
  }, [dispatch]);

  const skeletons = (styles) => {
    return [...new Array(8)].map((_, index) => (
      <CustomSkeleton styles={styles} key={index} />
    ));
  };

  const pageWidth = useWindowWidth();

  return (
    <div className={s.appCatalogWrapper}>
      <div className={s.pageHeader}>
        <h3 className={s.pageTitle}>Каталог приложений</h3>
      </div>

      <div className={s.appCatalog}>
        <div className={s.content}>
          {appListLoading ? (
            skeletons(s.skeletonCard)
          ) : filteredAppList.length < 1 ? (
            <EmptyList />
          ) : (
            filteredAppList.map((item) => (
              <div key={item.id}>
                <Card {...item} navigate={navigate} pageWidth={pageWidth} />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default AppCatalog;
