import React from "react";
import s from "./preview.module.scss";
import { useLocation } from "react-router-dom";

import defaultLogo from "../../../assets/img/defaultLogo.png";
import PrelandSpinWheel from "./PrelandSpinWheel/PrelandSpinWheel";
import { API_URL } from "../../../api/url";

const Preview = () => {
  const { search } = useLocation();

  const parseJSONSafely = (str, defaultValue) => {
    try {
      const correctedStr = str
        .replace(/'/g, '"')
        .replace(/False/g, "false")
        .replace(/True/g, "true");
      return JSON.parse(correctedStr);
    } catch (e) {
      console.error(`Error parsing JSON from URL parameter: ${e.message}`);
      return defaultValue;
    }
  };

  const queryParams = new URLSearchParams(search);
  const params = Array.from(queryParams.entries()).reduce(
    (acc, [key, value]) => {
      acc[key] =
        key === "sectors" || key === "style"
          ? parseJSONSafely(value, key === "style" ? {} : [])
          : decodeURIComponent(value);
      return acc;
    },
    {},
  );

  const {
    style = {},
    logo = "",
    header_text = "",
    rotation_button_text = "",
    respin_button_text = "",
    num_of_revolutions = 0,
    sectors = [],
    winning_button_text = "",
    popup_win_text = "",
    bonus_button_text = "",
    redirect_link = "",
  } = params;

  const winningIndex = Array.isArray(sectors)
    ? sectors.findIndex((sector) => sector.is_win)
    : -1;

  return (
    <div className={s.prelandingWrapper}>
      <PrelandSpinWheel
        prelandTitle={header_text}
        spinCount={num_of_revolutions}
        popupText={popup_win_text}
        respinButtonText={respin_button_text}
        spinButtonText={rotation_button_text}
        redirectLink={redirect_link}
        sectors={sectors}
        winSector={winningIndex}
        logo={logo || defaultLogo}
        // popupTitle={popup_win_text}
        popupTitle={bonus_button_text}
        currentWheel={{
          id: 1,
          background: `${API_URL}${style?.background}`,
          wheel: `${API_URL}${style?.wheel}`,
          button: `${API_URL}${style?.button}`,
          pointer: `${API_URL}${style?.pointer}`,
          popup: `${API_URL}${style?.popup}`,
        }}
        popupButtonText={winning_button_text}
      />
    </div>
  );
};

export default Preview;
